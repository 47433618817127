import { Profile, HackProfile, SanctionProfile } from "types";

export type EntityOrEventType = "sanction" | "hack" | "entity";

export type EntityOrEventResult = {
  item: HackProfile | SanctionProfile | Profile;
  type: EntityOrEventType;
} | null;

export const getKnownEntityOrEvent = (
  name: string | undefined,
  events: { sanctions: SanctionProfile[]; hacks: HackProfile[] },
  entities: Profile[],
): EntityOrEventResult => {
  if (!name) return null;
  // Todo Temp Fix before sanction release

  const knownSanctionsEvent = events.sanctions.find((sanction) => {
    const sanctionsTagsProperty = sanction.name;
    // crete a name to strip date including brackets from the script e.g "Dimitrii Karasavidi (September 2020)"
    // would become "Dimitrii Karasavidi"
    const nameWithoutDate = name.replace(/\(.*\)/, "").trim();
    if (!sanctionsTagsProperty) {
      return false;
    }
    const sanctionsTags = sanctionsTagsProperty.split(";").map((tag: string) => tag.trim());
    return sanctionsTags.includes(name) || sanction.entities?.includes(nameWithoutDate);
  });

  if (knownSanctionsEvent) {
    return { item: knownSanctionsEvent, type: "sanction" };
  }

  const knownHackEvent = events.hacks.find((hack) => hack.entities.includes(name));

  if (knownHackEvent) {
    return { item: knownHackEvent, type: "hack" };
  }

  const knowEntity = entities.find((entity) => entity.name === name);
  if (knowEntity) {
    return { item: knowEntity, type: "entity" };
  }

  return null;
};
