import styled from "styled-components";
import React from "react";
type Props = {
  $variant: "regular" | "small";
  color?: keyof Theme["colors"];
  ref: React.ForwardedRef<HTMLDivElement>;
};

export const Subtitle = styled.div<Props>`
  ${(p) => (p.$variant === "regular" ? p.theme.fonts.h2 : p.theme.fonts.subTextDarkGray)};
  font-weight: 600;
`;
