import { formatDate, formatCryptoCurrencyValue, formatFiatValue } from "utils";
import { Grid, Tooltip, InfoIcon, ColumnHeaderParams } from "ui";

import type { Columns } from "ui";
import { Address, HashField } from "components";

const renderAddressHeader = (params: ColumnHeaderParams) => (
  <Grid container alignItems="center" className=" MuiDataGrid-columnHeaderTitle">
    <Grid item>{params.colDef.headerName}</Grid>
    <Grid item alignItems="center" sx={{ display: "flex" }}>
      <Tooltip title="Grey shading corresponds to the subject address">
        <InfoIcon sx={{ height: "16px", color: "lightGrey" }} />
      </Tooltip>
    </Grid>
  </Grid>
);

const renderAddressCell = (
  value: string,
  row: any,
  coin: "eth" | "btc" | "erc20",
  originAddress: string,
  originEntityName: string,
) => {
  const reportCoin = coin === "erc20" ? "eth" : coin;
  return (
    <Grid container columnSpacing={2}>
      <Grid item width="90px">
        {value == "GENESIS" ? (
          value
        ) : (
          <Address link={`/report/${reportCoin}/${value}`} address={value.toString()} variant="short" />
        )}
      </Grid>
      <Grid item>{value === originAddress ? originEntityName : row.entityName || row.controllerTag || "Unknown"}</Grid>
    </Grid>
  );
};

const getColumns = (coin: "eth" | "btc" | "erc20", originAddress: string, originEntityName: string): Columns => {
  return [
    {
      field: "sender",
      headerName: "Sender",
      renderHeader: renderAddressHeader,
      flex: 1,
      cellClassName: (params) => (params.value === originAddress ? "highlight" : ""),
      renderCell: ({ value, row }) => renderAddressCell(value, row, coin, originAddress, originEntityName),
    },
    {
      field: "receiver",
      headerName: "Receiver",
      flex: 1,
      renderHeader: renderAddressHeader,
      cellClassName: (params) => (params.value === originAddress ? "highlight" : ""),
      renderCell: ({ value, row }) => renderAddressCell(value, row, coin, originAddress, originEntityName),
    },
    {
      field: "amount",
      headerName: "Amount",
      align: "left",
      width: 150,
      valueFormatter: (value) => {
        return formatCryptoCurrencyValue(value, coin);
      },
    },
    {
      field: "value",
      headerName: "Value ($)",
      align: "left",
      width: 150,
      valueFormatter: (value) => formatFiatValue(value, coin),
    },

    {
      field: "date",
      headerName: "Date",
      width: 120,
      valueFormatter: (value) => (value ? formatDate(value) : null),
    },
    {
      field: "curHop",
      headerName: "Hops",
      width: 80,
    },

    {
      field: "hash",
      headerName: "Hash",
      width: 80,
      type: "string",
      renderCell: ({ value, row }) => <HashField value={value} coin={coin} row={row} />,
    },
  ];
};

export default getColumns;
