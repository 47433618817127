import styled from "styled-components";

import { Button as MUIButton, ButtonProps } from "@mui/material";
type ButtonUIProps = ButtonProps & {
  $shouldAnimate: boolean;
};

export const ButtonUI = styled(MUIButton)<ButtonUIProps>`
  width: auto;
  overflow: hidden;
  flex-wrap: nowrap;
  white-space: nowrap;

  max-width: ${(props) => (props.$shouldAnimate ? "530px" : "260px")};
  min-width: ${(props) => (props.$shouldAnimate ? "180px" : "60px")};

  justify-content: ${(props) => (props.$shouldAnimate ? "flex-start" : "center")};
`;

export const Content = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
