import { AxiosResponse, AxiosError } from "axios";
import { toast } from "react-toastify";
import useErrorHandler from "./useErrorHandler";

export const useHandleRestResponse = () => {
  const handleError = useErrorHandler();
  const handleRestResponse = async <T>(response: Promise<AxiosResponse<T>>, successMessage?: string) => {
    try {
      const result = await response;
      if (successMessage) toast(successMessage);
      return result;
    } catch (error: unknown | AxiosError) {
      if (error instanceof AxiosError && error.response?.status === 409) {
        toast.error(error.response.data.message);
      } else {
        handleError(error);
      }
    }
  };
  return handleRestResponse;
};
