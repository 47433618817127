import { useState } from "react";
import { PopUpDialog, Input, Button, Grid } from "ui";

type Props = {
  onRename: (name: string) => void;
  onCancel: () => void;
};

export const RenamePortfolio = ({ onRename, onCancel }: Props) => {
  const [name, setName] = useState("");
  return (
    <PopUpDialog title="Rename" onCancel={() => onCancel()}>
      <Grid container rowSpacing={8} direction="column" flexWrap="nowrap">
        <Grid item xs={12}>
          <Input
            label="Name"
            placeholder="Enter new name"
            onChange={(e) => setName(e.target.value)}
            value={name}
            required
          />
        </Grid>
        <Grid item container justifyContent="flex-end" columnSpacing={1}>
          <Grid item>
            <Button
              disabled={name.trim() === ""}
              onClick={() => {
                onRename(name);
                setName("");
              }}
            >
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button type="submit" buttonTheme="cancel" onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </PopUpDialog>
  );
};

export default RenamePortfolio;
