import { BitcoinIcon, EthereumIcon, Grid, Tag } from "ui";
import Address from "../Address";
import RiskSummary from "../RiskSummary";
import { ReportMeta, Event, Entity } from "types";
import { AppContext } from "contexts";
import { useContext, useMemo } from "react";
import { getKnownEntityOrEvent } from "utils/profile";
import Actions from "./Actions";
import EntitySection from "./EntitySection";
import { useGetHighestRiskType } from "hooks/useRisk";
import * as S from "../../styled";

type Props = Pick<ReportMeta, "address"> & {
  reportType: "btc" | "eth";
  balanceCrypto: number;
  owner: Entity;
  controller: Entity;
  balanceFiat: number;
  highRiskCount: number;
  labels: string[];
  isInPortfolio: boolean;
  eventsData?: { owner: Event[]; controller: Event[] };
};

export const ReportTitle = ({
  address,
  reportType,
  owner,
  controller,
  highRiskCount,
  labels,
  isInPortfolio,
}: Props) => {
  const { events, entities } = useContext(AppContext);

  const ownerDetails = {
    name: owner.name,
    type: owner.type,
    subType: owner.subType,
    events: owner.events,
  };

  const controllerDetails = {
    name: controller.name,
    type: controller.type,
    subType: controller.subType,
    events: controller.events,
  };

  const allEvents = [...owner.events, ...controller.events];
  const eventsTypes = allEvents.map((event) => event.type.name);
  const allTypes = [owner.type, controller.type, ...eventsTypes, owner.subType, controller.subType].filter(Boolean);

  const highestRisk = useGetHighestRiskType(allTypes);
  const risk = ["LOW", "MEDIUM"].includes(highestRisk) && highRiskCount > 0 ? "HIGH" : highestRisk;

  const knownEntityOrEventOwner = useMemo(
    () => getKnownEntityOrEvent(owner.name, events, entities),
    [owner.name, events, entities],
  );

  const knownEntityOrEventController = useMemo(
    () => getKnownEntityOrEvent(controller.name, events, entities),
    [controller.name, events, entities],
  );

  const hasNameOrImage = !!(knownEntityOrEventOwner?.type || knownEntityOrEventController?.type);

  // Map events adding riskEntityName if owner/controller exist
  const ownerEvents = owner ? owner.events.map((event) => ({ eventProfile: event, entityName: owner.name })) : [];
  const controllerEvents = controller
    ? controller.events.map((event) => ({ eventProfile: event, entityName: controller.name }))
    : [];
  const eventsMapped = [...ownerEvents, ...controllerEvents];

  return (
    <Grid item container flexWrap="nowrap" rowGap={5} direction="column">
      <Grid container flexWrap="nowrap" direction="column">
        <Grid container flexWrap="nowrap">
          <Grid xs="auto" paddingRight={1.5}>
            <S.IconWrapper>{reportType === "btc" ? <BitcoinIcon /> : <EthereumIcon />}</S.IconWrapper>
          </Grid>

          <Grid container height="48px" alignItems="center">
            <Address riskLevel={risk} address={address} truncate={address !== "genesis"} />
          </Grid>

          <Actions address={address} isInPortfolio={isInPortfolio} />
        </Grid>
        <Grid container columnGap={1} marginLeft={7}>
          {labels.map((label) => (
            <Tag key={label} label={label} backgroundColor="lightGray" color="darkGray" />
          ))}
        </Grid>
      </Grid>
      <Grid item container rowGap="40px" direction="column">
        <Grid container columnSpacing={6}>
          <Grid item>
            <EntitySection details={ownerDetails} hasNameOrImage={hasNameOrImage} title="Address Owner" />
          </Grid>
          <Grid item>
            <EntitySection details={controllerDetails} hasNameOrImage={hasNameOrImage} title="Address Controller" />
          </Grid>
        </Grid>
        <Grid>
          <RiskSummary highRiskCount={highRiskCount} mediumRiskCount={0} events={eventsMapped} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReportTitle;
