import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { User } from "types";
import { ErrorDialog, Loading } from "ui";
import { checkAccess, FeatureType } from "utils/subscription";

type Props = {
  children: React.ReactNode;
};

type AuthContextType = {
  user: User;
  updateUser: () => void;
  hasFeatureAccess: (feature: FeatureType) => boolean;
};

export const AuthContext: React.Context<AuthContextType> = createContext<AuthContextType>({
  user: {} as User,
  updateUser: () => null,
  hasFeatureAccess: () => false,
});

export const AuthProvider = ({ children }: Props) => {
  const { isLoading, error, isAuthenticated, loginWithRedirect, getAccessTokenSilently, user: auth0User } = useAuth0();

  const [token, setToken] = useState<string | null>(null);
  const [inActiveuser, setInActiveUser] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const product = urlParams.get("product");

  if (!isLoading && !isAuthenticated && !error) {
    loginWithRedirect({
      appState: {
        targetUrl: window.location.pathname,
      },
      ...(product ? { authorizationParams: { product } } : {}),
    });
  }

  const updateUser = async () => {
    try {
      if (auth0User) {
        const auth0Response = await axios.get(
          `https://${import.meta.env.VITE_AUTH0_DOMAIN}/api/v2/users/${auth0User?.sub}`,
        );
        const rolesAttribute = `${import.meta.env.VITE_DOMAIN}/roles`;
        const searchHistory = await axios.get("/api/users/searchHistory");
        const userData = auth0Response.data;
        const user = {
          roles: auth0User[rolesAttribute],
          isAdmin: auth0User[rolesAttribute].includes("admin"),
          company: userData.user_metadata.company,
          position: userData.user_metadata.position,
          phone: userData.user_metadata.phone,
          name: userData.user_metadata.name,
          email: userData.email,
          api_access: userData.user_metadata.api_access,
          product: userData.user_metadata.product,
          subscriptionStatus: userData.user_metadata.status,
          subscriptionCurrentPeriodEnd: userData.user_metadata.subscription_current_period_end,
          lastPaymentDate: userData.user_metadata.last_payment_date,
          searchHistory: searchHistory.data,
        };
        setUser(user);
        setInActiveUser(userData.user_metadata.status === "inactive");
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status !== 402) {
        // eslint-disable-next-line no-console
        console.error("Error updating user:", error);
      }
      // Don't set user state if there's a 402 error as we're redirecting
      return;
    }
  };

  useEffect(() => {
    const loadUserData = async () => {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently({
          // audience: import.meta.env.VITE_AUTH0_AUDIENCE,
          // scope:
          //   "read:current_user update:current_user_metadata profile email openid",user
        });
        setToken(token);
        localStorage.setItem("token", token);
      }
    };
    loadUserData();
  }, [isAuthenticated]);

  useEffect(() => {
    if (token && auth0User) {
      updateUser();
    }
  }, [token, auth0User]);

  useEffect(() => {
    // Removes URL params to prevent invalid state on refresh
    if (error) {
      navigate("/");
    }
  }, [error]);

  const hasFeatureAccess = (feature: FeatureType): boolean => {
    return user ? checkAccess(user.product, feature) : false;
  };

  if (isLoading || (!isLoading && isAuthenticated && token === null)) {
    return <Loading />;
  }

  if (error !== undefined) {
    return <ErrorDialog title={error.message} />;
  }

  if (inActiveuser) {
    return (
      <ErrorDialog
        title={"Thank you for registering!  Please await account activation."}
        actionName="LOGOUT"
        onAction={() =>
          (location.href = `https://${import.meta.env.VITE_AUTH0_DOMAIN}/v2/logout?returnTo=${
            window.location.origin
          }&client_id=${import.meta.env.VITE_AUTH0_CLIENT_ID}`)
        }
      />
    );
  }

  return (
    user && (
      <AuthContext.Provider
        value={{
          user: {
            ...user,
          },
          updateUser,
          hasFeatureAccess,
        }}
      >
        {isAuthenticated && children}
      </AuthContext.Provider>
    )
  );
};

export default AuthContext;
