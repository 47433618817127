import { formatDate } from "utils";
import { Grid, Link as UILink, Tooltip, InfoIcon, ColumnHeaderParams } from "ui";
import type { Columns } from "ui";
import { Address } from "components";

const renderAddressHeader = (params: ColumnHeaderParams) => (
  <Grid container alignItems="center" className="MuiDataGrid-columnHeaderTitle dsadas">
    <Grid item>{params.colDef.headerName}</Grid>
    <Grid container item alignItems="center" id="asd">
      <Tooltip title="Grey shading corresponds to the subject address">
        <InfoIcon sx={{ height: "16px", color: "lightGrey" }} />
      </Tooltip>
    </Grid>
  </Grid>
);

const renderAddressCell = (
  value: string,
  row: any,
  coin: "eth" | "btc" | "erc20",
  originAddress: string,
  originEntityName: string,
) => {
  const reportCoin = coin === "erc20" ? "eth" : coin;
  return (
    <Grid container columnSpacing={2}>
      <Grid item>
        <Address link={`/report/${reportCoin}/${value}`} address={value?.toString()} variant="short" />
      </Grid>
      <Grid item>{value === originAddress ? originEntityName : row.entityName || row.controllerTag || "Unknown"}</Grid>
    </Grid>
  );
};

const getColumns = (coin: "eth" | "btc" | "erc20", originAddress: string, originEntityName: string): Columns => {
  return [
    {
      field: "sender",
      headerName: "Sender",
      renderHeader: renderAddressHeader,
      flex: 1,
      cellClassName: (params) => (params.value === originAddress ? "highlight" : ""),
      renderCell: ({ value, row }) => renderAddressCell(value, row, coin, originAddress, originEntityName),
    },
    {
      field: "receiver",
      headerName: "Receiver",
      flex: 1,
      renderHeader: renderAddressHeader,
      cellClassName: (params) => (params.value === originAddress ? "highlight" : ""),
      renderCell: ({ value, row }) => renderAddressCell(value, row, coin, originAddress, originEntityName),
    },
    {
      field: "amount",
      headerName: "Amount",
      align: "left",
      width: 150,
    },
    {
      field: "value",
      headerName: "Value ($)",
      align: "left",
      width: 150,
    },

    {
      field: "date",
      headerName: "Date",
      width: 120,
      valueFormatter: (value) => (value ? formatDate(value as string) : null),
    },
    {
      field: "curHop",
      headerName: "Hops",
      width: 120,
    },

    {
      field: "hash",
      headerName: "Hash",
      width: 80,
      type: "string",
      valueFormatter: (value) => (value as string)?.toString(),
      renderCell: ({ value }) => (
        <UILink
          href={coin === "btc" ? `https://www.blockchain.com/${coin}/tx/${value}` : `https://etherscan.io/tx/${value}`}
          target="_blank"
          rel="noreferrer"
        >
          {value.slice(0, 5)}...
        </UILink>
      ),
    },
  ];
};

export default getColumns;
