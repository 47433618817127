/* eslint-disable no-console */
import { Profile, HackProfile, SanctionProfile } from "types";
import { Grid, Label } from "ui";

type AddressResult = {
  address: string;
  type: "eth" | "btc";
};

export type SearchHistoryItem = {
  id: string;
  name: string;
  type: SearchResultTypes;
};

type Props = {
  items: Profile[] | HackProfile[] | SanctionProfile[] | AddressResult[] | SearchHistoryItemType[];
  label: string;
  itemType: "address" | "entity" | "hack" | "sanction" | "search_history";
  showItem: (id: string, type: SearchResultTypes) => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  isFocused: (id: string) => boolean;
};

import { AddressItem } from "../AddressItem";
import { HackItem } from "./HackItem";
import { SanctionItem } from "./SanctionItem";
import { SearchHistoryItem, SearchHistoryItemType } from "./SearchHistoryItem";
import type { SearchResultTypes } from "./SearchResult";
import * as S from "./styled";
import EntityProfileItem from "./EntityProfileItem";

const getKeyFromItem = (
  item: Profile | HackProfile | SanctionProfile | AddressResult | SearchHistoryItem,
  index: number,
): string => {
  if ("name" in item && item.name) {
    return item.name;
  } else if ("entity" in item && item.entity) {
    return item.entity;
  } else if ("address" in item && item.address) {
    return item.address;
  }

  return index.toString();
};
const SearchResultItem = ({ items, label, itemType, onMouseEnter, onMouseLeave, isFocused, showItem }: Props) => (
  <Grid container item direction="column" rowSpacing={2}>
    <Grid item>
      <Label>{label}</Label>
    </Grid>
    <Grid container item>
      {items.map((item, index) => (
        <S.SearchItem
          container
          key={getKeyFromItem(item, index)}
          padding="16px 12px"
          $isFocused={isFocused(getKeyFromItem(item, index))}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={() => showItem(getKeyFromItem(item, index), itemType)}
        >
          {itemType === "search_history" && <SearchHistoryItem item={item as SearchHistoryItemType} />}
          {itemType === "address" && (
            <AddressItem address={(item as AddressResult).address} type={(item as AddressResult).type} />
          )}
          {itemType === "entity" && (
            <EntityProfileItem
              name={(item as Profile).name}
              type={(item as Profile).type} // changed from tag
              riskScore={(item as Profile).riskScore}
            />
          )}
          {itemType === "hack" && <HackItem hack={item as HackProfile} />}
          {itemType === "sanction" && <SanctionItem sanction={item as SanctionProfile} />}
        </S.SearchItem>
      ))}
    </Grid>
  </Grid>
);

export default SearchResultItem;
