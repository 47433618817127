import { DataGrid as DataGridMD } from "@mui/x-data-grid";
import styled from "styled-components";

export const DataGrid = styled(DataGridMD)<{ fontSize: "small" | "normal" }>`
  .MuiDataGrid-columnHeader,
  .MuiDataGrid-cell {
    padding-left: 16px;
  }

  .MuiDataGrid-columnHeaderTitle {
    font-weight: 600;
    text-transform: uppercase;
    color: black;
  }
  .MuiDataGrid-columnHeaderTitleContainer {
    padding: 0;
    font-size: 12px;
    font-style: normal;
    line-height: 100%;
    letter-spacing: 0.15px;
  }
  border-color: ${(p) => p.theme.colors.mediumGray};

  font-size: ${(p) => (p.fontSize === "normal" ? undefined : "14px")};
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;

  & .MuiChip-root {
    font-size: ${(p) => (p.fontSize === "normal" ? undefined : "14px")};
  }
  .MuiDataGrid-columnHeaders {
    border-bottom: 1px solid ${(p) => p.theme.colors.mediumGray};
  }
  .MuiDataGrid-virtualScrollerContent {
  }
`;

export const ExpandableRow = styled.div<{
  $columnWidth?: number;
  $columnAlign?: string;
  $columnFlex?: number;
  maxWidth?: number;
  minWidth?: number;
  $rowHeight?: number;
}>`
  width: ${(props) => props.$columnWidth}px;
  text-align: ${(props) => props.$columnAlign};
  flex: ${(props) => props.$columnFlex};
  padding-left: 19x;
  padding-right: 0px;
  max-width: ${(props) => props.maxWidth}px;
  min-width: ${(props) => props.minWidth}px;
  height: ${(props) => props.$rowHeight}px;
  align-content: center;
  transition: height 0.3s ease;
  flex-shrink: 0;

  overflow: hidden;
  text-overflow: ellipsis;
`;
