import getColumns from "./columns";

import { Table } from "ui";
import styled from "styled-components";
import { useContext } from "react";
import AuthContext from "contexts/Auth0Context";
import { Transaction } from "../../../../types";
import { formatCryptoCurrencyValue, formatFiatValue } from "utils";

type Props = {
  coin: "eth" | "btc" | "erc20";
  originAddress: string;
  originEntityName: string;
  transactions: Transaction[];
};

const StyleTable = styled(Table)`
  .highlight {
    background: rgba(103, 103, 103, 0.1);
  }
`;

export const TransactionTable = ({ transactions, coin, originAddress, originEntityName }: Props) => {
  const columns = getColumns(coin, originAddress, originEntityName);
  const { user } = useContext(AuthContext);
  if (coin === "erc20") {
    columns.splice(2, 0, {
      field: "symbol",
      headerName: "Symbol",
      align: "left",
      width: 150,
      valueFormatter: (value) => value,
    });
  }

  return (
    <StyleTable
      allowExport={user.isAdmin}
      columns={columns}
      rows={transactions.map((tx, index) => {
        return {
          ...tx,
          amount: formatCryptoCurrencyValue(tx.amount, coin),
          value: formatFiatValue(tx.value, coin),
          id: index,
        };
      })}
      initialState={{
        sorting: {
          sortModel: [{ field: "date", sort: "desc" }],
        },
      }}
    />
  );
};

export default TransactionTable;
