import styled from "styled-components";

type ValueProps = {
  $print?: boolean;
  width: number | string;
  $bold?: boolean;
  $valueLineHeight?: string;
};

export const Value = styled.div<ValueProps>`
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: ${(p) => (p.$print ? "initial" : "nowrap")};
  max-width: ${(p) => (p.$print ? "100%" : p.width + "px")};
  font-weight: ${(p) => (p.$bold ? "600" : "normal")};
  line-height: ${(p) => p.$valueLineHeight};
`;
