import styled from "styled-components";
import ScrollContainerUnsyled from "react-indiana-drag-scroll";
import { ArrowOutIcon as ArrowOutIconUI, Grid } from "ui";

type ScrollContainerProps = {
  hidePrint: boolean;
};

type LicenceProps = {
  print: boolean;
};

export const ArrowOutIcon = styled(ArrowOutIconUI)`
  transform: translateY(66%);
  svg {
    margin-bottom: 15px;
  }
`;

export const Licence = styled(Grid)<LicenceProps>`
  border: 1px solid ${(props) => props.theme.colors.mediumGray};
  break-inside: avoid;
  border-radius: 10px;
  width: calc((100% - 48px) / ${(props) => (props.print ? 2 : 3)});
  height: 180px;
  cursor: pointer;

  &:hover {
    border-color: ${(props) => props.theme.colors.primary};
  }
`;

export const ScrollContainerPrint = styled.div`
  display: flex;
  flex-wrap: wrap;

  row-gap: 16px;
  width: 100%;
  column-gap: 16px;
  padding-right: 0;
  padding-bottom: 16px;
`;

export const ScrollContainer = styled(ScrollContainerUnsyled)<ScrollContainerProps>`
  display: flex;
  margin-top: 16px;
  width: 100%;
  column-gap: 16px;
  padding-right: 0;
  padding-bottom: 16px;

  ${({ hidePrint }) =>
    hidePrint &&
    `
      @media print {
        display: none;
      }
    `}
`;

export const Header = styled("div")`
  margin-left: 32px;
`;
