import { Grid, Table } from "ui";
import { EntityNotificationLog } from "types";
import getColumns from "./columns";
import { useContext } from "react";
import { AppContext } from "contexts";

type Props = {
  entitiesNotificationLogs: EntityNotificationLog[];
};

const EntitiesNotifications = ({ entitiesNotificationLogs }: Props) => {
  const { entities } = useContext(AppContext);
  const columns = getColumns();

  const rows = entitiesNotificationLogs.map((log) => {
    const entity = entities.find((e) => parseInt(e.id) === log.entity_id);
    return {
      created_at: log.created_at,
      id: log.id,
      entity: entity?.name ?? "",
      score: log.additionalInfo.score,
      oldScore: log.additionalInfo.oldScore,
      reason: log.additionalInfo.reason,
      createdAt: log.created_at,
    };
  });

  return (
    <Grid container width="100vw">
      <Table rowHeight={50} columns={columns} rows={rows} />
    </Grid>
  );
};

export default EntitiesNotifications;
