import React from "react";
import { Profile, HackProfile, SanctionProfile } from "types";
import { EntityPopup } from "./EntityPopup";
import { EventPopup } from "./EventPopup";

type ProfilePopupProps = {
  content: React.ReactNode;
  type: "entity" | "event";
  data?: Profile | HackProfile | SanctionProfile;
};

const ProfilePopup: React.FC<ProfilePopupProps> = ({ content, type, data }) => {
  if (!data) {
    return <span>{content}</span>;
  }

  return type === "entity" ? (
    <EntityPopup data={data as Profile} content={content} />
  ) : (
    <EventPopup data={data as HackProfile | SanctionProfile} content={content} />
  );
};

export default ProfilePopup;
