import { EntityType, EntityAvatar } from "components";
import type { Profile } from "types";
import { Grid, RisktrailScore } from "ui";

//TODO - replace type after renaming tag to type
type Props = Pick<Profile, "name" | "riskScore"> & { type: string };

export const EntityProfileItem = ({ name, type, riskScore }: Props) => (
  <Grid
    container
    item
    xs={12}
    columnSpacing={2}
    flexWrap="nowrap"
    alignItems="flex-start"
    justifyContent="space-between"
  >
    <Grid item>
      <EntityAvatar name={name} variant="normal" />
    </Grid>
    <Grid item xs={12}>
      <Grid container direction="column">
        <Grid item>
          <strong>{name}</strong>
        </Grid>
        <Grid item>
          <EntityType type={type} />
        </Grid>
      </Grid>
    </Grid>
    <Grid item>
      <RisktrailScore score={riskScore} value={riskScore !== null ? riskScore.toFixed(2) : ""} />
    </Grid>
  </Grid>
);

export default EntityProfileItem;
