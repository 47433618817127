import styled from "styled-components";
import { Link } from "ui";

export const EntityName = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
`;

type LinkProps = {
  $underline?: boolean;
};
export const MuiLink = styled(Link)<LinkProps>`
  display: flex;
  gap: 8px;
  text-underline-offset: 2px;
  align-items: center;
  height: 32px;
  font-size: 14px;
`;
