import * as Flags from "country-flag-icons/react/3x2";
import { GlobeIcon, UnknownFlagIcon } from "ui";
import { countries } from "./countryCodes";

type Props = {
  countryName: string;
};

import * as S from "./styled";

export const NationFlag = ({ countryName }: Props) => {
  if (!countryName) {
    return null;
  } else if (countryName === "Global") {
    return (
      <S.Flag>
        <GlobeIcon />
      </S.Flag>
    );
  } else if (countryName === "Unknown") {
    return (
      <S.Flag>
        <UnknownFlagIcon />
      </S.Flag>
    );
  }

  const country = countries.find((country) => country.name === countryName);
  const code: string = country ? country.code.toUpperCase() : countryName.toString().toUpperCase();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const Flag = Flags[code];
  return <S.Flag>{Flag && <Flag title={country ? country.name : countryName} />}</S.Flag>;
};

export default NationFlag;
