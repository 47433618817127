import { Colors } from "styles/variables";
import { EventType } from "types/Events";
import { Chip } from "ui";

type Props = {
  type: EventType | "Scam";
  label?: string;
};

type ColorMapping = {
  [key in Props["type"]]: {
    backgroundColor: keyof Colors;
    color: keyof Colors;
  };
};

const colorMapping: ColorMapping = {
  Sanction: {
    backgroundColor: "lightRed",
    color: "red",
  },
  Hack: {
    backgroundColor: "lightOrange",
    color: "darkOrange",
  },
  Scam: {
    backgroundColor: "lightRed",
    color: "red",
  },
};

export const EventTags = ({ type, label }: Props) => {
  return (
    <Chip
      label={label || type}
      sx={(theme) => ({
        borderRadius: "4px",
        backgroundColor: theme.colors[colorMapping[type].backgroundColor],
        color: colorMapping[type].color,
        fontWeight: "600",
        padding: "1px 0px 1px 0px",
        height: "20px",
        fontSize: "12px",
      })}
      size="small"
    />
  );
};

export default EventTags;
