import { useState } from "react";
import { PopUpDialog, Input, Button, Grid } from "ui";

type Props = {
  onCreate: (name: string) => void;
  onCancel: () => void;
};

export const CreatePortfolio = ({ onCreate, onCancel }: Props) => {
  const [name, setName] = useState("");
  return (
    <PopUpDialog title="Create a new portfolio" onCancel={() => onCancel()}>
      <Grid container rowSpacing={8} direction="column" flexWrap="nowrap">
        <Grid item>
          <Input
            label="New Portfolio Name"
            placeholder="Enter portfolio name"
            onChange={(e) => setName(e.target.value)}
            value={name}
            required
          />
        </Grid>
        <Grid container item justifyContent="flex-end" columnSpacing={1}>
          <Grid item>
            <Button
              disabled={name.trim() === ""}
              onClick={() => {
                onCreate(name);
                setName("");
              }}
            >
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button buttonTheme="secondary" onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </PopUpDialog>
  );
};

export default CreatePortfolio;
