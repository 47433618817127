import { Event, EventType, Transaction } from "types";
import { Colors } from "styles";

export const isHighRisk = (risk: string, includeMedium = false) => {
  const highRiskLevels = includeMedium ? ["VERY_HIGH", "HIGH", "MEDIUM"] : ["VERY_HIGH", "HIGH"];
  return highRiskLevels.includes(risk);
};

export const getRiskColor = (risk: string, theme?: Theme): keyof Colors | string => {
  if (theme) {
    return isHighRisk(risk) ? theme.colors.red : theme.colors.orange;
  }
  return isHighRisk(risk) ? "red" : "orange";
};

export const getRiskBackgroundColor = (risk: string) => {
  return isHighRisk(risk) ? "#FFEBEB" : "#FDEDE3";
};

export const countHighRiskEvents = (
  row: Partial<Transaction> & {
    controllerEvents?: Event[];
    ownerEvents?: Event[];
  },
): {
  controller: Record<EventType, number>;
  owner: Record<EventType, number>;
} => {
  const isHighRisk = (event: Event) => event.type?.risk === "HIGH" || event.type?.risk === "VERY_HIGH";

  const countEventTypes = (events: Event[]): Record<EventType, number> => {
    const counts: Record<EventType, number> = {
      Sanction: 0,
      Hack: 0,
    };

    events.forEach((event) => {
      if (isHighRisk(event)) {
        if (event.type.name == "Sanctions") {
          counts.Sanction++;
        } else if (event.type.name === "Hack") {
          counts.Hack++;
        }
      }
    });

    return counts;
  };

  return {
    controller: countEventTypes(row.controllerEvents || []),
    owner: countEventTypes(row.ownerEvents || []),
  };
};
