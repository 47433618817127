import React from "react";
import { ErrorDialog } from "ui";

type Props = {
  children?: React.ReactNode;
};

class ErrorBoundary extends React.Component<Props> {
  state: {
    hasError: false;
    showErrorDialog: false;
  };
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, showErrorDialog: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    // eslint-disable-next-line no-console
    console.log(error, info);
    this.setState({
      showErrorDialog: true,
    });
  }

  render() {
    return <>{this.props.children}</>;

    // TODO: decide how to show errors to user less agressively, or do not show it at all
    // if (this.state.showErrorDialog) {
    //   return <ErrorDialog actionName="GO HOME" onAction={() => location.replace("/")} />;
    // } else {
    //   return <>{this.props.children}</>;
    // }
  }
}

export default ErrorBoundary;
