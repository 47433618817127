import { Warning } from "ui";

import { useTheme } from "@mui/material";
import * as S from "./styled";

type Props = {
  type: string;
};

export const RiskyEventType = ({ type }: Props) => {
  const theme = useTheme();
  return (
    <S.EventWarning $backgroundColor={theme.colors.lightRed} color={theme.colors.red}>
      <Warning fill="red" /> {type.toUpperCase()}
    </S.EventWarning>
  );
};

export default RiskyEventType;
