import { Card, CardContent, ListItem, List, Stack } from "@mui/material";
import { EventTag } from "components";
import { Grid, Label } from "ui";
import type { Event, SanctionProfile, HackProfile, EventType } from "types/Events";
import { formatDate } from "utils";
import { compareAsc, parseISO } from "date-fns";

type Props = {
  hacks: HackProfile[];
  sanctions: SanctionProfile[];
  onEventSelected: (eventType: EventType, eventID: number | string) => void;
};

export const Events = ({ hacks, sanctions, onEventSelected }: Props) => {
  let events: (Event & { eventType: EventType })[] = hacks.map((hack) => {
    return {
      ...hack,
      eventType: "Hack",
    };
  });
  events = events.concat(
    sanctions.map((event) => {
      return {
        ...event,
        eventType: "Sanction",
      };
    }),
  );

  const rows = events
    .sort((a, b) => {
      const dateA = a.date ? parseISO(a.date) : new Date(0);
      const dateB = b.date ? parseISO(b.date) : new Date(0);
      return compareAsc(dateB, dateA);
    })
    .slice(0, 10);
  return (
    <List sx={{ width: "100%", overflowY: "hidden" }} component={Stack} direction="row">
      {rows.map((event, index: number) => (
        <ListItem key={index} sx={{ minWidth: 400 }}>
          <Card
            sx={{
              height: 255,
              "&:hover": { boxShadow: 20 },
              transform: "translateX(0)",
              transition: "transform 0.5s ease",
              cursor: "pointer",
            }}
            key={index}
            onClick={() => {
              onEventSelected(event.eventType, event.id);
            }}
          >
            <CardContent sx={{ height: "100%", p: 2, "&:last-child": { pb: 2 } }}>
              <Grid container direction="column" rowSpacing={1} sx={{ height: "100%" }}>
                <Grid container item justifyContent="space-between">
                  <Grid item>
                    <EventTag type={event.eventType} />
                  </Grid>
                  <Grid item>
                    <Label>{formatDate(event.date)}</Label>
                  </Grid>
                </Grid>
                <Grid item container direction="column">
                  <Grid item>
                    <b>{event?.name ? event.name : ""}</b>
                  </Grid>
                  <Grid item>
                    <Label>{event.eventType}</Label>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sx={{
                    height: "90px",
                    overflowY: "hidden",
                    flexGrow: 1,
                  }}
                >
                  {event.synopsis?.substring(0, 150)}...
                </Grid>
                <Grid item container alignItems="flex-end">
                  <Label>Read More</Label>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </ListItem>
      ))}
    </List>
  );
};

export default Events;
