import CSS from "csstype";

import * as S from "./styled";

type Props = {
  color?: string;
  className?: string;
  style?: CSS.Properties;
  text?: string;
  variant?: "small" | "large";
};

const Loading = ({ color, className = "", text, style, variant = "large" }: Props) => {
  const styles = {
    spinner: {
      small: {
        width: "50px",
        height: "50px",
      },
      large: {
        width: "60px",
        height: "60px",
      },
    },
    circle: {
      large: {
        cx: "30",
        cy: "30",
        r: "26",
        strokeWidth: "8",
      },
      small: {
        cx: "25",
        cy: "25",
        r: "20",
        strokeWidth: "4",
      },
    },
  };
  const spinner = styles.spinner[variant];
  const circle = styles.circle[variant];

  return (
    <S.Wrapper $position={variant === "large" ? "absolute" : "relative"}>
      <S.Spinner width={spinner.width} height={spinner.height} color={color} className={className} style={style}>
        <circle cx={circle.cx} cy={circle.cy} r={circle.r} fill="transparent" strokeWidth={circle.strokeWidth} />
      </S.Spinner>
      {text && <S.LoadingText>{text}</S.LoadingText>}
    </S.Wrapper>
  );
};

export default Loading;
