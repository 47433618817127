import { useEffect, useRef, useState, useMemo, lazy } from "react";
import { useParams } from "react-router-dom";
import { Grid, Title } from "ui";
import PortfolioDetail from "./PortfolioDetail";
import { RenamePortfolio, DeletePortfolio, CreatePortfolio } from "./PortfolioActions";
const PortfolioActionBar = lazy(() => import("./PortfolioActionBar"));
import { PaymentBanner, Section } from "components";
import { Transaction } from "types";
import PortfolioDetailPrint from "./PortfolioDetailPrint";
import { useReactToPrint } from "react-to-print";
import { useAccessControl } from "hooks/useAccessControl";
import { usePortfoliosData } from "hooks/usePortfoliosData";

export const Portfolio = () => {
  const {
    portfolios,
    deletePortfolio,
    loadPortfolios,
    loadPortfolio,
    createPortfolio,
    updatePortfolioName,
    arePortfoliosLoaded,
    removeAddressReportFromPortfolio,
  } = usePortfoliosData();

  const hasAccess = useAccessControl("portfolio");
  const { portfolioId: portfolioIdParam } = useParams();
  const portfolioQueryParam = Number(portfolioIdParam || "");
  const [selectedPortfolioIndex, setSelectedPortfolioIndex] = useState(0);

  const activePortfolio = useMemo(() => portfolios[selectedPortfolioIndex], [portfolios, selectedPortfolioIndex]);

  const selectPortfolioById = (id: number, portfolioList = portfolios) => {
    const selectedIndex = portfolioList.findIndex((portfolio) => portfolio.id === id);
    if (selectedIndex >= 0) setSelectedPortfolioIndex(selectedIndex);
  };

  // load portfolio list and set active portfolio
  useEffect(() => {
    loadPortfolios().then((portfolios) => {
      selectPortfolioById(portfolioQueryParam, portfolios);
    });
  }, []);

  // load active portfolio data
  useEffect(() => {
    if (activePortfolio && !activePortfolio.data) {
      loadPortfolio(activePortfolio.id);
    }
  }, [activePortfolio]);

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showRenameConfirm, setShowRenameConfirm] = useState(false);
  const [showCreatePortfolio, setShowCreatePortfolio] = useState(false);

  const handleAddressDelete = async (address: string) => {
    if (!activePortfolio) return;
    await removeAddressReportFromPortfolio(activePortfolio.id, address);
  };

  const handleDeletePortfolio = async () => {
    if (!activePortfolio) return;
    await deletePortfolio(activePortfolio.id);
    setSelectedPortfolioIndex(0);
    setShowDeleteConfirm(false);
  };

  const handleCreatePortfolio = async (name: string) => {
    const newPortfolio = await createPortfolio(name);
    if (!newPortfolio) return;

    selectPortfolioById(newPortfolio.id);
    setShowCreatePortfolio(false);
  };

  const handleUpdatePortfolioName = async (newName: string) => {
    if (!activePortfolio) return;
    await updatePortfolioName(activePortfolio.id, newName);
    setShowRenameConfirm(false);
  };

  const highRiskTransactions: Transaction[] = useMemo(() => {
    if (!activePortfolio?.data) return [];
    return activePortfolio.data.flatMap((tx) => {
      const clientAddress = tx.addressInfo.address;

      const highRisks: Transaction[] = Array.isArray(tx.highRisks)
        ? tx.highRisks
        : [...tx.highRisks.incoming, ...tx.highRisks.outgoing];
      return highRisks.map((highRisk) => {
        return {
          ...highRisk,
          clientAddress,
        };
      });
    });
  }, [activePortfolio]);

  /*
   * PRINT
   */
  const [print, setPrint] = useState(false);
  const printContainerRef = useRef(null);

  const handlePrint = useReactToPrint({
    contentRef: printContainerRef,
    onAfterPrint: () => {
      setPrint(false);
    },
  });

  useEffect(() => {
    if (print) {
      handlePrint();
    }
  }, [print]);

  return (
    <Grid container direction="column" padding={4}>
      <PaymentBanner
        hasAccess={hasAccess}
        title="Upgrade your plan to access Hoptrail's screening and portfolio tools"
      />
      <Title>Portfolio</Title>
      <PortfolioActionBar
        onCreatePortfolio={() => setShowCreatePortfolio(true)}
        onRenamePortfolio={() => setShowRenameConfirm(true)}
        onDeletePortfolio={() => setShowDeleteConfirm(true)}
        onPortfolioChange={selectPortfolioById}
        onExportToPdf={() => setPrint(true)}
        highRiskTransactions={highRiskTransactions}
        activePortfolio={activePortfolio}
        portfolios={portfolios}
        portfolioData={activePortfolio?.data || []}
      />

      <Grid container item marginTop={4}>
        {activePortfolio ? (
          <Grid container>
            <PortfolioDetail
              portfolioData={activePortfolio.data || []}
              handleAddressDelete={handleAddressDelete}
              highRiskTransactions={highRiskTransactions}
            />
            <div style={{ display: "none" }}>
              <div ref={printContainerRef}>
                {print && (
                  <PortfolioDetailPrint
                    portfolioData={activePortfolio.data || []}
                    name={activePortfolio.name}
                    highRiskTransactions={highRiskTransactions}
                  />
                )}
              </div>
            </div>
          </Grid>
        ) : arePortfoliosLoaded && portfolios.length === 0 ? (
          <Grid item xs={12} textAlign="center">
            <Section>No portfolios found. Please create new one.</Section>
          </Grid>
        ) : (
          <Grid item xs={12} textAlign="center">
            <Section>No portfolio selected, please choose one.</Section>
          </Grid>
        )}
      </Grid>
      {showCreatePortfolio && (
        <CreatePortfolio
          onCreate={(newName: string) => handleCreatePortfolio(newName)}
          onCancel={() => setShowCreatePortfolio(false)}
        />
      )}
      {showRenameConfirm && (
        <RenamePortfolio
          onRename={(newName) => handleUpdatePortfolioName(newName)}
          onCancel={() => setShowRenameConfirm(false)}
        />
      )}
      {showDeleteConfirm && (
        <DeletePortfolio onCancel={() => setShowDeleteConfirm(false)} onDelete={handleDeletePortfolio} />
      )}
    </Grid>
  );
};

export default Portfolio;
