import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { Button, Grid, Loading } from "ui";

import { Metadata, HighRiskTransactions, PaymentBanner } from "components";

import useErrorHandler from "hooks/useErrorHandler";
import type { EntityTransaction, Transaction, Report as ReportType } from "types";

import LargeReportMessage from "./LargeReportMessage";
import { BitcoinTransactionSummary, TransactionsList } from "./BitcoinTransactionSummary";

import { formatDate, formatCryptoCurrencyValue, getHigherRiskType, getEntityToDisplay } from "utils";
import { ReportHeader } from "./ReportHeader";
import AppContext from "contexts/AppContext";
import { useAccessControl } from "hooks/useAccessControl";
import { usePortfoliosData } from "hooks/usePortfoliosData";

export const BitcoinReport: React.FC = () => {
  const hasAccess = useAccessControl("reports");
  const { cacheActiveReport } = usePortfoliosData();
  const [report, setReport] = useState<ReportType | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLargeReport, setIsLargeReport] = useState(false);
  const [addressRelatedTags, setAddressRelatedTags] = useState<{ type: string; subtype?: string }[]>([]);
  const [directionTab, setDirectionTab] = useState(0);
  const handleError = useErrorHandler();
  const { address } = useParams();
  const [transactionListEntityName, setTransactionListEntityName] = useState<string | null>(null);
  const [transactionList, setTransactionsList] = useState<Transaction[]>([]);
  const { entityTypes } = useContext(AppContext);
  const [isAddressInPortfolio, setIsAddressInPortfolio] = useState<boolean>(false);

  const loadTransactions = async (entityName: string | null, address: string, hop: number, isBreakdown: boolean) => {
    const systemTags = ["Hops Exceeded", "Unknown", "Unknown Address", "Unknown Addresses", "Returning Funds"];

    const filterAndReduce = (transfers: EntityTransaction[], direction: "in" | "out") => {
      return transfers
        .filter((transfer) => {
          if (transfer.curHop !== hop) {
            return false;
          }

          if (entityName && !systemTags.includes(entityName) && !isBreakdown) {
            const isUnknownEntity = entityName === "Hops Exceeded" || entityName == "Unknown Address";
            return isUnknownEntity
              ? !transfer.entityName || transfer.entityName === "Unknown"
              : transfer.entityName === entityName;
          } else {
            return transfer.address === address;
          }
        })
        .reduce((acc: any[], transfer) => {
          const txs = (transfer.txs || []).map((tx) => {
            return {
              ...tx,
              direction: direction,
              entityName: transfer.entityName,
              entityType: transfer.entityType,
              controllerTag: transfer.controllerTag,
              controllerType: transfer.controllerType,
              curHop: transfer.curHop,
              address: transfer.address,
            };
          });
          acc = acc.concat(txs);
          return acc;
        }, []);
    };

    const sources = filterAndReduce(report!.sources, "in");
    const destinations = filterAndReduce(report!.destinations, "out");

    const detailTransactions = ([] as Transaction[]).concat(sources).concat(destinations);

    setTransactionsList(detailTransactions);
    setTransactionListEntityName(entityName);
  };

  useEffect(() => {
    const loadData = async () => {
      const controller = new AbortController();
      setIsLoaded(false);
      setReport(null);
      cacheActiveReport(null);
      const reportInfo = await axios.get(`/api/reports/btc/${address}/info`);
      setIsLargeReport(reportInfo.data.isLargeAddress);
      setIsAddressInPortfolio(reportInfo.data.isAddressInSomePortfolio);

      axios
        .get<ReportType>(`/api/reports/btc/${address}`, {
          signal: controller.signal,
        })
        .then((response) => {
          const data = response.data;
          let sourcesTags = data.sources.flatMap((source) => {
            const types = [
              source.entityType,
              source.entitySubType,
              source.controllerType,
              source.entitySubType,
              source.controllerSubType,
            ].flatMap((val) => (val ? [val] : []));
            return types.length > 0 ? getHigherRiskType(entityTypes, types) : [];
          });

          let destinationsTags = data.destinations.flatMap((destination) => {
            const types = [
              destination.entityType,
              destination.entitySubType,
              destination.controllerType,
              destination.controllerSubType,
            ].flatMap((val) => (val ? [val] : []));
            return types.length > 0 ? getHigherRiskType(entityTypes, types) : [];
          });
          destinationsTags = destinationsTags.filter((tag) => tag !== null) as string[];

          sourcesTags = [...new Set(sourcesTags)];

          sourcesTags = sourcesTags.reduce((previousValue: string[], currentValue) => {
            if (!previousValue.includes(currentValue) && currentValue) {
              previousValue.push(currentValue);
            }
            return previousValue;
          }, []);

          destinationsTags = destinationsTags.reduce((previousValue: string[], currentValue) => {
            if (!previousValue.includes(currentValue) && currentValue) {
              previousValue.push(currentValue);
            }
            return previousValue;
          }, []);
          destinationsTags = [...new Set(destinationsTags)];

          const Tags = [...new Set([...sourcesTags, ...destinationsTags])];
          setAddressRelatedTags(
            Tags.map((tag) => ({
              type: tag,
            })),
          );
          const report = response.data;
          report.sources = report.sources.flatMap((tx) => {
            const { entityName, entityType } = getEntityToDisplay(tx, entityTypes);
            const entityDetails = {
              name: tx.entityName,
              type: tx.entityType,
              subType: tx?.entitySubType,
            };
            const amount = tx.txs!.reduce((acc, tx) => acc + tx.amount, 0);
            const value = tx.txs!.reduce((acc, tx) => acc + tx.value, 0);

            return {
              ...tx,
              amount,
              value,
              direction: "in",
              entityName,
              entityType,
              entityDetails,
            };
          });

          report.destinations = report.destinations.flatMap((tx) => {
            const { entityName, entityType } = getEntityToDisplay(tx, entityTypes);
            const entityDetails = {
              name: tx.entityName,
              type: tx.entityType,
              subType: tx?.entitySubType,
            };
            const amount = tx.txs!.reduce((acc, tx) => acc + tx.amount, 0);
            const value = tx.txs!.reduce((acc, tx) => acc + tx.value, 0);
            return {
              ...tx,
              amount,
              value,
              direction: "out",
              entityName,
              entityType,
              entityDetails,
            };
          });
          report.highRisks = report.highRisks.flatMap((tx) => {
            const { entityName, entityType } = getEntityToDisplay(tx, entityTypes);
            return tx.txs!.map((subTx) => {
              return { ...tx, ...subTx, direction: tx.direction, entityName, entityType };
            });
          });
          setReport(report);
          cacheActiveReport(report);
        })
        .catch((error: unknown | AxiosError) => {
          if (error instanceof AxiosError && error.response?.status === 409) {
            toast.error(error.response.data.message);
          } else {
            handleError(error);
          }
        })
        .finally(() => {
          setIsLoaded(true);
        });
    };
    loadData();
  }, [address]);

  return (
    <Grid container maxWidth={1000} marginX="auto" marginTop={5}>
      {!isLoaded && (
        <Grid container item direction="column">
          <Grid item>
            <Loading text={isLargeReport ? "This address is large. Please wait for the report to generate." : ""} />
          </Grid>
        </Grid>
      )}
      {report && (
        <Grid container columnSpacing={3} rowSpacing={4}>
          <PaymentBanner
            hasAccess={hasAccess}
            title="Unlock In-Depth Address Reports"
            description="Upgrade your plan to gain access to detailed address reports with detailed overview of risky transactions."
            style={{
              top: 20,
            }}
          />
          <ReportHeader
            reportType={"btc"}
            cryptoBalance={report.addressInfo.balance}
            address={report.addressInfo.address}
            owner={{
              name: report.addressInfo.entityName,
              type: report.addressInfo.entityType,
              subType: report.addressInfo.entitySubType,
              events: report.addressInfo.ownerEvents,
            }}
            controller={{
              name: report.addressInfo.controllerTag,
              type: report.addressInfo.controllerType,
              subType: report.addressInfo.controllerSubType,
              events: report.addressInfo.controllerEvents,
            }}
            isInPortfolio={isAddressInPortfolio}
            addressRelatedTags={addressRelatedTags}
            labels={report.addressInfo.labels}
            highRiskCount={report.highRisks.length}
          />

          <Grid container item rowSpacing={3}>
            <Metadata
              list={[
                {
                  title: "Balance",
                  value: `${formatCryptoCurrencyValue(report.addressInfo.balance, "btc")} BTC`,
                },
                {
                  title: "Total Received",
                  value: `${formatCryptoCurrencyValue(report.addressInfo.received, "btc")} BTC`,
                },
                {
                  title: "Total Sent",
                  value: `${formatCryptoCurrencyValue(
                    report.addressInfo.received - report.addressInfo.balance,
                    "btc",
                  )} BTC`,
                },
                {
                  title: "Total Transactions",
                  value: report.addressInfo.txCount,
                },
                {
                  title: "First Transaction",
                  value: formatDate(report.addressInfo.firstTx),
                },
                {
                  title: "Last Transaction",
                  value: formatDate(report.addressInfo.lastTx),
                },
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container item xs={12} alignItems="center" rowSpacing={2}>
              <Grid item container columnSpacing={1}>
                <Grid item>
                  <Button
                    color={directionTab === 0 ? "primary" : "secondary"}
                    variant={directionTab === 0 ? "contained" : "outlined"}
                    onClick={() => setDirectionTab(0)}
                    style={{
                      boxShadow: "none",
                      padding: "4px 20px",
                      height: "40px",
                    }}
                  >
                    Sources
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color={directionTab === 1 ? "primary" : "secondary"}
                    variant={directionTab === 1 ? "contained" : "outlined"}
                    onClick={() => setDirectionTab(1)}
                    style={{
                      boxShadow: "none",
                      padding: "4px 20px",
                      height: "40px",
                    }}
                  >
                    Destinations
                  </Button>
                </Grid>
              </Grid>
              <Grid item>
                {isLargeReport && (
                  <Grid item xs="auto">
                    <LargeReportMessage />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <BitcoinTransactionSummary
              onTxCountClick={loadTransactions}
              originAddress={report.addressInfo.address}
              direction={directionTab === 0 ? "incoming" : "outgoing"}
              transactions={directionTab === 0 ? report.sources : report.destinations}
            />
          </Grid>
          <Grid item xs={12}>
            <HighRiskTransactions transactions={report.highRisks} chain="btc" />
          </Grid>
        </Grid>
      )}

      {transactionListEntityName && report && (
        <TransactionsList
          counterPartyName={transactionListEntityName}
          originEntityName={report.addressInfo.entityName as string}
          originAddress={report.addressInfo.address}
          transactions={transactionList}
          defaultTab={directionTab === 0 ? "Sources" : "Destinations"}
          coin="btc"
          onClose={() => setTransactionListEntityName(null)}
        />
      )}
    </Grid>
  );
};
