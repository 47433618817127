import React from "react";
import * as S from "./styled";

type Props = {
  children: React.ReactNode[] | React.ReactNode | string;
  color?: keyof Theme["colors"];
  variant?: "regular" | "small";
  textAlign?: "left" | "center" | "right";
};

const Title = ({ children, color = "black", variant = "regular", textAlign = "left" }: Props) => (
  <S.Title $variant={variant} color={color} $textalign={textAlign}>
    {children}
  </S.Title>
);

export default Title;
