import { Grid, Chip } from "ui";
import { useTheme } from "styled-components";
import { getScoreColors } from "utils";

type Props = {
  score: number | null;
  entityType?: string;
  value: number | string;
  justifyContent?: string;
  variant?: "expandend" | "compact";
  ignoreScoreColor?: boolean;
  borderRadius?: number;
};

export const RisktrailScore = ({
  score,
  value,
  entityType,
  variant = "expandend",
  justifyContent,
  ignoreScoreColor,
  borderRadius = 4,
}: Props) => {
  const theme: Theme = useTheme() as Theme;
  if (score === null) {
    return null;
  }
  const colors = getScoreColors(score, entityType === "Wallet", theme.colors, ignoreScoreColor);

  return (
    <Grid container item justifyContent={justifyContent}>
      {variant === "compact" ? (
        <Chip
          label={value}
          variant="outlined"
          size="small"
          customBackground={colors.backgroundColor}
          customColor={colors.color}
        />
      ) : (
        <Chip
          label={value}
          // size="small"
          customBackground={colors.backgroundColor}
          customColor={colors.color}
          sx={{
            width: "100%",
            fontWeight: "bold",
            borderRadius: borderRadius,
          }}
        />
      )}
    </Grid>
  );
};

export default RisktrailScore;
