import { Grid, type Columns } from "ui";
import { formatCryptoCurrencyValue, formatDate, formatFiatValue, getTypeToDisplay } from "utils";
import { Address, EntityOverview, EntityType, Tags } from "components";
import { Link as MUILink } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "contexts";
import RiskyEventCount from "pages/Report/RiskyEventCount/RiskyEventCount";
import { TransactionSummaryRow } from "pages/Report/TransactionSummary/TransactionSummary";

type Props = {
  showBreakdown: boolean;
  hideAmounts: boolean;
  chain: "btc" | "eth" | "erc20";
  onTxCountClick?: (
    entityName: string,
    addresses: string[],
    curHop: number,
    showBreakdown: boolean,
    firstTx: string,
    lastTx: string,
  ) => void;
};

const getColumns = ({ showBreakdown, hideAmounts, onTxCountClick, chain }: Props): Columns => {
  const { entityTypes } = useContext(AppContext);
  const hasEventsAccess = true;
  const columns: Columns = hasEventsAccess
    ? [
        {
          field: "ExpandIcon",
          headerName: "",
          width: 30,
          sortable: false,
        },
        {
          field: "entityName",
          headerName: "Name",
          flex: 1,
          renderCell: ({ value, row }) => {
            const numberOfRiskyEvents = row.controllerEvents.length + row.ownerEvents.length;
            return (
              <Grid container columnGap={1} alignItems="center">
                <Grid item>
                  <EntityOverview name={value} avatarVariant="none" />
                </Grid>
                <Grid item>
                  <EntityType type={row.entityType} />
                </Grid>
                {numberOfRiskyEvents > 0 && (
                  <Grid item>
                    <RiskyEventCount count={numberOfRiskyEvents} />
                  </Grid>
                )}
              </Grid>
            );
          },
        },
        {
          field: "value",
          headerName: "Value ($)",
          align: "left",
          width: 104,
          valueFormatter: (value) => formatFiatValue(value, chain),
        },
        {
          field: "firstTx",
          headerName: "First Tx",
          align: "left",
          width: 104,
          valueFormatter: (value) => formatDate(value),
        },
        {
          field: "lastTx",
          headerName: "Last Tx",
          align: "left",
          width: 104,
          valueFormatter: (value) => formatDate(value),
        },
        {
          field: "txCount",
          headerName: "Tx Count",
          align: "left",
          width: 104,
          renderCell: ({ row, value }) => {
            return onTxCountClick ? (
              <MUILink
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  onTxCountClick(row.entityName, row.addresses, row.curHop, showBreakdown, row.firstTx, row.lastTx)
                }
              >
                {value}
              </MUILink>
            ) : (
              value
            );
          },
        },
        { field: "curHop", headerName: "Hops", width: 70 },
      ]
    : [
        {
          field: "entityName",
          headerName: "Tag",
          flex: 1,
          renderCell: ({ value }) => {
            return <EntityOverview name={value} avatarVariant="none" />;
          },
        },
        {
          field: "entityType",
          headerName: "Type",
          width: 180,
          renderCell: ({ row, value }) => {
            const typeToDisplay = getTypeToDisplay(entityTypes, row);
            return value ? (
              <Tags
                tags={[
                  {
                    type: typeToDisplay.name,
                  },
                ]}
                short
              />
            ) : (
              "Unknown"
            );
          },
        },
        {
          field: "value",
          headerName: "Value ($)",
          align: "left",
          minWidth: 95,
          valueFormatter: (value) => formatFiatValue(value, chain),
        },
        {
          field: "firstTx",
          headerName: "First Tx",
          align: "left",
          valueFormatter: (value) => formatDate(value),
          valueGetter: (params: { row: TransactionSummaryRow }) => {
            return new Date(params.row.firstTx);
          },
        },
        {
          field: "lastTx",
          headerName: "Last Tx",
          align: "left",
          valueFormatter: (value) => formatDate(value),
          valueGetter: (params: { row: TransactionSummaryRow }) => {
            return new Date(params.row.lastTx);
          },
        },
        {
          field: "txCount",
          headerName: "Tx Count",
          align: "left",
          renderCell: ({ row, value }) => {
            return onTxCountClick ? (
              <MUILink
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  return onTxCountClick(
                    row.addresses,
                    row.entityName,
                    row.curHop,
                    showBreakdown,
                    row.firstTx,
                    row.lastTx,
                  );
                }}
              >
                {value}
              </MUILink>
            ) : (
              value
            );
          },
        },
        { field: "curHop", headerName: "Hops", maxWidth: 70 },
      ];

  if (showBreakdown) {
    if (hasEventsAccess) {
      columns.splice(1, 0, {
        field: "address",
        headerName: "Address",
        width: 110,
        renderCell: ({ value, row }) => {
          const labels = row?.labels?.join(", ");
          const link = `/report/${chain === "erc20" ? "eth" : chain}/${value}`;
          return value ? <Address labels={labels} link={link} address={value?.toString()} /> : "";
        },
      });
    } else {
      columns.unshift({
        field: "address",
        headerName: "Address",
        width: 100,
        renderCell: ({ value }) => {
          const link = `/report/${chain === "erc20" ? "eth" : chain}/${value}`;
          return value ? <Address link={link} address={value?.toString()} /> : "";
        },
      });
    }
  }

  if (!hideAmounts) {
    columns.splice(showBreakdown ? 3 : 2, 0, {
      field: "amount",
      headerName: "Amount",
      align: "left",
      width: 104,
      valueFormatter: (value) => formatCryptoCurrencyValue(value, chain),
    });
  }

  return columns;
};

export default getColumns;
