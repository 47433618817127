import { CheckCircleIcon, WarningSummary, Grid } from "ui";

type RiskMessageProps = {
  iconType: "event" | "transactions";
  message: string;
  count?: number;
};

export const RiskyCounterpartyMessage = ({ iconType, message, count }: RiskMessageProps) => {
  const IconComponent = iconType === "transactions" ? WarningSummary : CheckCircleIcon;

  return (
    <Grid item container columnGap={1} sx={(theme) => ({ color: theme.colors.red, fontSize: "14px" })}>
      <IconComponent />
      <Grid>{count ? `${count} ${message}` : message}</Grid>
    </Grid>
  );
};
