import SolvencyDisclosure from "./SolvencyDisclosure";
import { Grid, Label, Subtitle } from "ui";
import { abbrNum } from "utils";
import { Profile, EntitySolvency, EntityReserves, EntityCompliance, PrivacyToken } from "types";
import * as S from "./styled";
import React from "react";
import { TokenTags } from "../../pages";
import OverviewList from "./OverviewList";
import { CheckmarkIcon } from "../Checkmark/Checkmark";

type Props = {
  entity: Profile;
  volume?: number;
  print: boolean;
  solvency: EntitySolvency | null;
  reservers: EntityReserves | null;
};

const fullyEncryptedTokens: PrivacyToken[] = ["XMR"];
const partiallyEncryptedTokens: PrivacyToken[] = ["DASH", "ZEC", "ROSE", "XVG"];

function getPrivacyTokenTradingInfo(value: PrivacyToken[]): {
  tooltip: string;
  icon: CheckmarkIcon;
} {
  if (value.includes("Unknown")) {
    return {
      tooltip: "Evidence that the VASP permits trading fully or partially encrypted tokens.",
      icon: "danger",
    };
  }
  if (fullyEncryptedTokens.some((element) => value.includes(element))) {
    return {
      tooltip: "Evidence that the VASP permits trading fully encrypted tokens.",
      icon: "danger",
    };
  }
  if (partiallyEncryptedTokens.some((element) => value.includes(element))) {
    return {
      tooltip: "Evidence that the VASP permits trading partially encrypted tokens.",
      icon: "amberDanger",
    };
  }
  if (value.length === 0) {
    return {
      tooltip:
        "VASP does not permit privacy token trading, either with privacy-enabling features ('partial') or total encryption ('full').",
      icon: "checkmark",
    };
  }
  throw new Error("Invalid privacy token value");
}

export type OverviewItem = {
  label: string;
  value: EntityCompliance | PrivacyToken[];
  icon?: CheckmarkIcon;
  hideTag?: boolean;
  tooltip?: string;
  additionalInfo?: string | React.ReactElement;
};

export const Overview = ({ entity, volume, solvency, reservers, print }: Props) => {
  const complianceItems: OverviewItem[] = [
    {
      label: "Privacy Token Trading",
      value: entity.privacyCoins,
      tooltip: getPrivacyTokenTradingInfo(entity.privacyCoins).tooltip,
      icon: getPrivacyTokenTradingInfo(entity.privacyCoins).icon as CheckmarkIcon,
      hideTag: true,
      additionalInfo: entity.privacyCoins.length > 0 ? <TokenTags tokens={entity.privacyCoins} /> : "No Privacy Tokens",
    },
    {
      label: "Transaction Monitoring",
      tooltip:
        "Whether the VASP integrates transaction monitoring tools, from external vendors ('full'), internally ('partial'), or none at all  ",
      value: entity.transactionMonitoring,
    },
    {
      label: "P2P",
      tooltip: "Whether person-to-person (P2P) trades are enabled in any form on the VASP platform",
      value: entity.p2p.toString() as EntityCompliance,
      hideTag: true,
    },
  ];

  return (
    <>
      {entity.status !== "Inactive" && (
        <Grid container item columnSpacing={2} rowSpacing={5} marginBottom={print ? 0 : 10}>
          <Grid item sm={6} md={print ? 6 : 4}>
            <Subtitle>KYC & Compliance</Subtitle>
            <OverviewList
              items={[
                {
                  label: "AML Policy",
                  tooltip:
                    "Whether the VASP integrates AML checks, including Sanctions & PEP screening and EDD, on all customers",
                  value: entity.amlPolicy,
                },
                {
                  label: "KYC",
                  tooltip:
                    "Whether the VASP requires Know-Your-Customer requirements on registration ('full') or on withdrawal ('partial')",
                  value: entity.KYC,
                  hideTag: false,
                },
                {
                  label: "Compliance Officer",
                  tooltip: "The Existence of an active Compliance Officer or a similarly affiliated position",
                  value: entity.complianceOfficer.toString() as EntityCompliance,
                  hideTag: true,
                },
              ]}
            />
          </Grid>
          <Grid item sm={6} md={print ? 6 : 4}>
            <Subtitle>Security & Data</Subtitle>
            <OverviewList
              items={[
                {
                  label: "ISO27001",
                  value: entity.ISO27001.toString() as EntityCompliance,
                  tooltip: "Whether the VASP has achieved ISO27001 certification",
                  hideTag: true,
                },
                {
                  label: "Systems Audits",
                  tooltip:
                    "Whether the VASP has achieved Service Organisation Control certifications on data & cyber security",
                  value: entity.systemAudits as EntityCompliance,
                },
                {
                  label: "Travel Rule Compliant",
                  tooltip: "Evidence that the VASP has implemented Travel Rule procedures and/or compliance tools",
                  value: entity.travelRuleCompliant.toString() as EntityCompliance,
                  hideTag: true,
                },
              ]}
            />
          </Grid>
          <Grid container item sm={6} md={print ? 6 : 4}>
            <Subtitle>Trading & Monitoring</Subtitle>
            <OverviewList items={complianceItems} />
            {volume && (
              <Grid container item columnSpacing={1} marginTop={2}>
                <S.BarChartIcon />
                <Grid item>
                  <Label>Daily Volume</Label>
                </Grid>
                <Grid item>{"$" + abbrNum(volume!, 2)}</Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      {entity.status !== "Inactive" && (
        <SolvencyDisclosure solvency={solvency} reserves={reservers} entityName={entity.name} print={print} />
      )}
    </>
  );
};

export default Overview;
