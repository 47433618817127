import React from "react";
import { Grid } from "ui";
import { NationFlag } from "components";
import * as S from "./styled";

type JurisdictionsProps = {
  jurisdictions?: string | string[];
};

const Jurisdictions: React.FC<JurisdictionsProps> = ({ jurisdictions }) => {
  if (!jurisdictions) return <span>Unknown</span>;

  const jurisdictionList = Array.isArray(jurisdictions) ? jurisdictions : [jurisdictions];
  return (
    <>
      {jurisdictionList.map((jurisdiction, index) =>
        jurisdiction ? (
          <Grid key={jurisdiction} container columnSpacing={1} flexWrap="nowrap">
            <Grid item>
              <NationFlag countryName={jurisdiction} />
            </Grid>
            <S.Jurisdiction item>{jurisdiction}</S.Jurisdiction>
          </Grid>
        ) : (
          <span key={index}>Unknown</span>
        ),
      )}
    </>
  );
};
export default Jurisdictions;
