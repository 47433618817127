import styled from "styled-components";
import { Tooltip, tooltipClasses, TooltipProps } from "ui";

const TOOLTIP_WIDTH = 328;
const TOOLTIP_PADDING = "24px";

export const ProfileTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, itemType }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.white,
    borderRadius: 16,
    width: "min-content",
    minWidth: TOOLTIP_WIDTH + "px",
    maxWidth: `${itemType == "entity" ? "434px" : TOOLTIP_WIDTH + "px"}`,
    border: `1px solid ${theme.colors.mediumGray}`,
    boxShadow: theme.shadows[1],
    color: theme.colors.black,
  },
  ["&.MuiTooltip-popperInteractive"]: {
    zIndex: 0,
  },
}));

export const Body = styled.div<{ $padding?: string }>`
  padding: ${(props) => (props.$padding ? props.$padding : TOOLTIP_PADDING)};
  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap: 8px;
  letter-spacing: 0.15px;
  overflow: hidden;
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Subtitle = styled.p<{ fontSize?: string; color?: string; $paddingBottom?: string }>`
  margin: 8px 0 0 0;
  font-size: ${(p) => p.fontSize || "14px"};
  font-weight: 500;
  color: ${(p) => p.color || p.theme.colors.darkGray};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 12px;
  padding-bottom: ${(p) => p.$paddingBottom};
`;

export const InfoColumn = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  gap: 16px;
`;

export const RowDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 16px;
`;

export const ProfileButtonText = styled.div`
  display: flex;
  gap: 8px;
`;

export const Type = styled.div`
  display: flex;
  gap: 4px;
`;

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;

export const Title = styled.span<{ width?: string }>`
  width: ${(p) => (p.width ? p.width : "280px")};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 26px;
  display: block;
  & div {
    margin-left: 4px;
  }
`;
export const HeaderContainer = styled.div`
  margin-bottom: 8px;
`;
