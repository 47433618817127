import { Grid, type Columns } from "ui";
import { formatCryptoCurrencyValue, formatDate, formatFiatValue, getTypeToDisplay } from "utils";
import { Address, EntityOverview, EntityType, Tags } from "components";
import { Link as MUILink } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "contexts";
import { hasFeatureAccess } from "utils/featureUtils";
import AuthContext from "contexts/Auth0Context";
import RiskyEventCount from "pages/Report/RiskyEventCount/RiskyEventCount";

type Props = {
  displayAddressBreakdown: boolean;
  chain: "btc";
  onTxCountClick?: (entityName: string, address: string, hop: number, isBreakdown: boolean) => void;
};

const getColumns = ({ displayAddressBreakdown, onTxCountClick, chain }: Props): Columns => {
  const { entityTypes } = useContext(AppContext);
  const { user } = useContext(AuthContext);
  const hasEventsAccess = true;
  const columns: Columns = hasEventsAccess
    ? [
        {
          field: "ExpandIcon",
          headerName: "",
          width: 30,
          sortable: false,
        },
        {
          field: "entityName",
          headerName: "Name",
          flex: 1,
          renderCell: ({ row, value }) => {
            const numberOfRiskyEvents = row.controllerEvents.length + row.ownerEvents.length;
            return (
              <Grid container columnGap={1} alignItems="center">
                <Grid item>
                  <EntityOverview name={value} avatarVariant="none" />
                </Grid>
                <Grid item>
                  <EntityType type={row.entityType} />
                </Grid>
                {numberOfRiskyEvents > 0 && (
                  <Grid item>
                    <RiskyEventCount count={numberOfRiskyEvents} />
                  </Grid>
                )}
              </Grid>
            );
          },
        },
        {
          field: "amount",
          headerName: "Amount",
          align: "left",
          width: 104,
          valueFormatter: (value) => formatCryptoCurrencyValue(value, chain),
        },
        {
          field: "value",
          headerName: "Value ($)",
          align: "left",
          width: 104,
          valueFormatter: (value) => formatFiatValue(value, chain),
        },
        {
          field: "firstTx",
          headerName: "First Tx",
          align: "left",
          width: 104,
          valueFormatter: (value) => formatDate(value),
        },
        {
          field: "lastTx",
          headerName: "Last Tx",
          align: "left",
          width: 104,
          valueFormatter: (value) => formatDate(value),
        },
        {
          field: "txCount",
          headerName: "Tx Count",
          align: "left",
          width: 104,
          renderCell: ({ value, row }) => {
            return onTxCountClick ? (
              <MUILink
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  return onTxCountClick(row.entityName, row.address, row.curHop, displayAddressBreakdown);
                }}
              >
                {value}
              </MUILink>
            ) : (
              value
            );
          },
        },
        { field: "curHop", headerName: "Hops", width: 70 },
      ]
    : [
        {
          field: "entityName",
          headerName: "Name",
          minWidth: 150,
          flex: 4,
          renderCell: ({ value }) => {
            return <EntityOverview name={value} avatarVariant="none" />;
          },
        },
        {
          field: "entityType",
          headerName: "Type",
          flex: 3,
          minWidth: 130,
          renderCell: ({ row, value }) => {
            const typeToDisplay = getTypeToDisplay(entityTypes, row);
            return value ? (
              <Tags
                tags={[
                  {
                    type: typeToDisplay.name,
                  },
                ]}
                short
              />
            ) : (
              "Unknown"
            );
          },
        },
        {
          field: "amount",
          headerName: "Amount",
          align: "left",
          width: 104,
          valueFormatter: (value) => formatCryptoCurrencyValue(value, chain),
        },
        {
          field: "value",
          headerName: "Value ($)",
          align: "left",
          minWidth: 95,
          flex: 2,
          valueFormatter: (value) => formatFiatValue(value, chain),
        },
        {
          field: "firstTx",
          headerName: "First Tx",
          align: "left",
          valueFormatter: (value) => formatDate(value),
        },
        {
          field: "lastTx",
          headerName: "Last Tx",
          align: "left",
          valueFormatter: (value) => formatDate(value),
        },
        {
          field: "txCount",
          headerName: "Tx Count",
          align: "left",
          renderCell: ({ value, row }) => {
            return onTxCountClick ? (
              <MUILink
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  return onTxCountClick(row.entityName, row.address, row.curHop, displayAddressBreakdown);
                }}
              >
                {value}
              </MUILink>
            ) : (
              value
            );
          },
        },
        { field: "curHop", headerName: "Hops", maxWidth: 60, flex: 1 },
      ];

  if (displayAddressBreakdown) {
    if (hasEventsAccess) {
      columns.splice(1, 0, {
        field: "address",
        headerName: "Address",
        width: 110,
        renderCell: ({ value, row }) => {
          const labels = row?.labels?.join(", ");
          const link = `/report/${chain}/${value}`;
          return value ? <Address labels={labels} link={link} address={value?.toString()} /> : "";
        },
      });
    } else {
      columns.unshift({
        field: "address",
        headerName: "Address",
        width: 100,
        renderCell: ({ value }) => {
          const link = `/report/${chain}/${value}`;
          return value ? <Address link={link} address={value?.toString()} /> : "";
        },
      });
    }
  }

  return columns;
};

export default getColumns;
