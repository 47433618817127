import {
  GridColDef,
  GridColumnHeaderParams,
  GridActionsCellItem,
  GridInitialState,
  GridRenderCellParams,
  GridRowParams,
  GridSortModel,
  GridToolbarContainer,
  useGridApiContext,
  GridRowSelectionModel,
  GridPaginationModel,
  GridValidRowModel,
  GridEventListener,
} from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import { FileDownloadIcon } from "ui";
import ExpandableRow from "./ExpandableRow";
import * as S from "./styled";
import { ReactNode, useEffect, useState } from "react";

type Props = {
  rows: any[];
  columns: GridColDef[];
  checkboxSelection?: boolean;
  className?: string;
  autoHeight?: boolean;
  rowHeight?: number;
  onRowClick?: GridEventListener<"rowClick">;
  initialState?: GridInitialState;
  sortModel?: GridSortModel;
  getRowClassName?: (params: GridRowParams) => string;
  fontSize?: "small" | "normal";
  allowExport?: boolean;
  paginationMode?: "client" | "server";
  rowCount?: number;
  columnVisibilityModel?: { [column: string]: boolean };
  onPageChange?: (page: number) => void;
  loading?: boolean;
  hideFooter?: boolean;
  isExpandable?: boolean;
  renderRowDetails?: (params: GridRowParams) => ReactNode;
  onRowSelectionModelChange?: (rowSelectionModel: GridRowSelectionModel) => void;
  selectionModel?: string[];
  expandedRowIds?: (string | number)[];
  onRowExpandToggle?: (rowId: string | number) => void;
  onSortModelChange?: (sortModel: GridSortModel) => void;
  paginationModel?: GridPaginationModel;
  onPaginationModelChange?: (model: GridPaginationModel) => void;
};

const CustomToolbar = () => {
  const apiRef = useGridApiContext();

  const handleExport = () => apiRef.current.exportDataAsCsv();

  return (
    <GridToolbarContainer>
      <IconButton onClick={handleExport}>
        <FileDownloadIcon />
      </IconButton>
    </GridToolbarContainer>
  );
};

// Separate component for expandable row to contain its own state

const Table = ({
  rows,
  columns,
  checkboxSelection = false,
  className,
  rowHeight = 40,
  onRowClick,
  initialState,
  columnVisibilityModel,
  sortModel,
  allowExport = false,
  paginationMode = "client",
  rowCount,
  onPageChange,
  hideFooter = false,
  loading = false,
  isExpandable,
  renderRowDetails,
  onRowSelectionModelChange,
  selectionModel,
  expandedRowIds,
  onRowExpandToggle,
  onSortModelChange,
  paginationModel = { pageSize: 10, page: 0 },
  onPaginationModelChange,
}: Props) => {
  // State to force re-render after initial mount for expandable tables
  const [forceRender, setForceRender] = useState(false);
  // Force a single re-render after initial mount for expandable tables
  useEffect(() => {
    if (isExpandable && !forceRender) {
      // Use requestAnimationFrame to ensure DOM has settled
      requestAnimationFrame(() => {
        setForceRender(true);
      });
    }
  }, [isExpandable, forceRender]);

  // Local state for sort model and pagination model if not controlled externally
  const [localSortModel, setLocalSortModel] = useState<GridSortModel>(
    sortModel || initialState?.sorting?.sortModel || [],
  );
  const [localPaginationModel, setLocalPaginationModel] = useState<GridPaginationModel>(paginationModel);

  // Handlers for sort and pagination changes
  const handleSortModelChange = (newSortModel: GridSortModel) => {
    if (onSortModelChange) {
      onSortModelChange(newSortModel);
    } else {
      setLocalSortModel(newSortModel);
    }
  };

  const handlePaginationModelChange = (newModel: GridPaginationModel) => {
    if (onPaginationModelChange) {
      onPaginationModelChange(newModel);
    } else if (onPageChange) {
      onPageChange(newModel.page);
      setLocalPaginationModel(newModel);
    } else {
      setLocalPaginationModel(newModel);
    }
  };

  return (
    <S.DataGrid
      rows={rows}
      columnVisibilityModel={columnVisibilityModel}
      columns={columns}
      slots={{
        ...(allowExport ? { toolbar: CustomToolbar } : {}),
        row: isExpandable
          ? (props) => (
              <ExpandableRow
                params={{
                  // Handle the case where props.id might be undefined
                  row: props.row,
                  // Don't pass the id directly if it might be undefined
                }}
                columns={columns}
                rowHeight={rowHeight}
                renderRowDetails={renderRowDetails}
                expandedRowIds={expandedRowIds}
                onRowExpandToggle={onRowExpandToggle}
              />
            )
          : undefined,
      }}
      fontSize="small"
      rowHeight={rowHeight}
      getRowHeight={() => rowHeight}
      disableColumnSelector
      disableColumnFilter
      disableColumnMenu
      hideFooter={hideFooter}
      paginationModel={onPaginationModelChange ? paginationModel : localPaginationModel}
      pageSizeOptions={[10]}
      checkboxSelection={checkboxSelection}
      className={className}
      disableRowSelectionOnClick={false}
      onRowClick={onRowClick}
      initialState={initialState}
      sortModel={onSortModelChange ? sortModel : localSortModel}
      onSortModelChange={handleSortModelChange}
      sortingOrder={["asc", "desc"]}
      paginationMode={paginationMode}
      rowCount={rowCount}
      loading={loading}
      onPaginationModelChange={handlePaginationModelChange}
      onRowSelectionModelChange={onRowSelectionModelChange}
      rowSelectionModel={selectionModel}
      sx={{
        "& .MuiDataGrid-virtualScrollerContent": {
          height: "max-content !important",
        },
        "& .MuiDataGrid-virtualScroller": {
          width: isExpandable && !forceRender ? "auto" : "unset",
        },
        "& .MuiDataGrid-main": {
          minHeight: "100px",
        },
        "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
          display: "none",
        },
        "& .MuiDataGrid-virtualScrollerRenderZone": {
          width: "100%",
          position: "unset",
        },
      }}
    />
  );
};

export type ColumnHeaderParams = GridColumnHeaderParams;
export type RowParams = GridRowParams;
export type Cell<T extends GridValidRowModel> = GridRenderCellParams<T>;
export type Columns = GridColDef[];
export { GridActionsCellItem as ActionCell };
export default Table;
