import { EntityName, EntityOverview, LabelValue } from "components";
import { EntityType, Event } from "types";
import { FlagIconNoBackground, Grid } from "ui";
import * as S from "../styled";
import { useTheme } from "@mui/material";
import { getTypeToDisplay } from "utils";

type Props = {
  riskEvents?: Event[] | [];
  labels: string[];
  displayAddressBreakdown?: boolean;
  controllerTag?: string;
  controllerType?: string;
  controllerSubType?: string;
  entityDetails: {
    name: string;
    type: string;
    subType?: string;
  };
  entityType?: string;
  entitySubType?: string;
  entityTypes: EntityType[];
};

export const TransactionDetails = ({
  riskEvents,
  labels,
  displayAddressBreakdown,
  controllerTag,
  controllerType,
  controllerSubType,
  entityDetails,
  entityType,
  entitySubType,
  entityTypes,
}: Props) => {
  const theme = useTheme();
  const isRiskyEvents = riskEvents && riskEvents.length > 0;
  const typeToDisplay = getTypeToDisplay(entityTypes, {
    entityType: entityType ?? null,
    entitySubType: entitySubType ?? null,
    controllerType: controllerType ?? null,
    controllerSubType: controllerSubType ?? null,
  });
  const OwnerControllerInfo = ({ name: name, type, subType }: { name?: string; type?: string; subType?: string }) =>
    name ? (
      <Grid container width="max-content" alignItems="center">
        <EntityName name={name} avatarVariant="none" />
        <S.Type>
          {type}
          {subType ? ` (${subType})` : ""}
        </S.Type>
      </Grid>
    ) : (
      <strong>Unknown</strong>
    );

  const EntityDetail = () => (
    <Grid container alignItems={"flex-start"} flexDirection={"column"} gap={3} alignSelf={"stretch"}>
      <Grid item display={"flex"} gap={"40px"}>
        <LabelValue labelColor="black" value={typeToDisplay.name ?? "Unknown"} label="Type" direction="column" />
        <LabelValue labelColor="black" value={entityDetails.subType ?? "Unknown"} label="Sub-Type" direction="column" />
      </Grid>
      <Grid item>
        <LabelValue
          print
          labelColor="black"
          gridType="container"
          style={{
            display: "flex",
            alignItems: "center",
            color: isRiskyEvents ? theme.colors.red : "black",
          }}
          value={
            isRiskyEvents ? (
              riskEvents.map((event) => {
                return (
                  <Grid container key={event.id} alignItems="center" columnGap={1}>
                    <FlagIconNoBackground color={theme.colors.red} minWidth="20px" />
                    <EntityOverview name={event.name} color={theme.colors.red} />
                  </Grid>
                );
              })
            ) : (
              <span style={{ color: "black", paddingLeft: "8px" }}>None</span>
            )
          }
          label="Risk events"
          direction="column"
        />
      </Grid>
      <Grid item>
        <LabelValue
          print
          labelColor="black"
          value={labels && labels.length > 0 ? labels : "None"}
          label="Entity Labels"
          direction="column"
        />
      </Grid>
    </Grid>
  );
  const AddressDetail = () => (
    <Grid container alignItems="flex-start" flexDirection="column" gap={3} alignSelf="stretch">
      <Grid item>
        <LabelValue
          print
          labelColor="black"
          value={labels.length > 0 ? labels : <strong>None</strong>}
          label="Address labels"
          direction="column"
        />
      </Grid>
      <Grid item display="flex" gap="40px">
        <LabelValue
          labelColor="black"
          print
          value={
            <OwnerControllerInfo name={entityDetails.name} type={entityDetails.type} subType={entityDetails.subType} />
          }
          label="Owner"
          direction="column"
        />
        <LabelValue
          print
          labelColor="black"
          value={<OwnerControllerInfo name={controllerTag} type={controllerType} subType={controllerSubType} />}
          label="Controller"
          direction="column"
        />
      </Grid>
    </Grid>
  );
  return displayAddressBreakdown ? AddressDetail() : EntityDetail();
};
