import { useRef } from "react";
import { IconButton as IconButtonMUI, SxProps } from "@mui/material";
import { Tooltip } from "ui";
import * as S from "./styled";

type Props = {
  children: React.ReactElement;
  text?: string;
  disabled?: boolean;
  tooltip?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  sx?: SxProps<Theme>;
};

export const IconButton = ({
  children,
  text,
  tooltip = "",
  disabled,
  onClick,
  onMouseLeave,
  onMouseEnter,
  sx,
}: Props) => {
  const buttonRef = useRef(null);

  return (
    <Tooltip title={tooltip}>
      {/* https://github.com/mui/material-ui/issues/8416 */}
      <div>
        <IconButtonMUI
          ref={buttonRef}
          onClick={onClick}
          disabled={disabled}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          sx={sx}
        >
          {children}
          {text && <S.Text>{text}</S.Text>}
        </IconButtonMUI>
      </div>
    </Tooltip>
  );
};

export default IconButton;
