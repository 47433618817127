import styled from "styled-components";
import { Table as TableUI, DangerIcon as DangerIconUI, InfoIcon as InfoIconUI, Box as BoxUI } from "ui";
import TabsMUI from "@mui/material/Tabs";

export const DangerIcon = styled(DangerIconUI)`
  fill: ${(props) => props.theme.colors.darkRed as string};
`;

export const IconWrapper = styled.div`
  svg {
    width: 48px;
    height: 48px;
  }
`;

export const RiskScoreLabel = styled.div`
  ${(props) => props.theme.fonts.subTextDarkGray};
  margin-right: 5px;
`;

export const InfoIcon = styled(InfoIconUI)`
  fill: ${(props) => props.theme.colors.darkGray};
  width: 16px;
  height: 16px;
`;

export const AddressInfo = styled(BoxUI)`
  width: 100%;
  background-color: ${(props) => props.theme.colors.lightGray};
  padding: 24px 120px 32px 32px;
`;

export const Tabs = styled(TabsMUI)`
  .MuiTab-root {
    font-size: 16px;
    font-weight: 600;
    text-transform: none;
  }

  .MuiTabs-flexContainer {
    width: max-content;
  }
`;

export const Table = styled(TableUI)`
  &,
  & .MuiDataGrid-cell,
  & .MuiDataGrid-columnHeaders,
  & .MuiDataGrid-footerContainer {
    border-color: ${(p) => p.theme.colors.gray};
  }
`;

export const RiskEvents = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.15px;
  display: flex;
  align-items: center;
  gap: 4px;
`;
export const EventWarning = styled.span`
  display: flex;
  height: 22px;
  padding: 8px 8px 8px 2px;
  align-items: center;
  gap: 2px;
  border-radius: 100px;
  background: ${(p) => p.theme.colors.red};
  color: ${(p) => p.theme.colors.white};
  font-size: 11px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

export const Type = styled.span`
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: ${(p) => p.theme.colors.secondary};
  margin-left: 8px;
`;
