import { Grid, Label } from "ui";
import { Event } from "types";
import { RiskyEventMessage } from "./RiskyEventMessage";
import { RiskyCounterpartyMessage } from "./RiskyCounterpartyMessage";

type Props = {
  highRiskCount: number;
  mediumRiskCount: number;
  events: {
    entityName: string;
    eventProfile: Event;
  }[];
};

export const RiskSummary = ({ highRiskCount, mediumRiskCount, events }: Props) => {
  return (
    <Grid container rowGap={"20px"}>
      <Grid item>
        <Label>Risk Highlights</Label>
      </Grid>
      <Grid item container alignItems="center" columnGap={1}>
        <Grid container rowGap={1} direction="column">
          <RiskyEventMessage events={events} />
          {highRiskCount > 0 && (
            <RiskyCounterpartyMessage
              iconType="transactions"
              message="High Risk Transactions Detected"
              count={highRiskCount}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RiskSummary;
