import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Link as MUILink } from "@mui/material";
import { Grid, Subtitle, Table } from "ui";
import { Address, EntityOverview, EntityType } from "components";
import { useContext } from "react";
import AppContext from "contexts/AppContext";
import { capitalLetter, formatCryptoCurrencyValue, formatDate, formatFiatValue, getEntityToDisplay } from "utils";
import AuthContext from "contexts/Auth0Context";
import { Transaction } from "types";
import { TransactionWithID } from "../../pages/Portfolio/HighRiskTable/columns";
import { TokenTags } from "../../pages";
import RiskyEventCount from "pages/Report/RiskyEventCount/RiskyEventCount";

type Props = {
  transactions: Transaction[];
  chain?: "btc" | "eth" | "erc20";
};

export const HighRiskTransactions = ({ transactions, chain = "btc" }: Props) => {
  const { entityTypes } = useContext(AppContext);
  const { user } = useContext(AuthContext);

  const groupedTransactions: TransactionWithID[] = transactions.map((transaction: Transaction, index) => {
    return {
      ...transaction,
      id: index,
    };
  });

  const columns: GridColDef[] = [
    {
      field: "address",
      headerName: "Address",
      width: 95,
      renderCell: ({ value }) => <Address link={`/report/${chain}/${value}`} address={value} />,
    },
    {
      field: "entityName",
      headerName: "Name",
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams<TransactionWithID>) => {
        const { entityName, entityType } = getEntityToDisplay(row, entityTypes);
        const numberOfRiskyEvents = row.controllerEvents.length + row.ownerEvents.length;

        return (
          <Grid container columnGap={1} alignItems="center" wrap="nowrap">
            <Grid item>
              <EntityOverview name={entityName} avatarVariant="none" />
            </Grid>
            <Grid item>
              <EntityType type={entityType} />
            </Grid>
            {numberOfRiskyEvents > 0 && (
              <Grid item>
                <RiskyEventCount count={numberOfRiskyEvents} />
              </Grid>
            )}
          </Grid>
        );
      },
    },

    {
      field: "date",
      headerName: "Date",
      width: 100,
      valueFormatter: (value) => formatDate(value),
    },
    {
      field: "amount",
      headerName: "Amount",
      align: "left",
      width: 115,
      valueFormatter: (value) => formatCryptoCurrencyValue(value, chain),
    },
    {
      field: "value",
      headerName: "Value ($)",
      type: "number",
      align: "left",
      width: 95,
      valueFormatter: (value) => formatFiatValue(value, chain),
    },
    {
      field: "symbol",
      headerName: "Asset",
      align: "left",
      width: 90,
      renderCell: ({ value }: GridRenderCellParams<TransactionWithID>) => {
        return <TokenTags tokens={value ? [value] : []} />;
      },
    },
    {
      field: "direction",
      headerName: "Direction",
      align: "left",
      width: 95,
      valueFormatter: (value) => capitalLetter(value),
    },
    {
      field: "hop",
      width: 65,
      headerName: "Hops",
    },
    {
      field: "hash",
      headerName: "Hash",
      width: 80,
      type: "string",
      renderCell: ({ value }) => (
        <MUILink
          href={
            chain === "eth" || chain === "erc20"
              ? `https://etherscan.io/tx/${value}`
              : `https://www.blockchain.com/${chain}/tx/${value}`
          }
          target="_blank"
          rel="noreferrer"
        >
          {value.slice(0, 5)}...
        </MUILink>
      ),
    },
  ];

  return (
    <Grid item container rowSpacing={2} direction="column" margin={0} overflow="auto">
      <Grid item justifyContent="left" padding={0}>
        <Subtitle>High Risk Transactions</Subtitle>
      </Grid>
      <Grid item>
        <Table
          allowExport={user.isAdmin}
          fontSize="small"
          columns={columns}
          rows={groupedTransactions}
          initialState={{
            sorting: {
              sortModel: [{ field: "amount", sort: "desc" }],
            },
          }}
        />
      </Grid>
    </Grid>
  );
};
