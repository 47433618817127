import styled from "styled-components";

export const EventWarning = styled.span<{ color: string; $backgroundColor: string }>`
  display: flex;
  height: 22px;
  padding: 8px 8px 8px 2px;
  align-items: center;
  gap: 2px;
  border-radius: 100px;
  background: ${(p) => p.$backgroundColor};
  color: ${(p) => p.color};
  font-size: 11px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

export const EntityType = styled.span`
  color: ${(p) => p.theme.colors.darkGray};
  font-size: 14px;
  font-weight: 500;
`;
