import { Columns, Grid } from "ui";

import { TokenTags } from "../TokensTags";
import { formatFiatValue } from "utils";
import EventTag from "components/EventTag/EventTag";
import { NationFlag, EntityName } from "components";

const getColumns = (): Columns => [
  {
    field: "date",
    headerName: "Date",
    width: 120,
  },

  {
    field: "target",
    headerName: "Target",
    display: "flex",
    width: 250,
    renderCell: ({ value }) => <EntityName key={value} name={value} avatarVariant="small" />,
  },
  {
    field: "category",
    headerName: "Category",
    width: 190,
    renderCell: (params) => <EventTag type="Hack" label={params.value} />,
  },
  {
    field: "estimatedAmountStolen",
    headerName: "Est. Amount Stolen",
    width: 190,
    valueFormatter: (value) => (value ? `$${formatFiatValue(value)}` : ""),
  },

  {
    field: "jurisdiction",
    headerName: "Jurisdiction",
    width: 140,
    renderCell: (params) => {
      return (
        <Grid item container flexWrap="nowrap" columnSpacing={1}>
          <Grid item>
            <NationFlag countryName={params.value} />
          </Grid>
          <Grid item>{params.value}</Grid>
        </Grid>
      );
    },
  },
  {
    field: "tokens",
    headerName: "Tokens",
    minWidth: 200,
    flex: 1,
    renderCell: (params) => {
      return <TokenTags tokens={params.value} />;
    },
  },
];

export default getColumns;
