import { FormControlLabel } from "@mui/material";
import { TokenMetadata } from "types/ethereum";
import { Link as MUILink } from "@mui/material";
import { formatCryptoCurrencyValue, formatFiatValue, formatDate } from "utils";

import * as S from "./../styled";
import { isBefore, isAfter } from "date-fns";
import { useState } from "react";
import { Switch, Grid } from "ui";
type Props = {
  tokens: TokenMetadata[];
  onTxCountClick: (tokenAddress: string, tokenSymbol: string) => void;
};

type TokenMetadaDatefields = Pick<
  TokenMetadata,
  "firstIncomingTransaction" | "firstOutgoingTransaction" | "lastIncomingTransaction" | "lastOutgoingTransaction"
>;

const getTransactionDate = (
  metadata: TokenMetadata,
  firstDateField: keyof TokenMetadaDatefields,
  secondDateField: keyof TokenMetadaDatefields,
  comparator: (date1: Date, date2: Date) => boolean,
) => {
  if (!metadata[firstDateField] || !metadata[secondDateField]) {
    return metadata[firstDateField] ? metadata[firstDateField] : metadata[secondDateField];
  }

  const transaction1 = new Date(metadata[firstDateField]);
  const transaction2 = new Date(metadata[secondDateField]);

  if (comparator(transaction1, transaction2)) {
    return metadata[firstDateField];
  } else {
    return metadata[secondDateField];
  }
};

export const ERC20Summary = ({ tokens, onTxCountClick }: Props) => {
  const [showAllTokens, setShowAllTokens] = useState(false);

  const rows =
    tokens
      .filter((token) => showAllTokens || token.whiteListedToken)
      .map((token: TokenMetadata, index: number) => ({
        ...token,
        id: token.tokenSymbol + index, // There can be repeating symbols so index is added
      })) || [];

  return (
    <Grid container item xs={12}>
      <Grid container xs={12} direction="column" rowSpacing={2}>
        <Grid item alignSelf="flex-end">
          <FormControlLabel
            control={<Switch size="small" checked={showAllTokens} onChange={() => setShowAllTokens((prev) => !prev)} />}
            label="Show All Tokens"
            sx={{
              fontSize: "14px",
              marginRight: "0px",
            }}
          />
        </Grid>
        <Grid item>
          <S.Table
            fontSize="small"
            columns={[
              {
                headerName: "Token",
                field: "tokenName",
                flex: 5,
                renderCell: (params) => {
                  return <b>{params.value || "Unknown ERC20 Token"}</b>;
                },
              },
              {
                headerName: "Balance",
                field: "balance",
                valueFormatter: (value) => formatCryptoCurrencyValue(value, "erc20"),
                flex: 4,
              },
              {
                headerName: "Value ($)",
                field: "value",
                valueFormatter: (value) => {
                  return formatFiatValue(value);
                },
                flex: 3,
              },
              {
                headerName: "Transactions",
                field: "txCount",
                flex: 4,
                renderCell: ({ row }) => (
                  <MUILink
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      return onTxCountClick(row.tokenAddress, row.tokenSymbol);
                    }}
                  >
                    {row.incomingTransactionCount + row.outgoingTransactionCount}
                  </MUILink>
                ),
              },
              {
                headerName: "Total Received",
                field: "incomingAmount",
                valueFormatter: (value) => {
                  return formatCryptoCurrencyValue(value, "erc20");
                },

                flex: 4,
              },
              {
                headerName: "First Tx",
                field: "firstDate",
                renderCell: (params) => {
                  const date = getTransactionDate(
                    params.row,
                    "firstIncomingTransaction",
                    "firstOutgoingTransaction",
                    isBefore,
                  );
                  return formatDate(date);
                },
                flex: 4,
              },
              {
                headerName: "Last Tx",
                field: "lastDate",
                flex: 4,
                renderCell: (params) => {
                  const date = getTransactionDate(
                    params.row,
                    "lastIncomingTransaction",
                    "lastOutgoingTransaction",
                    isAfter,
                  );
                  return formatDate(date);
                },
              },
            ]}
            rows={rows}
            initialState={{
              sorting: {
                sortModel: [{ field: "value", sort: "desc" }],
              },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ERC20Summary;
