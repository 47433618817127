import React, { createContext, useState, useEffect, useContext } from "react";
import { Loading } from "ui";
import axios from "axios";
import { useErrorHandler } from "hooks";
import AuthContext from "./Auth0Context";
import type { EntityType, ScamProfile, Profile } from "types";
import { HackProfile, SanctionProfile } from "types/Events";
import PaymentConfirmation from "components/PaymentBanner/PaymentConfirmation";
import { toast } from "react-toastify";
type Props = {
  children: React.ReactNode;
};

export type AppContextType = {
  entities: Profile[];
  events: {
    hacks: HackProfile[];
    sanctions: SanctionProfile[];
    scams: ScamProfile[];
  };
  entityTypes: EntityType[];
  addressLabels: EntityLabel[];
  hasNewNotifications: boolean;
  setHasNewNotifications: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPaymentConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  isPaymentLoading: boolean;
  isPaid: boolean;
};

type EntityLabel = {
  name: string;
};

type EntitiesResponse = {
  entities: Profile[];
  types: EntityType[];
  labels: EntityType[];
};

export const AppContext: React.Context<AppContextType> = createContext<AppContextType>({
  /* eslint-disable */
  entities: [],
  events: {
    hacks: [],
    sanctions: [],
    scams: [],
  },
  entityTypes: [],
  addressLabels: [],
  hasNewNotifications: false,
  setHasNewNotifications: () => {},
  setIsPaymentConfirmation: () => {},
  isPaymentLoading: false,
  isPaid: false,
});

export const AppProvider = ({ children }: Props) => {
  const [entities, setEntities] = useState<Profile[]>([]);
  const [events, setEvents] = useState<{
    hacks: HackProfile[];
    sanctions: SanctionProfile[];
    scams: ScamProfile[];
  }>({ hacks: [], sanctions: [], scams: [] });
  const [entityTypes, setEntityTypes] = useState<EntityType[]>([]);
  const [addressLabels, setAddressLabels] = useState<EntityLabel[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasNewNotifications, setHasNewNotifications] = useState<boolean>(false);
  const [isPaymentConfirmation, setIsPaymentConfirmation] = useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [isPaid, setIsPaid] = useState(false);

  const { user } = useContext(AuthContext);

  const handleError = useErrorHandler();

  const fetchData = async () => {
    setIsLoaded(false);
    try {
      const [entitiesResponse, hacksResponse, sanctionsResponse, scamsResponse, hasNewNotificationsResponse] =
        await Promise.all([
          axios.get<EntitiesResponse>("/api/entities"),
          axios.get("/api/entities/hacks"),
          axios.get("/api/entities/sanctions"),
          axios.get("/api/entities/scams"),
          axios.get("/api/notifications/has-new"),
        ]);

      setHasNewNotifications(hasNewNotificationsResponse.data);

      let entities = entitiesResponse.data.entities;
      if (user && !user.isAdmin) {
        entities = entities.filter((entity) => {
          return entity.description && entity.description.trim() !== "";
        });
      }
      setEntities(entities);
      setEntityTypes(entitiesResponse.data.types);
      setAddressLabels(entitiesResponse.data.labels);
      setEvents({
        hacks: Array.isArray(hacksResponse.data) ? hacksResponse.data : [],
        sanctions: sanctionsResponse.data,
        scams: scamsResponse.data,
      });
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const checkPaymentStatus = async (attempts = 0) => {
    try {
      const response = await axios.get("/api/users/payments/status");
      if (response.data.payment_status === "paid") {
        toast.success("Payment confirmed! Thank you for your purchase.");
        setIsPaymentLoading(false);
        setIsPaid(true);
        setTimeout(() => {
          window.location.reload();
        }, 300);
        return;
      }

      if (attempts < 12) {
        setTimeout(() => checkPaymentStatus(attempts + 1), 5000);
      } else {
        toast.error("We’ll notify you once the payment is confirmed. Please check back later.");
        setIsPaymentLoading(false);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const onConfirm = async () => {
    try {
      setIsPaymentLoading(true);
      const response = await axios.put("/api/users/subscriptions");

      if (response.status === 200) {
        setIsPaymentConfirmation(false);
        checkPaymentStatus();
      }
    } catch (error) {
      handleError(error);
      setIsPaymentConfirmation(false);
      toast.error("Oops! Something went wrong during the payment process. Please try again.");
    }
  };
  return (
    <AppContext.Provider
      value={{
        entities: entities,
        events: events,
        entityTypes: entityTypes,
        addressLabels: addressLabels,
        hasNewNotifications,
        setHasNewNotifications,
        setIsPaymentConfirmation,
        isPaid,
        isPaymentLoading,
      }}
    >
      {!isLoaded ? <Loading /> : children}
      {isPaymentConfirmation && (
        <PaymentConfirmation onConfirm={() => onConfirm()} onCancel={() => setIsPaymentConfirmation(false)} />
      )}
    </AppContext.Provider>
  );
};

export default AppContext;
