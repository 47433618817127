import { useRef, useState, useMemo } from "react";

import { Button, CheckCircleIcon, Grid, StarBorderIcon, StarIcon } from "ui";
import { PortfolioMenu } from "pages/Report/ReportHeader/PortfolioMenu";
import { usePortfoliosData } from "hooks/usePortfoliosData";

type Props = {
  address: string;
  isInPortfolio: boolean;
};

export const PortfolioActions = ({ address, isInPortfolio }: Props) => {
  const {
    portfolios,
    loadPortfolios,
    createPortfolio,
    arePortfoliosLoaded,
    addAddressReportToPortfolio,
    removeAddressReportFromPortfolio,
  } = usePortfoliosData();

  /*
   * PORTFOLIOS LIST - list of portfolios to show in dropdown menu
   */
  const portfoliosContainsThisAddress = useMemo(() => {
    if (!arePortfoliosLoaded) return isInPortfolio;
    return portfolios.some((portfolio) => portfolio.addresses.includes(address));
  }, [portfolios, address, arePortfoliosLoaded, isInPortfolio]);

  /*
   * DD BUTTON - dropdown button
   */
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [buttonIsAnimatingAdd, setButtonIsAnimatingAdd] = useState(false);
  const [buttonIsAnimatingRemove, setButtonIsAnimatingRemove] = useState(false);
  const [buttonAnimatingPortfolioName, setButtonAnimatingPortfolioName] = useState<string>("");

  const buttonText = useMemo(() => {
    if (buttonIsAnimatingAdd) return `Added to "${buttonAnimatingPortfolioName}"`;
    if (buttonIsAnimatingRemove) return `Removed from "${buttonAnimatingPortfolioName}"`;
    return portfoliosContainsThisAddress ? "Added to Portfolio" : "Add to Portfolio";
  }, [buttonIsAnimatingAdd, buttonIsAnimatingRemove, portfoliosContainsThisAddress, buttonAnimatingPortfolioName]);

  const buttonIcon = useMemo(() => {
    if (buttonIsAnimatingAdd) return <CheckCircleIcon color="success" />;
    if (buttonIsAnimatingRemove) return <CheckCircleIcon color="warning" />;
    return portfoliosContainsThisAddress ? <StarIcon color="primary" /> : <StarBorderIcon />;
  }, [buttonIsAnimatingAdd, buttonIsAnimatingRemove, portfoliosContainsThisAddress]);

  const buttonColor = useMemo(() => {
    if (buttonIsAnimatingAdd) return "success";
    if (buttonIsAnimatingRemove) return "warning";
    return portfoliosContainsThisAddress ? "primary" : "secondary";
  }, [buttonIsAnimatingAdd, buttonIsAnimatingRemove, portfoliosContainsThisAddress]);

  const buttonAnimateAdded = (portfolioName: string) => {
    setButtonAnimatingPortfolioName(portfolioName);
    setButtonIsAnimatingAdd(true);
    setTimeout(() => setButtonIsAnimatingAdd(false), 1500);
  };

  const buttonAnimateRemoved = (portfolioName: string) => {
    setButtonAnimatingPortfolioName(portfolioName);
    setButtonIsAnimatingRemove(true);
    setTimeout(() => setButtonIsAnimatingRemove(false), 1500);
  };

  /*
   * MENU - dropdown menu state
   */
  const [menuIsOpened, setMenuIsOpened] = useState(false);

  return (
    <Grid justifyItems="start">
      <Button
        ref={buttonRef}
        icon={buttonIcon}
        variant="outlined"
        color={buttonColor}
        animateOnChange={buttonIsAnimatingAdd || buttonIsAnimatingRemove}
        onClick={() => {
          loadPortfolios(true);
          setMenuIsOpened(true);
        }}
      >
        {buttonText}
      </Button>
      <PortfolioMenu
        reportAddress={address}
        addToPortfolio={(portfolioID: number, portfolioName: string) => {
          setMenuIsOpened(false);
          addAddressReportToPortfolio(portfolioID);
          buttonAnimateAdded(portfolioName);
        }}
        removeFromPortfolio={(portfolioID: number, portfolioName: string) => {
          setMenuIsOpened(false);
          removeAddressReportFromPortfolio(portfolioID, address);
          buttonAnimateRemoved(portfolioName);
        }}
        open={menuIsOpened}
        anchor={buttonRef.current}
        onClose={() => setMenuIsOpened(false)}
        portfolios={portfolios}
        createPortfolio={createPortfolio}
      />
    </Grid>
  );
};

export default PortfolioActions;
