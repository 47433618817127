import { useCopyText } from "hooks/useCopyText";
import { Grid, Button, ContentCopyIcon } from "ui";

type Props = {
  address: string;
};

export const ReportActions = ({ address }: Props) => {
  const { copyText, animate, handleCopy, color } = useCopyText({
    initialText: "Copy",
    successText: "Address Copied",
    resetDelay: 1500,
  });

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(address);
      handleCopy();
    } catch (err) {
      console.error("Failed to copy text: ", err);
      // Fallback for older browsers
      const textArea = document.createElement("textarea");
      textArea.value = address;
      textArea.style.position = "fixed"; // Avoid scrolling to bottom
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      document.body.removeChild(textArea);
      handleCopy();
    }
  };

  return (
    <Grid>
      <Button
        icon={<ContentCopyIcon />}
        buttonTheme="cancel"
        variant="outlined"
        color={color}
        onClick={copyToClipboard}
        animateOnChange={animate}
      >
        {copyText}
      </Button>
    </Grid>
  );
};

export default ReportActions;
