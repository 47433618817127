import { LabelValue } from "components";
import { SanctionProfile } from "types";
import { Grid, Tag } from "ui";
import React from "react";
import { useAccessControl } from "hooks/useAccessControl";

type Props = {
  sanction: SanctionProfile;
};

export const SanctionItem: React.FC<Props> = ({ sanction }: Props) => {
  const hasAccess = useAccessControl("profiles");

  return (
    <Grid container item direction="column" rowSpacing={1} padding={2}>
      <Grid item>
        <Tag color="white" backgroundColor="red" label="Sanction" />
      </Grid>
      <Grid item>
        <b>{sanction.name}</b>
      </Grid>
      {hasAccess && (
        <Grid container item direction="column">
          <Grid container item>
            <Grid sm={4}>
              <LabelValue
                labelColor="darkGray"
                label="Entity Type"
                value={sanction.type.name}
                valueBold={true}
                width={250}
              />
            </Grid>
            <Grid sm={4}>
              <LabelValue label="Date" labelColor="darkGray" value={sanction?.date} valueBold={true} width={250} />
            </Grid>
            <Grid sm={4}>
              <LabelValue
                label="Enforcement Body"
                labelColor="darkGray"
                value={sanction?.enforcementBody}
                valueBold={true}
                width={250}
              />
            </Grid>

            {/* <Grid item style={{ textOverflow: "ellipsis" }}>
          {shortenSynopsis(sanction.Synopsis)}
        </Grid> */}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
export default SanctionItem;
