import { EntityName, EntityOverview } from "components";
import { AppContext } from "contexts";
import { useErrorHandler } from "hooks";
import { SanctionDetail } from "pages/Events";
import HackDetails from "pages/Events/Hacks/HackDetail";
import { useContext } from "react";
import { Colors } from "styles";
import { ErrorOutlineIcon, Grid } from "ui";
import { getRiskColor, formatDate } from "utils";
import { EntityType, HackProfile, SanctionProfile } from "types";
import { useTheme } from "@mui/material";

type Props = {
  events: {
    entityName: string;
    eventProfile: HackProfile | SanctionProfile;
  }[];
};

export const RiskyEventMessage = ({ events }: Props) => {
  const { events: contextEvents } = useContext(AppContext);
  const handleError = useErrorHandler();
  const theme = useTheme();

  // Early return if no events
  if (!events || events.length === 0) return null;

  // Define the EventProp type based on the event structure
  type EventProp = {
    entityName: string;
    eventProfile: HackProfile | SanctionProfile;
  };

  // Group events by entity name and type
  const groupedEvents = events.reduce((groups, event) => {
    const entityName = event.entityName;
    const eventProfile = event.eventProfile;
    // Create grouping key
    const key = `${entityName}_${eventProfile.type}`;

    if (!groups[key]) {
      groups[key] = {
        entityName,
        eventType: eventProfile.type,
        events: [],
      };
    }

    groups[key].events.push({
      entityName: event.entityName,
      eventProfile: eventProfile,
    });
    return groups;
  }, {} as Record<string, { entityName: string; eventType: EntityType; events: EventProp[] }>);

  const getRiskComponent = (event: HackProfile | SanctionProfile, eventType: string) => {
    if (eventType.toUpperCase() === "HACK") {
      const hackEvent = contextEvents.hacks.find((hack) => hack?.id === event?.id);
      return hackEvent ? <HackDetails hackId={hackEvent.id} /> : null;
    } else if (eventType.toUpperCase() === "SANCTIONS") {
      const sanctionEvent = contextEvents.sanctions.find((sanction) => sanction?.id === event?.id);
      return sanctionEvent ? <SanctionDetail sanctionId={sanctionEvent.id} /> : null;
    } else {
      handleError(`${eventType} is not an event type`);
      return null;
    }
  };

  // If no valid grouped events, return null
  if (Object.keys(groupedEvents).length === 0) return null;

  return (
    <>
      {Object.values(groupedEvents).map((group, groupIndex) => {
        // const color = getRiskColor(group.events[0].type?.risk) as keyof Colors;
        const color = getRiskColor("HIGH") as keyof Colors;

        return (
          <Grid
            container
            columnGap="6px"
            sx={(theme) => ({
              color: theme.colors[color],
              fontSize: "14px",
              height: "auto",
              marginBottom: "8px",
            })}
            alignItems={"flex-start"}
            flexWrap={"nowrap"}
            key={`${group.entityName}_${group.eventType}_${groupIndex}`}
          >
            <Grid container direction="column">
              <Grid container alignItems="center" flexWrap="nowrap" spacing={1}>
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  <ErrorOutlineIcon />
                </Grid>
                <Grid item sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                  <EntityName
                    name={group.entityName}
                    avatarVariant="none"
                    color={theme.colors.red}
                    style={{ fontSize: "14px", lineHeight: "22px" }}
                  />
                </Grid>
                <Grid item>
                  {group.eventType.name === "SANCTIONS"
                    ? ` was sanctioned by ${(group.events[0].eventProfile as SanctionProfile).enforcementBody} `
                    : "is connected to"}
                </Grid>
                &nbsp;
                {group.events.map((eventItem, index) => {
                  const event = eventItem.eventProfile;
                  const riskComponent = getRiskComponent(event, group.eventType.name);
                  const isLastItem = index === group.events.length - 1;

                  return riskComponent ? (
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                      key={`event_${event.id || index}`}
                    >
                      <EntityOverview name={event.name} avatarVariant="none" color={theme.colors.red} />
                      <span style={{ marginLeft: "4px" }}>({event.date ? formatDate(event.date) : ""})</span>
                      {!isLastItem && <span>,&nbsp;</span>}
                    </Grid>
                  ) : (
                    <Grid item sx={{ mr: 2 }} key={`event_${event.id || index}`}>
                      <span>{event.name}</span>
                      {!isLastItem && <span>,&nbsp;</span>}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};
