import { Link } from "react-router-dom";
import { Grid, TableCell } from "ui";
import { Tags, EntityOverview, Address, HashField } from "components";
import {
  capitalLetter,
  formatCryptoCurrencyValue,
  formatDate,
  formatFiatValue,
  getEntityToDisplay,
  getHigherRiskType,
} from "utils";
import { useContext } from "react";
import { Link as UILink } from "ui";
import AppContext from "contexts/AppContext";

import type { Columns } from "ui";
import { Transaction } from "types";
import { TokenTags } from "../../Events";
import { validateAddress } from "utils/address";

type Props = {
  isForPDF?: boolean;
};

export type TransactionWithID = Transaction & { id: number; curHop?: number };

const getColumns = ({ isForPDF }: Props = {}): Columns => {
  const { entityTypes } = useContext(AppContext);

  return [
    {
      field: "clientAddress",
      headerName: "Client Address",
      width: 135,
      renderCell: ({ value }) => {
        const coin = validateAddress(value, "btc") ? "btc" : "eth";
        return <Address link={`/report/${coin}/${value}`} address={value} />;
      },
    },
    {
      field: "address",
      headerName: "Risky Address",
      width: 129,
      renderCell: ({ value }) => {
        const coin = validateAddress(value, "btc") ? "btc" : "eth";
        return <Address link={`/report/${coin}/${value}`} address={value} />;
      },
    },

    {
      field: "entityName",
      headerName: "Name",
      width: isForPDF ? 150 : 200,
      flex: 1,
      renderCell: ({ row }) => {
        const { entityName } = getEntityToDisplay(
          {
            entityName: row.entityName,
            entityType: row.entityType,
            entitySubType: row.entitySubType,
            controllerTag: row.controllerTag,
            controllerType: row.controllerType,
            controllerSubType: row.controllerSubType,
          },
          entityTypes,
        );
        return (
          <TableCell>
            <b>
              {isForPDF ? (
                <Link to={`/entities/${entityName}`}>
                  <UILink>{entityName}</UILink>
                </Link>
              ) : (
                <EntityOverview name={entityName} avatarVariant="none" />
              )}
            </b>
          </TableCell>
        );
      },
    },

    {
      field: "type",
      headerName: "Type",
      flex: 1,
      display: "flex",
      renderCell: ({ row }) => {
        const type = getHigherRiskType(entityTypes, [
          row.entityType ?? "",
          row.entitySubType ?? "",
          row.controllerType ?? "",
          row.controllerSubType ?? "",
        ]);
        return (
          <Grid item container>
            {type ? <Tags tags={[{ type: type }]} /> : null}
          </Grid>
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 100,
      valueFormatter: (value) => (value ? formatDate(value as string) : null),
    },
    {
      field: "amount",
      headerName: "Amount",
      align: "left",
      width: 125,
      renderCell: ({ row }) => {
        const coin = validateAddress(row.address, "btc") ? "btc" : row.erc20 ? "erc20" : "eth";
        return formatCryptoCurrencyValue(row.amount, coin);
      },
    },
    {
      field: "value",
      headerName: "Value ($)",
      type: "number",
      align: "left",
      width: 120,
      renderCell: ({ row, value }) => {
        const coin = validateAddress(row.address, "btc") ? "btc" : row.isERC20 ? "erc20" : "eth";
        return formatFiatValue(value!, coin);
      },
    },
    {
      field: "symbol",
      headerName: "Asset",
      width: 70,
      align: "center",
      headerAlign: "center",
      renderCell: ({ value }) => {
        return <TokenTags tokens={value ? [value] : []} />;
      },
    },
    {
      field: "direction",
      headerName: "Direction",
      align: "left",
      width: 95,
      valueFormatter: (value) => capitalLetter(value),
    },
    {
      field: "hop",
      headerName: "Hops",
      width: 65,
      align: "center",
      headerAlign: "center",
      valueGetter: (value) => {
        return value;
      },
    },
    {
      field: "hash",
      headerName: "Hash",
      width: 75,
      type: "string",
      renderCell: ({ row, value }) => {
        const chain = validateAddress(row.address, "btc") ? "btc" : "eth";
        return <HashField value={value} coin={chain} row={row} />;
      },
    },
  ];
};

export default getColumns;
