import styled from "styled-components";
import { laptopL } from "styles";

import { Input as InputUI, Autocomplete as AutocompleteUI, Button as ButtonUI } from "ui";

export const SearchWrapper = styled.div`
  display: flex;
  height: 72px;
`;

export const AddressSearch = styled.div`
  margin-top: 16px;
  display: flex;
`;

export const Input = styled(InputUI)`
  margin-right: 10px;
  width: 100%;
  background-color: white;
  border-color: red;
  z-index: 100;
  //Change Width on smaller screens
  @media ${laptopL} {
    width: 640px;
  }

  .MuiInputBase-root {
    border-radius: 16px;
    height: 56px;
    &.hover: {
      background: red;
      border-color: ${(p) => p.theme.colors.primary};
    }
  }
`;

export const Autocomplete = styled(AutocompleteUI)`
  width: 600px;
  margin-right: 10px;
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${(p) => p.theme.colors.primary}; // Change the border color
    border-radius: 16px;
  }

  & .MuiInputBase-root {
    /* background-color: ${(prop) => prop.theme.colors.lightGray}; */

    svg {
      path {
        fill: ${(p) => p.theme.colors.primary};
      }
    }
  }
`;

export const Button = styled(ButtonUI)`
  margin-left: 10px;
`;

export const IconWrapper = styled.div`
  svg {
    width: 40px;
    height: 40px;
  }
`;

type SearchBarContainerProps = {
  $isAtTop: boolean;
  $searchResultOpen: boolean;
  $isSmallScreen: boolean;
};

export const SearchBarContainer = styled.div<SearchBarContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  transition: all 0.3s ease-in-out;
  top: ${({ $isAtTop }) => ($isAtTop ? "27px" : "340px")};
  height: ${({ $searchResultOpen: searchResultOpen }) => (searchResultOpen ? "100vh" : "90px")};
  background-color: "red";
  overflow: hidden;
  width: 100%;
  /* width: isSmallScreen ? 100%: 100%; */
`;
