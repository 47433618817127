import styled from "styled-components";
import { Grid } from "ui";

type SearchItemProps = {
  $isFocused: boolean;
};

export const SearchItem = styled(Grid)<SearchItemProps>`
  // add backround effect on hover for MUI grid component

  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }

  background-color: ${(props) => (props.$isFocused ? "#f5f5f5" : "transparent")};
`;
