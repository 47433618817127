import { useEffect, useState, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import LabelValue from "../LabelValue";
import type { NFT } from "types/ethereum";
import { useErrorHandler } from "hooks";
import { Label, Loading, Box, Grid, Link, Card, CardMedia } from "ui";

export const NFTGallery = () => {
  const { address } = useParams();
  const handleError = useErrorHandler();
  const [nfts, setNfts] = useState<NFT[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    const loadNFTData = async (page: number) => {
      try {
        setIsLoaded(false);
        const response = await axios.get(`/api/reports/nft/${address}/${page}`);
        if (response.status === 200 && response.data) {
          setNfts((prevNfts) => [...prevNfts, ...response.data.assets]);
          setTotalPages(response.data.totalPages);
        }
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoaded(true);
      }
    };
    loadNFTData(pageNumber);
  }, [pageNumber, address]);

  useEffect(() => {
    const currentObserver = observer.current;
    return () => {
      if (currentObserver) {
        currentObserver.disconnect();
      }
    };
  }, []);

  const lastNFTElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (isLoaded && pageNumber < totalPages) {
        if (observer.current) {
          observer.current.disconnect();
        }
        observer.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting) {
            setPageNumber((prevPageNumber) => prevPageNumber + 1);
          }
        });
        if (node) {
          observer.current.observe(node);
        }
      }
    },
    [isLoaded, pageNumber, totalPages],
  );

  if (!isLoaded && pageNumber === 1) {
    return (
      <Grid container justifyContent="center" alignItems="center" mt={2}>
        <Loading variant="small" />
      </Grid>
    );
  }

  return (
    <>
      {nfts && nfts.length > 0 ? (
        <Grid container spacing={4}>
          {nfts.map((nft, index) => (
            <Grid
              item
              key={nft.token_address + nft.token_id}
              ref={nfts.length === index + 1 ? lastNFTElementRef : null}
            >
              <Link
                underline="none"
                href={`https://opensea.io/assets/ethereum/${nft.token_address}/${nft.token_id}`}
                target="_blank"
                rel="noreferrer"
              >
                <Card elevation={0} sx={{ width: 208 }}>
                  <Grid container columnSpacing={2} alignItems="center" width="100%" direction="column">
                    <Grid container item>
                      <CardMedia component="img" height="240" image={nft.image || "/default.png"} loading="lazy" />
                    </Grid>
                    <Grid item padding={2}>
                      <LabelValue label={nft.name} value="" />
                    </Grid>
                  </Grid>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box padding={2}>
          <Label size="normal">No NFTs owned by this address.</Label>
        </Box>
      )}
      {!isLoaded && <Loading variant="small" />}
    </>
  );
};
