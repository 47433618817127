import { Card, CardContent, CardHeader } from "@mui/material";
import { parseISO, compareAsc } from "date-fns";
import React from "react";
import { Profile } from "types";
import { ArrowDownwardIcon, ArrowUpwardIcon, Grid, Label, RisktrailScore } from "ui";

type Props = {
  profiles: Profile[];
  onEntitySelected: (entityName: string) => void;
};

import * as S from "./styled";
import { EntityName } from "components";

export const getScoreChange = (currentScore: number, previousScore: number) => {
  const scoreChange = currentScore - previousScore;
  const scoreChangeText = Math.abs(scoreChange).toFixed(2);
  const scoreChangeColor = scoreChange > 0 ? "green" : "red";
  const scoreChangeSign = scoreChange > 0 ? "+" : "-";
  const ArrowIcon =
    scoreChange > 0 ? (
      <ArrowUpwardIcon color="success" fontSize="small" />
    ) : (
      <ArrowDownwardIcon color="error" fontSize="small" />
    );
  return (
    <Grid container item columnSpacing={1}>
      <Grid item>{ArrowIcon}</Grid>
      <S.Scorechange item color={scoreChangeColor}>
        {scoreChangeSign}
        {scoreChangeText}
      </S.Scorechange>
    </Grid>
  );
};

export const AmlRanksChange: React.FC<Props> = ({ profiles, onEntitySelected }: Props) => {
  const rows = profiles
    .filter((profile) => profile.riskScore && profile.previousScore)
    .sort((a, b) => {
      const dateA = a.lastScoreUpdatedAt ? parseISO(a.lastScoreUpdatedAt) : new Date(0);
      const dateB = b.lastScoreUpdatedAt ? parseISO(b.lastScoreUpdatedAt) : new Date(0);
      return compareAsc(dateB, dateA);
    })
    .slice(0, 5);
  return (
    <Card
      sx={(theme) => {
        return {
          overflow: "auto",
          border: `1px solid ${theme.colors.mediumGray}`,
        };
      }}
    >
      <CardHeader
        title="Latest AML Ranking Change"
        slotProps={{
          title: {
            fontSize: "16px",
            fontWeight: "600",
          },
        }}
        sx={(theme) => {
          return {
            borderBottom: `1px solid ${theme.colors.mediumGray}`,
          };
        }}
      />
      <CardContent sx={{ padding: "0px" }}>
        <Grid container direction="column">
          <Grid container padding="16px 24px 8px 24px" justifyContent="space-between">
            <Grid item width="170px">
              <Label>ENTITY</Label>
            </Grid>
            <Grid item width="100px">
              <Label>AML SCORE</Label>
            </Grid>
            <Grid item width="260px">
              <Label>AML SCORE CHANGE</Label>
            </Grid>
          </Grid>

          {rows.map((row, index) => (
            <Grid
              container
              key={index}
              padding="16px 24px 8px 24px"
              height="48px"
              justifyContent="space-between"
              onClick={() => onEntitySelected(row.name)}
              sx={(theme) => {
                return {
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: theme.colors.lightGray,
                  },
                };
              }}
            >
              <Grid item width="170px">
                <EntityName name={row.name} avatarVariant="small" />
              </Grid>
              <Grid item width="100px">
                <RisktrailScore
                  score={row.riskScore!}
                  value={row.riskScore!.toFixed(2)}
                  entityType={row.type} // changed from tag
                  variant="compact"
                />
              </Grid>
              <Grid container item width="260px" columnSpacing={1}>
                <Grid item>{getScoreChange(row.riskScore!, row.previousScore!)}</Grid>
                {row.lastScoreChangeReason && (
                  <Grid item fontSize="12px">
                    {row.lastScoreChangeReason}
                  </Grid>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AmlRanksChange;
