import { Columns, Grid } from "ui";
import { TokenTags } from "../TokensTags";
import { formatFiatValue } from "utils";
import { NationFlag, EventTag } from "components";

const getColumns = (): Columns => [
  {
    field: "Entity",
    headerName: "Entity",
    width: 200,
    renderCell: (params) => <b>{params.value}</b>,
  },
  {
    field: "Type",
    headerName: "Type",
    width: 180,
  },
  {
    field: "Start Date",
    headerName: "Start Date",
    width: 150,
  },
  {
    field: "End Date",
    headerName: "End Date",
    width: 150,
  },
  {
    field: "Est. Amount",
    headerName: "Est. Amount",
    width: 150,
    valueFormatter: (value) => (value ? `$${formatFiatValue(value as number)}` : ""),
  },
  {
    field: "Jurisdiction",
    headerName: "Jurisdiction",
    width: 250,
    renderCell: (params) => {
      return (
        <Grid item container flexWrap="nowrap" columnSpacing={1}>
          <Grid item>
            <NationFlag countryName={params.value} />
          </Grid>
          <Grid item>{params.value}</Grid>
        </Grid>
      );
    },
  },

  {
    field: "Tokens",
    headerName: "Tokens",
    flex: 1,
    renderCell: (params) => {
      return <TokenTags tokens={params.value} />;
    },
  },
];

export default getColumns;
