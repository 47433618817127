import React, { useState } from "react";
import { Grid, StarIcon, TextButton } from "ui";
import * as S from "./styled";

type Props = {
  path: string;
  label: string | React.ReactNode;
  icon?: React.ReactNode;
  isAddressInPortfolio: boolean;
  onAddToPortfolio: () => void;
  onRemoveFromPortfolio: () => void;
};

export const MenuItem = ({ label, onAddToPortfolio, onRemoveFromPortfolio, isAddressInPortfolio }: Props) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <S.PortfolioMenuWrapper
      container
      justifyContent="space-between"
      padding={1}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      alignItems="center"
      onClick={isAddressInPortfolio ? onRemoveFromPortfolio : onAddToPortfolio}
    >
      <Grid overflow="hidden" textOverflow={"ellipsis"} maxWidth="150px" item>
        {label}
      </Grid>
      <Grid item>
        {isAddressInPortfolio ? (
          isHovered ? (
            <TextButton
              text="Remove"
              onClick={(e) => {
                onRemoveFromPortfolio();
                e.stopPropagation();
              }}
              variant="secondary"
            />
          ) : (
            <StarIcon color="primary" fontSize="small" />
          )
        ) : (
          <TextButton
            text="+ Add"
            onClick={(e) => {
              onAddToPortfolio();
              e.stopPropagation();
            }}
          />
        )}
      </Grid>
    </S.PortfolioMenuWrapper>
  );
};

export default MenuItem;
