import { LabelValue, NationFlag } from "components";
import { EntityLicense } from "types";
import { Grid } from "ui";
import { Link as MUILink } from "@mui/material";
import LicenceTag from "./LicenceTag";
import * as S from "./styled";

type Props = {
  licence: EntityLicense;
  print: boolean;
};

const License = ({ licence, print }: Props) => (
  <S.Licence container item print={print} padding={2}>
    <Grid container item justifyContent="space-between" flexWrap="nowrap">
      <Grid item xs={5}>
        <LabelValue label="Entity" value={licence.entity} valueBold={true} width={200} labelLineHeight="12px" />
      </Grid>
      <LicenceTag licence={licence.licence} />
    </Grid>
    <Grid item>
      <LabelValue
        label="Jurisdiction"
        labelLineHeight="12px"
        value={
          licence.jurisdiction ? (
            <Grid item container flexWrap="nowrap" columnSpacing={1}>
              <Grid item>
                <NationFlag countryName={licence.jurisdiction} />
              </Grid>
              <Grid item>{licence.jurisdiction}</Grid>
            </Grid>
          ) : (
            <></>
          )
        }
      />
    </Grid>

    <Grid item container justifyContent="space-between" flexWrap="nowrap">
      <Grid item>
        <LabelValue
          label="Regulator"
          value={licence.regulator_body}
          print={print}
          justifyContent="flex-end"
          labelLineHeight="12px"
          width={200}
        />
      </Grid>
      {licence.source && (
        <Grid container item justifyContent="flex-end" alignItems="flex-end">
          <MUILink href={licence.source} target="_blank" rel="noreferrer">
            <Grid
              container
              item
              sx={(theme) => ({ color: theme.colors.primary })}
              justifyContent="flex-end"
              alignItems="center"
              flexWrap="nowrap"
            >
              Source
              <S.ArrowOutIcon />
            </Grid>
          </MUILink>
        </Grid>
      )}
    </Grid>
  </S.Licence>
);

export default License;
