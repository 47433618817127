import { isAfter, parseISO, subDays, format } from "date-fns";
import { TZDate } from "@date-fns/tz";

export const utcToLondonTime = (dateString: string) => {
  const pattern = "dd/MM/yyyy HH:mm";
  const iso = parseISO(dateString);

  // Create a TZDate directly with London timezone
  const londonTime = new TZDate(iso, "Europe/London");

  return format(londonTime, pattern);
};

export const formatDate = (dateString: string, format: "short" | "long" = "short") => {
  if (!dateString) {
    return "";
  }

  const date = new Date(dateString);

  if (format === "short") {
    return date.toLocaleDateString("en-gb").replaceAll("/", "-");
  }

  return date.toLocaleDateString("en-GB", { day: "numeric", month: "long", year: "numeric" });
};

export const formatUTCDateWithTimezone = (dateStr: string, timeZone = "UTC") => {
  const pattern = "dd/MM/yyyy HH:mm";
  const date = parseISO(dateStr);

  // Use the TZDate with the specified timezone
  const zonedDate = new TZDate(date, timeZone);

  return format(zonedDate, pattern);
};
// Check if a date is within the specified period
export const isDateWithingPeriod = (dateString: string, days: number) => {
  if (!dateString) return false;
  const targetDate = parseISO(dateString);
  const referenceDate = subDays(new Date(), days);

  return isAfter(targetDate, referenceDate);
};

export const formatDateToMonthYear = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toLocaleString("en-US", { month: "long", year: "numeric" });
};
