import { Link } from "react-router-dom";
import { Link as MUILink, useTheme } from "@mui/material";
import { Tooltip, Clipboard } from "ui";
import * as S from "./styled";

type Props = {
  link: string;
  address: string;
  variant?: "short" | "long" | "compact";
  labels?: string;
};

export const Address = ({ link, address, variant = "short" }: Props) => (
  <S.Address>
    <Tooltip title={address}>
      <S.AddressText>
        <MUILink component={Link} to={link}>
          {variant === "short"
            ? address.slice(0, 5) + "..."
            : variant === "compact"
            ? address.substring(0, length + 5) + "..." + address.substring(address.length - 5)
            : address}
        </MUILink>
      </S.AddressText>
    </Tooltip>
    <Clipboard text={address} hideText={true} />
  </S.Address>
);

export default Address;
