import Reserves from "./Reserves";
import { Grid } from "ui";
import type { EntitySolvency, EntityReserves } from "types";
import Solvency from "./Solvency";
import { useMediaQuery, useTheme } from "@mui/material";

type Props = {
  solvency: EntitySolvency | null;
  reserves: EntityReserves | null;
  entityName: string;
  print: boolean;
};

export const SolvencyDisclosure = ({ solvency, reserves, entityName, print }: Props) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid container item columnSpacing={10} rowSpacing={5} sm={print ? 8 : 12}>
      {solvency && (
        <Grid container item md={6} sm={12}>
          <Solvency solvency={solvency} />
        </Grid>
      )}
      <Grid container item md={6} sm={12}>
        {reserves && (
          <Reserves isSmallScreen={isSmallScreen} reserves={reserves} entityName={entityName} print={print} />
        )}
      </Grid>
    </Grid>
  );
};

export default SolvencyDisclosure;
