import { Grid } from "ui";
import ReportActions from "pages/Report/ReportHeader/ReportTitle/Actions/ReportActions";
import PortfolioActions from "pages/Report/ReportHeader/ReportTitle/Actions/PortfolioActions";
import { NotificationsPopover } from "pages/Report/ReportHeader/NotificationsPopover";

type Props = {
  address: string;
  isInPortfolio: boolean;
};

export const Actions = ({ address, isInPortfolio }: Props) => (
  <Grid item container flexWrap="nowrap" xs="auto" columnGap={1} alignItems="center">
    <NotificationsPopover address={address} />
    <ReportActions address={address} />
    <PortfolioActions address={address} isInPortfolio={isInPortfolio} />
  </Grid>
);

export default Actions;
