import React, { useEffect } from "react";
import { PopUpDialog, Input, Button, Grid, DatePicker } from "ui";
import { ApiKey } from "./Api";

import { useForm, Controller } from "react-hook-form";

type Props = {
  open: boolean;
  onClose: () => void;
  onSave: (newKey: Partial<ApiKey>) => void;
  currentKey: ApiKey | null;
};

type FormData = {
  name: string;
  expiresAt: Date | null;
};

const ApiKeyModal: React.FC<Props> = ({ open, onClose, onSave, currentKey }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      name: currentKey?.name || "",
      expiresAt: currentKey?.expiresAt ? new Date(currentKey.expiresAt) : null,
    },
  });

  useEffect(() => {
    if (open) {
      reset({
        name: currentKey?.name || "",
        expiresAt: currentKey?.expiresAt ? new Date(currentKey.expiresAt) : null,
      });
    }
  }, [currentKey, open, reset]);

  const onSubmit = (data: FormData) => {
    const newKey: Partial<ApiKey> = {
      name: data.name.trim(),
      expiresAt: data.expiresAt ? data.expiresAt.toISOString() : null,
    };
    onSave(newKey);
    onClose();
  };

  return (
    <PopUpDialog title={"Create New API Key"} onCancel={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} direction="column" marginTop="8px">
          <Grid item>
            <Controller
              name="name"
              control={control}
              rules={{
                required: "Name is required",
                maxLength: { value: 50, message: "Name must be 50 characters or less" },
                validate: (value) => value.trim().length > 0 || "Name cannot be empty or only whitespace",
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  label="Name"
                  placeholder="Enter API key name"
                  required
                  InputProps={{
                    inputProps: {
                      maxLength: 30,
                    },
                  }}
                  error={!!errors.name}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              name="expiresAt"
              control={control}
              render={({ field }) => (
                <DatePicker
                  label="Expiration Date"
                  disablePast
                  value={field.value}
                  onChange={(newValue) => field.onChange(newValue)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      margin: "normal",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid container item justifyContent="flex-end" columnSpacing={1}>
            <Grid item>
              <Button type="submit">{"Create"}</Button>
            </Grid>
            <Grid item>
              <Button buttonTheme="secondary" onClick={onClose}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </PopUpDialog>
  );
};

export default ApiKeyModal;
