import { IconButton } from "@mui/material";
import { GridRowParams, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import React from "react";
import { useTheme } from "styled-components";
import { DropDown } from "ui/Icons";
import * as S from "./styled";

const ExpandableRow = React.memo(
  ({
    params,
    columns,
    rowHeight,
    renderRowDetails,
    expandedRowIds = [],
    onRowExpandToggle,
  }: {
    params: Partial<GridRowParams> & { row: any; id?: string | number };
    columns: GridColDef[];
    rowHeight: number;
    renderRowDetails?: (params: any) => React.ReactNode;
    expandedRowIds?: (string | number)[];
    onRowExpandToggle?: (rowId: string | number) => void;
  }) => {
    const apiRef = React.useRef<any>(null);
    const theme = useTheme();

    const isExpanded = expandedRowIds.includes(params.row.id);

    const toggleExpansion = (e: React.MouseEvent) => {
      e.stopPropagation();
      onRowExpandToggle && onRowExpandToggle(params.row.id);
    };

    const renderCellContent = (params: GridRenderCellParams, column: GridColDef) => {
      const value = params.value;

      if (value === null || value === "") {
        return <span style={{ textAlign: "center", fontSize: "20px" }}>-</span>;
      }

      if (column.renderCell) {
        return column.renderCell(params);
      }

      if (column.valueFormatter) {
        return column.valueFormatter(value as never, params.row, column, apiRef);
      }

      if (column.valueGetter) {
        const getValue = column.valueGetter as (params: GridRenderCellParams) => any;
        return getValue(params);
      }
      return value;
    };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          borderBottom: `1px solid ${theme.colors.mediumGray}`,
          height: "max-content",
          transition: "height 0.2s ease",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={toggleExpansion} size="small" style={{ paddingLeft: "12px" }}>
            <DropDown
              style={{
                transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.2s ease",
              }}
            />
          </IconButton>
          {columns.map((column) => (
            <S.ExpandableRow
              key={column.field}
              $columnWidth={column.width}
              $columnAlign={column.align}
              $columnFlex={column.flex}
              maxWidth={column.maxWidth}
              minWidth={column.minWidth}
              $rowHeight={rowHeight}
            >
              {renderCellContent(
                {
                  ...params,
                  field: column.field,
                  value: params.row[column.field],
                  id: params.row.id,
                } as any,
                column,
              )}
            </S.ExpandableRow>
          ))}
        </div>

        {isExpanded && renderRowDetails && (
          <div
            style={{
              padding: "24px 63px",
              opacity: isExpanded ? 1 : 0,
              transition: "opacity 0.3s ease",
            }}
          >
            {renderRowDetails(params)}
          </div>
        )}
      </div>
    );
  },
);

ExpandableRow.displayName = "ExpandableRow";

export default ExpandableRow;
