import { Card, CardContent, CardHeader } from "@mui/material";
import { EntityName } from "components";
import { compareAsc, parseISO } from "date-fns";
import React from "react";
import { Profile } from "types";
import { Grid, Label, RisktrailScore } from "ui";
import { formatDate } from "utils";

type Props = {
  profiles: Profile[];
  onEntitySelected: (entityName: string) => void;
};

export const NewEntities: React.FC<Props> = ({ profiles, onEntitySelected }: Props) => {
  const rows = profiles
    .sort((a, b) => {
      const dateA = a.createdAt ? parseISO(a.createdAt) : new Date(0);
      const dateB = b.createdAt ? parseISO(b.createdAt) : new Date(0);
      return compareAsc(dateB, dateA);
    })
    .slice(0, 5);

  return (
    <Card
      sx={(theme) => {
        return {
          border: `1px solid ${theme.colors.mediumGray}`,
        };
      }}
    >
      <CardHeader
        title="Newly Added"
        slotProps={{
          title: {
            fontSize: "16px",
            fontWeight: "600",
          },
        }}
        sx={(theme) => {
          return {
            borderBottom: `1px solid ${theme.colors.mediumGray}`,
          };
        }}
      />
      <CardContent sx={{ padding: "0px" }}>
        <Grid container direction="column">
          <Grid container padding="16px 24px 8px 24px" justifyContent="space-between">
            <Grid item width="170px">
              <Label>ENTITY</Label>
            </Grid>
            <Grid item width="100px">
              <Label>AML SCORE</Label>
            </Grid>
            <Grid item width="130px">
              <Label>TYPE</Label>
            </Grid>
            <Grid item width="90px">
              <Label>ADDED</Label>
            </Grid>
          </Grid>

          {rows.map((row, index) => (
            <Grid
              container
              key={index}
              padding="16px 24px 8px 24px"
              height="48px"
              justifyContent="space-between"
              onClick={() => onEntitySelected(row.name)}
              sx={(theme) => {
                return {
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: theme.colors.lightGray,
                  },
                };
              }}
            >
              <Grid item width="170px">
                <EntityName name={row.name} avatarVariant="small" />
              </Grid>
              <Grid item width="100px">
                {row.riskScore !== null && (
                  <RisktrailScore
                    score={row.riskScore}
                    value={row.riskScore.toFixed(2)}
                    entityType={row.type}
                    variant="compact"
                  />
                )}
              </Grid>
              <Grid item width="130px" sx={{ fontSize: "14px" }}>
                {row.type}
              </Grid>
              <Grid item width="90px" sx={{ fontSize: "14px" }}>
                {formatDate(row.createdAt)}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default NewEntities;
