import { EntityTypeRisk } from "types";
import * as S from "./styled";
import { AlertCircle, CheckCircleIcon, Grid, ReportGmailerrorred } from "ui";

type Props = {
  riskLevel: EntityTypeRisk;
};

const getRiskConfig = (level: EntityTypeRisk) => {
  const configs = {
    LOW: {
      text: "No Risk",
      icon: CheckCircleIcon,
    },
    MEDIUM: {
      text: "Medium Risk",
      icon: ReportGmailerrorred,
    },
    HIGH: {
      text: "High Risk",
      icon: AlertCircle,
    },
    VERY_HIGH: {
      text: "Very High Risk",
      icon: AlertCircle,
    },
  };

  return configs[level];
};

const RiskIndicator = ({ riskLevel }: Props) => {
  const config = getRiskConfig(riskLevel);
  const Icon = config.icon;

  return (
    <Grid container justifyItems="center">
      <S.RiskPill $riskLevel={riskLevel}>
        <S.RiskText>{config.text}</S.RiskText>
        <Icon />
      </S.RiskPill>
    </Grid>
  );
};

export default RiskIndicator;
