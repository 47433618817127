import React from "react";
import { Grid } from "ui";
import { useTheme } from "@mui/material";
import EntityAvatar from "./EntityAvatar";
import { AvatarVariantType } from "./types";
import * as S from "./styled";

type Props = {
  name: string;
  avatarVariant?: AvatarVariantType;
  color?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  linkProfile?: boolean;
};

const EntityName: React.FC<Props> = ({ name, onClick, linkProfile, avatarVariant = "normal", color, style }) => {
  const theme = useTheme();
  const displayName = name || "Unknown";
  const textColor = color || theme.colors.black;

  // Common content that appears in both versions
  const AvatarWithName = () => (
    <Grid container columnGap={1} alignItems="center">
      {avatarVariant !== "none" && name !== "Unknown" && (
        <Grid item>
          <EntityAvatar name={name} variant={avatarVariant} />
        </Grid>
      )}

      <Grid>
        <strong>{displayName}</strong>
      </Grid>
    </Grid>
  );

  return (
    <Grid item>
      {linkProfile ? (
        <S.MuiLink onClick={onClick} color={textColor} style={style}>
          <AvatarWithName />
        </S.MuiLink>
      ) : (
        <AvatarWithName />
      )}
    </Grid>
  );
};

export default EntityName;
