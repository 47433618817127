import { Avatar as AvatarUI } from "ui";
import { AvatarProps } from "ui/Avatar/Avatar";

type Props = {
  name: string;
  variant: AvatarProps["size"];
};

export const EntityAvatar = ({ name, variant }: Props) => {
  return <AvatarUI src={`/images/entities/${name}.png`} size={variant} shape="circle" />;
};

export default EntityAvatar;
