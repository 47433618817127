import { Button, Grid } from "ui";
import { useForm } from "react-hook-form";
import * as S from "../Cluster/styled";
import AppContext from "contexts/AppContext";
import { useContext, useEffect, useState } from "react";
import { LabelsEditor } from "./LabelsEditor";
import { EntityField } from "components";
import { Event } from "types";
import axios from "axios";
import { useErrorHandler } from "hooks";
type Props = {
  metadata?: MetaData;
  onCancel: () => void;
  saveData: (data: MetaData) => void;
};

export type MetaData = {
  ownerID?: number;
  ownerTag: string;
  ownerType: string;
  ownerSubType: string;
  controllerID?: number;
  controllerTag: string;
  controllerType: string;
  controllerSubType: string;
  labels: string[];
  controllerEvents: Event[];
  ownerEvents: Event[];
};

export const EditAddressForm = ({ saveData, metadata, onCancel }: Props) => {
  const [addressLabels, setAddressLabels] = useState<string[]>([]);
  const handleError = useErrorHandler();
  const { handleSubmit, getValues, setValue, setError, formState, watch, clearErrors } = useForm<MetaData>({
    defaultValues: {
      ownerTag: "",
      controllerTag: "",
      labels: [],
      ...metadata,
    },
  });
  const field1 = watch("ownerTag");
  const field2 = watch("controllerTag");

  const isFormValid = () => {
    return field1 !== "" || field2 !== "";
  };

  useEffect(() => {
    const fetchLabels = async () => {
      try {
        const response = await axios.get("/api/address/labels");
        const labels = response.data;
        setAddressLabels(labels);
      } catch (error) {
        handleError("Something went wrong when fetching address labels.");
      }
    };
    fetchLabels();
  }, []);

  const validate = () => {
    const isValid = isFormValid();
    clearErrors();
    if (!isValid) {
      setError("ownerTag", {
        message: "There has to be owner or controller assigned",
      });
      return false;
    }

    return true;
  };

  const submit = () => {
    validate() && saveData(getValues());
  };

  return (
    <S.Form onSubmit={handleSubmit(submit, validate)}>
      <Grid container columnSpacing={3} direction="column" xs={12} rowSpacing={2}>
        <Grid item container direction="column" xs={5}>
          {Object.keys(formState.errors).map((key) => (
            <Grid item key={key}>
              {formState.errors[key as keyof MetaData]?.message}
            </Grid>
          ))}

          <Grid item>
            <EntityField
              value={metadata?.ownerTag || ""}
              label="Owner Tag"
              name="ownerTag"
              onEntitySelected={(value) => {
                setValue("ownerTag", value);
              }}
            />
          </Grid>

          <Grid item paddingTop={2}>
            <EntityField
              value={metadata?.controllerTag || ""}
              label="Controller Tag"
              name="controllerTag"
              onEntitySelected={(value) => {
                setValue("controllerTag", value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={2}>
          <LabelsEditor
            options={addressLabels.map((label) => label)}
            onChange={(value) => setValue("labels", value)}
            defaultValue={getValues().labels}
          />
        </Grid>
        <Grid container item spacing={2} flexWrap="nowrap">
          <Grid item>
            <Button type="button" size="medium" buttonTheme="cancel" onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button type="submit" size="medium">
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </S.Form>
  );
};

export default EditAddressForm;
