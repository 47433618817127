const Warning = ({ fill = "white" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M9.99565 13.5096C10.1615 13.5096 10.302 13.4535 10.4171 13.3412C10.5322 13.2292 10.5898 13.0901 10.5898 12.9242C10.5898 12.7583 10.5337 12.6178 10.4215 12.5027C10.3093 12.3877 10.1702 12.3302 10.0044 12.3302C9.83856 12.3302 9.69808 12.3862 9.58294 12.4983C9.4678 12.6105 9.41023 12.7496 9.41023 12.9154C9.41023 13.0812 9.46634 13.2217 9.57856 13.3369C9.69078 13.452 9.82981 13.5096 9.99565 13.5096ZM9.99585 11.1923C10.1482 11.1923 10.2772 11.1404 10.3829 11.0367C10.4888 10.9328 10.5417 10.8041 10.5417 10.6506V6.70186C10.5417 6.54839 10.4902 6.41978 10.3871 6.31603C10.284 6.21214 10.1564 6.1602 10.0042 6.1602C9.85183 6.1602 9.7228 6.21214 9.6171 6.31603C9.51127 6.41978 9.45835 6.54839 9.45835 6.70186V10.6506C9.45835 10.8041 9.50988 10.9328 9.61294 11.0367C9.71599 11.1404 9.84363 11.1923 9.99585 11.1923ZM7.84148 16.5833C7.66287 16.5833 7.49266 16.5512 7.33085 16.4871C7.16905 16.423 7.01981 16.3226 6.88315 16.1858L3.81419 13.096C3.69071 12.9708 3.59363 12.8236 3.52294 12.6546C3.4521 12.4855 3.41669 12.3133 3.41669 12.1377V7.84145C3.41669 7.66284 3.44877 7.49263 3.51294 7.33082C3.57696 7.16902 3.67738 7.01978 3.81419 6.88312L6.88315 3.81416C7.01981 3.67735 7.16905 3.57693 7.33085 3.51291C7.49266 3.44874 7.66287 3.41666 7.84148 3.41666H12.1586C12.3372 3.41666 12.5074 3.44874 12.6692 3.51291C12.831 3.57693 12.9802 3.67735 13.1169 3.81416L16.1859 6.88312C16.3227 7.01978 16.4231 7.16902 16.4871 7.33082C16.5513 7.49263 16.5834 7.66284 16.5834 7.84145V12.1585C16.5834 12.3371 16.5513 12.5074 16.4871 12.6692C16.4231 12.831 16.3227 12.9802 16.1859 13.1169L13.0961 16.1858C12.9708 16.3093 12.8236 16.4064 12.6546 16.4771C12.4856 16.5479 12.3133 16.5833 12.1377 16.5833H7.84148ZM7.72919 15.5H12.2709L15.5 12.2708V7.72916L12.25 4.49999H7.72919L4.50002 7.72916V12.2708L7.72919 15.5Z"
      fill={fill}
    />
  </svg>
);

export default Warning;
