import { Profile } from "types";
import { SealIcon, FileDownloadIcon, Grid, Button, Tag, Title, IconButton, Label } from "ui";
import NotificationsPopover from "./NotificationsPopover";
import { EntityAvatar } from "components";

type Props = {
  entity: Profile;
  isSmallScreen: boolean;
  handlePrint?: () => void;
};

export const BasicInfo = ({ entity, handlePrint, isSmallScreen }: Props) => {
  return (
    <Grid container item justifyContent="space-between">
      <Grid container item columnSpacing={3} flexWrap="nowrap">
        <Grid container item width="fit-content">
          <EntityAvatar variant="large" name={entity.name} />
        </Grid>
        <Grid container item sm>
          <Grid container columnSpacing={2} justifyContent="space-between" flexWrap="nowrap">
            <Grid container item alignItems="center" columnSpacing={1} flexWrap="nowrap">
              <Grid item>
                <Title>{entity.name}</Title>
              </Grid>
              <Grid item>
                <Tag
                  label={entity.status}
                  color={entity.status === "Active" ? "green" : "darkGray"}
                  size="medium"
                  backgroundColor={entity.status === "Active" ? "lightGreen" : "lightGray"}
                />
              </Grid>
              {entity.verified && (
                <Grid item>
                  <Tag
                    icon={<SealIcon />}
                    label={"Verified"}
                    color="primary"
                    size="medium"
                    backgroundColor={"lightPrimary"}
                  />
                </Grid>
              )}
            </Grid>
            <Grid
              container
              item
              justifyContent={isSmallScreen ? "initial" : "flex-end"}
              columnSpacing={1}
              alignItems={"center"}
            >
              <Grid item>
                <NotificationsPopover entityID={entity.id} />
              </Grid>
              {isSmallScreen && handlePrint && (
                <Grid item>
                  <IconButton onClick={handlePrint}>
                    <FileDownloadIcon />
                  </IconButton>
                </Grid>
              )}

              {!isSmallScreen && handlePrint && (
                <Grid item>
                  <Button onClick={handlePrint} icon={<FileDownloadIcon />} color="secondary" variant="outlined">
                    Download Report
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item>
            <Label>
              {entity.type}
              {entity.subtype ? ` - ${entity.subtype}` : ""}
            </Label>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BasicInfo;
