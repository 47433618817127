import { Button, Grid, HistoryIcon, Label, NotificationIcon, Title } from "ui";

type Props = {
  title: string;
  userEmail: string;
  notificationCount: number;
  onHistoryClick: () => void;
  hasAccess: boolean;
};

export const NotificationTitle = ({ title, userEmail, notificationCount, onHistoryClick, hasAccess }: Props) => (
  <Grid item container direction="column" rowSpacing={2}>
    <Grid container item columnSpacing={2} flexWrap="nowrap">
      <Grid item>
        <NotificationIcon />
      </Grid>
      <Grid container item justifyContent="space-between" direction={{ xs: "row", md: "column" }} rowSpacing={1}>
        <Grid item xs={12}>
          <Title variant="small">
            <span style={{ fontWeight: "normal" }}>Alert when </span>
            {title}
          </Title>
          {hasAccess && (
            <Label whiteSpace="normal">
              Email notification will be sent to <strong>{userEmail}</strong>
            </Label>
          )}
        </Grid>
        <Grid item xs={12}>
          {hasAccess && (
            <Button
              style={{ float: "right" }}
              variant="outlined"
              icon={<HistoryIcon />}
              color="secondary"
              onClick={onHistoryClick}
            >
              Show History
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
    <Grid item>
      <Label>{notificationCount > 0 ? "ACTIVE ALERTS" : "No active alerts"}</Label>
    </Grid>
  </Grid>
);

export default NotificationTitle;
