import { Grid, Tab } from "@mui/material";
import React from "react";

import * as S from "../styled";

type Props = {
  tabIndex: number;
  handleTokenTabChange: (event: React.SyntheticEvent, newValue: number) => void;
};

export const EthTabs = ({ tabIndex, handleTokenTabChange }: Props) => (
  <Grid container item direction="column">
    <S.Tabs
      sx={(theme) => {
        return {
          color: "black",
          borderBottom: `2px solid ${theme.colors.mediumGray}`,
          "& .MuiTabs-flexContainer": {
            flexWrap: "wrap",
          },
        };
      }}
      value={tabIndex}
      onChange={handleTokenTabChange}
    >
      <Tab label="Native (ETH)" />
      <Tab label="Tokens" />
      <Tab label="NFTs" />
    </S.Tabs>
  </Grid>
);

export default EthTabs;
