import { Grid, RisktrailScore } from "ui";
import { Checkmark, NationFlag, Tags, EntityName, ScoreTrend } from "components";
import { isDateWithingPeriod } from "utils";
import type { Columns } from "ui";

const getColumns = (display: "All" | "Category"): Columns => {
  return [
    {
      field: "rank",
      headerName: display === "All" ? "Rank" : "Rank",
      maxWidth: 80,
      display: "flex",
      headerClassName: "rankHeader",
      renderCell: ({ row }) => {
        const rank = display === "All" ? row.globalRank : row.typeRank;
        return <Grid padding={1}>{rank}</Grid>;
      },
    },
    {
      field: "name",
      headerName: "Entity",
      minWidth: 300,
      flex: 1,
      display: "flex",
      renderCell: ({ value, row }) => {
        const wasEntityAddedRecently = isDateWithingPeriod(row.createdAt, 28);
        const wasEntityAssignedNewScoreRecently =
          row.previousScore === null && row.riskScore && isDateWithingPeriod(row.lastScoreUpdatedAt, 28);

        const isNew = wasEntityAddedRecently || wasEntityAssignedNewScoreRecently;

        return (
          <Grid
            container
            item
            overflow="hidden"
            textOverflow="ellipsis"
            flexWrap="nowrap"
            columnSpacing={2}
            alignItems={"center"}
          >
            <EntityName name={value} avatarVariant="small" />
            {isNew && (
              <Grid item>
                <Tags
                  short={false}
                  tags={[
                    {
                      type: "New",
                    },
                  ]}
                />
              </Grid>
            )}
            <ScoreTrend
              riskScore={row.riskScore}
              previousScore={row.previousScore}
              lastScoreUpdatedAt={row.lastScoreUpdatedAt}
            />
          </Grid>
        );
      },
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 160,
    },
    {
      field: "riskScore",
      type: "number",
      headerName: "AML Score",
      display: "flex",

      align: "center",
      minWidth: 120,
      headerAlign: "center",
      renderCell: ({ value, row }) =>
        value && (
          <Grid container>
            <RisktrailScore
              score={value}
              value={value.toFixed(2)}
              entityType={row.type}
              variant="compact"
              justifyContent="center"
            />
          </Grid>
        ),
    },
    {
      field: "solvencyScore",
      headerName: "Solvency Transparency",
      align: "center",
      headerAlign: "center",
      minWidth: 230,
      display: "flex",
      renderCell: ({ value, row }) =>
        value !== null && (
          <Grid container>
            <RisktrailScore
              score={value}
              value={value + "%"}
              entityType={row.type}
              variant="compact"
              justifyContent="center"
              ignoreScoreColor={value !== 0}
            />
          </Grid>
        ),
    },
    {
      field: "jurisdiction",
      headerName: "Jurisdiction",
      minWidth: 140,
      renderCell: (params) => {
        return (
          <Grid item container flexWrap="nowrap" columnSpacing={1}>
            <Grid item>
              <NationFlag countryName={params.value} />
            </Grid>
            <Grid item>{params.value}</Grid>
          </Grid>
        );
      },
    },

    {
      field: "penaltyPoints",
      align: "center",
      headerName: "Penalty",
      display: "flex",
      minWidth: 100,
      renderCell: ({ value }) => <Checkmark value={value || false} icon={value ? "important" : "none"} />,
    },
    {
      field: "amlPolicy",
      align: "center",
      headerName: "AML",
      display: "flex",
      width: 70,
      renderCell: ({ value }) => (
        <Checkmark value={value} icon={["Partial", "Unclear"].includes(value) ? "partial" : "checkmark"} />
      ),
    },
    {
      field: "KYC",
      align: "center",
      headerName: "KYC",
      display: "flex",
      minWidth: 70,
      maxWidth: 70,
      renderCell: ({ value }) => <Checkmark value={value || false} />,
    },
    {
      field: "licensed",
      align: "center",
      display: "flex",
      headerName: "Regulated",
      minWidth: 120,
      renderCell: ({ value }) => <Checkmark value={value || false} />,
    },
    // {
    //   field: "privacyCoins",
    //   align: "center",
    //   headerName: "Privacy Coins",
    //   minWidth: 150,
    //   renderCell: ({ value }) => (
    //     <Checkmark value={value || false} icon="important" />
    //   ),
    // },
  ];
};

export default getColumns;
