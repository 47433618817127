import axios from "axios";
import { useEffect, useState } from "react";
import { EntityName, Metadata, PaymentBanner, Summary } from "components";
import { HackProfile } from "types/Events";
import { TokenTags } from "../TokensTags";
import { Divider, Grid, Label, Link, Loading, Title } from "ui";
import EventBadge from "components/EventBadge";
import { useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { useModal } from "contexts/ModalContext";
import { useErrorHandler } from "hooks";
import { useAccessControl } from "hooks/useAccessControl";
import Jurisdictions from "components/Jurisdictions";

const HackDetails = ({ hackId }: { hackId?: string | number }) => {
  const handleError = useErrorHandler();
  const hasAccess = useAccessControl("profiles");

  const { setIsLoading, isLoading } = useModal();
  const theme = useTheme();
  const [details, setDetails] = useState<HackProfile>();

  const params = useParams();

  const hackIDToLoad = hackId || params.hackId;

  useEffect(() => {
    const fetchHackDetails = async () => {
      setIsLoading(true);

      try {
        const { data } = await axios.get(`/api/entities/hacks/${hackIDToLoad}`);
        setDetails(data);
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (hackIDToLoad) {
      fetchHackDetails();
    }

    return () => {
      setIsLoading(false);
    };
  }, [hackIDToLoad]);

  if (isLoading || !details) {
    return <Loading />;
  }

  return (
    <Grid container direction="column" rowSpacing={3} paddingBottom={8}>
      <PaymentBanner
        hasAccess={hasAccess}
        title="Unlock detailed event reports"
        description="Upgrade your plan to gain access to detailed reports on scams, sanctions and hacks."
      />
      <Grid container item rowSpacing={2} direction="column">
        <Grid container item justifyContent={"space-between"}>
          <EventBadge type="Hack" />
        </Grid>
        <Grid item container>
          <Title>{details.name}</Title>
          <Label>
            {details &&
              new Date(details.date).toLocaleString("en-gb", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}
          </Label>
        </Grid>
      </Grid>
      <Grid item container>
        <Metadata
          spacing={3}
          columns={4}
          list={[
            {
              title: "Target",
              value: <EntityName name={details?.target} avatarVariant="small" />,
            },
            {
              title: "Est. Amount Stolen",
              value: details?.estimatedAmountStolen
                ? `$ ${Number(details?.estimatedAmountStolen).toLocaleString("en-gb", {
                    maximumFractionDigits: 2,
                  })}`
                : "-",
            },
            {
              title: "Jurisdiction",
              value: <Jurisdictions jurisdictions={details?.jurisdiction} />,
            },
            {
              title: "Perpetrator(s)",
              value: details?.entities.join(", "),
            },
          ]}
        />
      </Grid>
      <Grid container item marginBottom={3}>
        <Grid container direction="column">
          <Grid>
            <Label>Tokens</Label>
          </Grid>
          <Grid container>
            <TokenTags tokens={details?.tokens} />
          </Grid>
        </Grid>
      </Grid>

      <Divider />
      <Summary text={details?.synopsis ?? ""} showAll={true} />
      {details?.link && (
        <Link href={details?.link} target="_blank">
          View Source
        </Link>
      )}
    </Grid>
  );
};

export default HackDetails;
