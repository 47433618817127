import { useParams } from "react-router-dom";
import { Title, Grid } from "ui";
import Hacks from "./Hacks/Hacks";
import Sanctions from "./Sanctions/Sanctions";
import { Scams } from "./Scams";
import { JSX } from "react";

const eventTable: { [key: string]: JSX.Element } = {
  hacks: <Hacks />,
  sanctions: <Sanctions />,
  scams: <Scams />,
};

export const Events = () => {
  const { event } = useParams();

  return (
    <Grid container direction="column" rowSpacing={3}>
      <Grid item>
        <Title>{event && event?.charAt(0).toUpperCase() + event?.slice(1)}</Title>
      </Grid>
      <Grid item>{event && eventTable[event]}</Grid>
    </Grid>
  );
};
