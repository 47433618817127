import React, { JSX, useContext, useMemo } from "react";
import AppContext from "contexts/AppContext";
import EntityProfile from "../EntityProfile";
import { HackDetail, SanctionDetail } from "pages";
import ProfilePopup from "components/ProfilePopup";
import { EntityOrEventResult, EntityOrEventType, getKnownEntityOrEvent } from "utils/profile";
import { useModal } from "contexts/ModalContext";
import EntityName from "./EntityName";
import { AvatarVariantType } from "./types";
import type { Profile, HackProfile, SanctionProfile } from "types";

type Props = {
  name: string;
  color?: string;
  avatarVariant?: AvatarVariantType;
};

const EntityOverview: React.FC<Props> = ({ name, color, avatarVariant = "average" }) => {
  const { entities, events } = useContext(AppContext);
  const { openModal } = useModal();

  // Retrieve known entity or event based on name
  const knownEntityOrEvent = useMemo<EntityOrEventResult | null>(
    () => getKnownEntityOrEvent(name, events, entities),
    [name, events, entities],
  );

  // Handle opening detailed views for entities or events
  const openDetailView = (item: HackProfile | SanctionProfile | Profile, type: EntityOrEventType) => {
    const detailViewMap: Record<EntityOrEventType, JSX.Element> = {
      sanction: <SanctionDetail sanctionId={item.id} />,
      hack: <HackDetail hackId={item.id} />,
      entity: <EntityProfile entity={item as Profile} />,
    };
    openModal(detailViewMap[type]);
  };

  const content = (
    <EntityName
      name={name}
      color={color}
      avatarVariant={knownEntityOrEvent?.type === "entity" ? avatarVariant : "none"}
      onClick={() => knownEntityOrEvent && openDetailView(knownEntityOrEvent.item, knownEntityOrEvent.type)}
      linkProfile={!!knownEntityOrEvent}
    />
  );

  if (!knownEntityOrEvent) {
    return content;
  }

  return (
    <ProfilePopup
      data={knownEntityOrEvent.item}
      type={knownEntityOrEvent.type === "entity" ? "entity" : "event"}
      content={content}
    />
  );
};

export default EntityOverview;
