import React from "react";

type FlagIconProps = {
  background: string;
  color: string;
  minWidth: string;
};

const FlagIcon: React.FC<FlagIconProps> = ({ background, color, minWidth }) => (
  <svg style={{ minWidth }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill={background} />
    <path
      d="M8.66675 13V17.3333C8.66675 17.475 8.6188 17.5937 8.52291 17.6895C8.42703 17.7854 8.30825 17.8333 8.16658 17.8333C8.0248 17.8333 7.90608 17.7854 7.81041 17.6895C7.71464 17.5937 7.66675 17.475 7.66675 17.3333V7.60267C7.66675 7.43189 7.72453 7.28878 7.84008 7.17333C7.95553 7.05778 8.09864 7 8.26941 7H12.5654C12.7077 7 12.8348 7.04467 12.9466 7.134C13.0582 7.22333 13.1286 7.33933 13.1578 7.482L13.3307 8.33333H16.3974C16.5682 8.33333 16.7113 8.39083 16.8267 8.50583C16.9423 8.62083 17.0001 8.76333 17.0001 8.93333V13.7335C17.0001 13.9035 16.9423 14.0459 16.8267 14.1608C16.7113 14.2758 16.5682 14.3333 16.3974 14.3333H13.4348C13.2924 14.3333 13.1654 14.2887 13.0536 14.1993C12.9419 14.11 12.8715 13.994 12.8424 13.8513L12.6694 13H8.66675Z"
      fill={color}
    />
  </svg>
);

export default FlagIcon;
