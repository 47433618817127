import { IconButton } from "@mui/material";
import React, { useEffect } from "react";

import { CloseIcon, Grid } from "ui";

import * as S from "./styled";

type Props = {
  confirmText?: string;
  children: React.ReactNode[] | React.ReactNode | string;
  onCancel: () => void;
  className?: string;
  title?: string;
};

const PopUpDialog = ({ children, onCancel, className = "", title }: Props) => {
  useEffect(() => {
    const close = (evt: KeyboardEvent) => {
      if (evt.key === "Escape") {
        onCancel();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  return (
    <S.Wrapper>
      <S.Dialog className={className}>
        <Grid container justifyContent="space-between">
          <Grid>
            <S.DialogTitle>{title}</S.DialogTitle>
          </Grid>
          <Grid padding={3}>
            <IconButton onClick={onCancel}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container padding={3}>
          {children}
        </Grid>
      </S.Dialog>
    </S.Wrapper>
  );
};

export default PopUpDialog;
