import { useState } from "react";
import { Portfolio } from "types";
import axios from "axios";
import { Report } from "types";
import * as Ethereum from "types/ethereum";
import { useHandleRestResponse } from "./useHandleRestResponse";

// This is "hooked" service for portfolio data
// If needed, data can be cached here and used in several components - dont forget to clear cache when needed

// TODO: active report is just for adding new report to portfolio - after reports refactor to hooks, this can be removed
let activeReport: Report | Ethereum.Report | null = null;

export const usePortfoliosData = () => {
  const handleRestResponse = useHandleRestResponse();
  const [portfolios, setPortfolios] = useState<Portfolio[]>([]);
  const [arePortfoliosLoaded, setArePortfoliosLoaded] = useState(false);

  // TODO: active report is just for adding new report to portfolio - after reports refactor to hooks, this can be removed
  const cacheActiveReport = (report: Report | Ethereum.Report | null) => {
    activeReport = report;
  };

  const loadPortfolios = async (loadOnlyOnce = false): Promise<Portfolio[]> => {
    if (loadOnlyOnce && arePortfoliosLoaded) return [];
    const response = await handleRestResponse(axios.get<Portfolio[]>("/api/portfolios"));
    if (!response) return [];
    const portfolios = response.data;

    setPortfolios(portfolios);
    setArePortfoliosLoaded(true);
    return portfolios;
  };

  const loadPortfolio = async (portfolioId: number) => {
    const response = await handleRestResponse(axios.get<Portfolio>(`/api/portfolios/${portfolioId}`));
    if (!response) return;

    setPortfolios((prevPortfolios) =>
      prevPortfolios.map((portfolio) => (portfolio.id === portfolioId ? { ...response.data } : portfolio)),
    );

    return response.data;
  };

  const createPortfolio = async (name: string) => {
    const response = await handleRestResponse(
      axios.post<Portfolio>("/api/portfolios", { name }),
      "New portfolio saved successfully.",
    );
    if (!response) return;

    setPortfolios((prevPortfolios) => [...prevPortfolios, response.data]);
    return response.data;
  };

  const updatePortfolioName = async (portfolioId: number, name: string) => {
    const response = await handleRestResponse(
      axios.put<Portfolio>(`/api/portfolios/${portfolioId}`, { name }),
      "Portfolio renamed successfully.",
    );
    if (!response) return;

    setPortfolios((prevPortfolios) =>
      prevPortfolios.map((portfolio) => (portfolio.id === portfolioId ? { ...response.data } : portfolio)),
    );
  };

  const deletePortfolio = async (portfolioId: number) => {
    await handleRestResponse(axios.delete(`/api/portfolios/${portfolioId}`), "Portfolio deleted successfully.");

    setPortfolios((prevPortfolios) => prevPortfolios.filter((portfolio) => portfolio.id !== portfolioId));
  };

  const removeAddressReportFromPortfolio = async (portfolioId: number, address: string) => {
    await handleRestResponse(
      axios.delete(`/api/portfolios/${portfolioId}/address/${address}`),
      "Address removed from portfolio",
    );

    const portfolio = portfolios.find((portfolio) => portfolio.id === portfolioId);
    if (!portfolio) return;

    if (portfolio.data) {
      portfolio.data = portfolio.data.filter(({ addressInfo }: Report | Ethereum.Report) => {
        return addressInfo.address.toLowerCase() !== address.toLowerCase();
      });
    }

    portfolio.addresses = portfolio.addresses.filter((address) => address.toLowerCase() !== address.toLowerCase());

    setPortfolios([...portfolios]);
  };

  const addAddressReportToPortfolio = async (portfolioId: number) => {
    if (!activeReport) throw new Error("No active report set before this action - use cacheActiveReport first");

    await handleRestResponse(
      axios.put(`/api/portfolios/${portfolioId}`, { data: activeReport }),
      "Address added to portfolio",
    );

    const portfolio = portfolios.find((portfolio) => portfolio.id === portfolioId);
    if (!portfolio) return;

    if (portfolio.data) {
      portfolio.data.push(activeReport);
    }

    portfolio.addresses.push(activeReport.addressInfo.address);

    setPortfolios([...portfolios]);
  };

  return {
    portfolios,
    arePortfoliosLoaded,
    loadPortfolios,
    loadPortfolio,
    createPortfolio,
    updatePortfolioName,
    deletePortfolio,
    addAddressReportToPortfolio,
    removeAddressReportFromPortfolio,
    cacheActiveReport,
  };
};
