import React from "react";
import { Tooltip } from "@mui/material";
import useIsOverflow from "hooks/useIsOverflow";
import { Colors } from "styles";
import { Grid, Label } from "ui";
import * as S from "./styled";

type Props = {
  label: string;
  value: React.ReactNode | string | string[];
  direction?: "column" | "row";
  print?: boolean;
  bold?: boolean;
  valueBold?: boolean;
  width?: number | string;
  justifyContent?: string;
  labelColor?: keyof Colors;
  style?: React.CSSProperties;
  labelLineHeight?: string;
  valueLineHeight?: string;
  gridType?: "container" | "item";
};

type ValueWithOverflowProps = {
  item: React.ReactNode | string;
  print: boolean;
  width: number | string;
  valueBold?: boolean;
  valueLineHeight?: string;
  style?: React.CSSProperties;
  gridType?: "container" | "item";
  isLast: boolean;
};

const ValueWithOverflow = React.memo(
  ({ item, print, width, valueBold, valueLineHeight, style, gridType, isLast }: ValueWithOverflowProps) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const isOverflow = useIsOverflow(ref as React.RefObject<HTMLElement>);

    const ValueComponent = (
      <S.Value
        ref={ref}
        $print={print}
        width={width}
        $bold={valueBold}
        $valueLineHeight={valueLineHeight}
        style={style}
      >
        {item}
        {isLast && gridType === "container" ? "." : !isLast && gridType === "container" ? ", " : ""}
      </S.Value>
    );

    if (isOverflow && !print) {
      return (
        <Tooltip title={String(item)} placement="bottom">
          <div>{ValueComponent}</div>
        </Tooltip>
      );
    }

    return ValueComponent;
  },
);

ValueWithOverflow.displayName = "ValueWithOverflow";

const LabelValue = ({
  label,
  value = "-",
  bold = false,
  direction = "column",
  print = false,
  width = 130,
  labelColor = "darkGray",
  valueBold,
  justifyContent,
  style,
  labelLineHeight,
  valueLineHeight,
  gridType = "item",
}: Props) => {
  const values = Array.isArray(value) ? value : [value];

  return (
    <Grid
      container
      direction={direction}
      columnSpacing={1}
      rowSpacing={values.length > 1 ? 1 : "4px"}
      alignItems={direction === "row" ? "center" : undefined}
      justifyContent={justifyContent ?? (direction === "row" ? "flex-end" : undefined)}
    >
      <Grid item>
        <Label color={labelColor} size="small" labelLineHeight={labelLineHeight}>
          {bold ? <b>{label}</b> : label}
        </Label>
      </Grid>
      <Grid {...(gridType === "container" ? { container: true } : { item: true })}>
        {values.map((item, index) => (
          <ValueWithOverflow
            key={typeof item === "string" ? item : index}
            item={item}
            print={print}
            width={width}
            valueBold={valueBold}
            valueLineHeight={valueLineHeight}
            style={style}
            gridType={gridType}
            isLast={index === values.length - 1}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default LabelValue;
