import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Section, Metadata, HighRiskTransactions, NFTGallery, PaymentBanner } from "components";
import { useErrorHandler } from "hooks";
import { Grid, Loading, Button } from "ui";
import {
  convertWeiToEth,
  capitalLetter,
  formatCryptoCurrencyValue,
  formatDate,
  formatFiatValue,
  getTypeToDisplay,
  getEntityToDisplay,
} from "utils";

import AuthContext from "contexts/Auth0Context";
import AppContext from "contexts/AppContext";
import { TransactionsList, TransactionSummary } from "./../TransactionSummary";

import { Transfer } from "types/ethereum";
import { EntityTransaction, Report, ReportMeta } from "types";
import * as EthereumType from "types/ethereum";
import { ReportHeader } from "../ReportHeader";
import EthTabs from "./EthTabs";
import ERC20Summary from "./ERC20Summary";
import type { MetadataItem } from "components/Metadata";
import LargeReportMessage from "../LargeReportMessage";
import { useAccessControl } from "hooks/useAccessControl";
import { usePortfoliosData } from "hooks/usePortfoliosData";

const EthereumReport = () => {
  const [report, setReport] = useState<EthereumType.Report>();
  const [metadata, setMetadata] = useState<MetadataItem[]>([]);
  const [tabIndex, setTokenTab] = useState(0);
  const [directionTab, setDirectionTab] = useState(0);
  const [isLargeReport, setIsLargeReport] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [addressRelatedTags, setAddressRelatedTags] = useState<{ type: string }[]>([]);
  const [transactionDetailSummary, setTransactionDetailSummary] = useState({
    txCount: {
      in: 0,
      out: 0,
    },
    amount: {
      in: 0,
      out: 0,
    },
    value: {
      in: 0,
      out: 0,
    },
    firstDate: "",
    lastDate: "",
  });

  const handleError = useErrorHandler();
  const { entityTypes } = useContext(AppContext);
  const hasAccess = useAccessControl("reports");
  const { cacheActiveReport } = usePortfoliosData();
  const [isAddressInPortfolio, setIsAddressInPortfolio] = useState<boolean>(false);

  const [transactionListEntityName, setTransactionListEntityName] = useState<string | null>(null);
  const [counterPartyAddresses, setCounterPartyAddresses] = useState<string[]>([]);
  const [tokenAddress, setTokenAddress] = useState<string>("");

  const { address, cache } = useParams();
  useEffect(() => {
    const loadData = async () => {
      try {
        const controller = new AbortController();
        setIsLoaded(false);
        setReport(undefined);
        cacheActiveReport(null);
        const reportInfo = await axios.get(`/api/reports/eth/${address}/info`);
        setIsLargeReport(reportInfo.data.isLargeAddress);
        setIsAddressInPortfolio(reportInfo.data.isAddressInSomePortfolio);

        const reportData = await axios.get<EthereumType.Report>(
          `/api/reports/eth/${address}${cache ? "/" + cache : ""}`,
          {
            signal: controller.signal,
          },
        );

        setReport(reportData.data);
        cacheActiveReport(reportData.data);
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoaded(true);
      }
    };

    loadData();
  }, [address]);

  const getKnownTags = (txs: Transfer[]) => {
    const knownTags = new Set<string>();
    for (const tx of txs) {
      const type = getTypeToDisplay(entityTypes, {
        entityType: tx.entityType,
        entitySubType: tx?.entitySubType,
        controllerType: tx.controllerType,
        controllerSubType: tx.controllerSubType,
      }).name;
      if (type !== "Unknown") {
        knownTags.add(type);
      }
    }
    return knownTags;
  };

  useEffect(() => {
    if (!report) return;
    let knowTags: Set<string>;
    knowTags = new Set([...getKnownTags(Object.values(report.native).flat())]);
    const tokenTags = getKnownTags([
      ...Object.values(report.erc20.incoming).flat(),
      ...Object.values(report?.erc20.outgoing).flat(),
    ]);

    const highRiskTags = getKnownTags(Object.values(report.highRisks).flat());
    knowTags = new Set([...knowTags, ...tokenTags, ...highRiskTags]);
    setAddressRelatedTags([...knowTags].map((tag) => ({ type: tag })));

    if (tabIndex == 0) {
      setMetadata([
        {
          title: "Balance",
          value: `${formatCryptoCurrencyValue(report.addressInfo.balance, "eth")} ETH`,
        },
        {
          title: "Total Received",
          value: `${formatCryptoCurrencyValue(report.addressInfo.received, "eth")} ETH`,
        },
        {
          title: "Total Sent",
          value: `${formatCryptoCurrencyValue(report.addressInfo.received - report.addressInfo.balance, "eth")} ETH`,
        },
        {
          title: "Total Transactions",
          value: report.addressInfo.txCount,
        },
        {
          title: "First Transaction",
          value: formatDate(report.addressInfo.firstTx),
        },

        {
          title: "Last Transaction",
          value: formatDate(report.addressInfo.lastTx),
        },
      ]);
    } else if (tabIndex === 1) {
      const totalTokensValue = report.addressInfo.tokens.reduce((acc, token) => acc + token.value, 0);
      setMetadata([
        {
          title: "Transactions",
          value: report.addressInfo.tokens.reduce(
            (acc, token) => acc + token.incomingTransactionCount + token.outgoingTransactionCount,
            0,
          ),
        },
        {
          title: "First Transaction",
          value: report.addressInfo.firstTokenTransaction ? formatDate(report.addressInfo.firstTokenTransaction) : "",
        },
        {
          title: "Last Transaction",
          value: report.addressInfo.lastTokenTransaction ? formatDate(report.addressInfo.lastTokenTransaction) : "",
        },
        {
          title: "Total Value ($)",
          value: formatFiatValue(totalTokensValue, "erc20"),
        },
      ]);
    } else {
      setMetadata([]);
    }
  }, [tabIndex, report]);

  if (!isLoaded) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Loading
          variant="small"
          text={isLargeReport ? "This address is large. Please wait for the report to generate." : ""}
        />
      </Grid>
    );
  }

  if (!report) {
    return <></>;
  }

  const handleTokenTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setTokenTab(newTab);
  };

  const reportData: Report = {
    addressInfo: {} as ReportMeta,
    sources: [],
    destinations: [],
    highRisks: [],
    dataFrom: "",
    limitedResult: false,
  };

  if (tabIndex === 0) {
    // Native (ETH)
    reportData.sources = report.native.incoming.map((tx) => {
      const { entityName, entityType } = getEntityToDisplay(tx, entityTypes);
      const entityDetails = {
        name: tx.entityName,
        type: tx.entityType,
        subType: tx?.entitySubType,
      };
      return {
        ...tx,
        amount: tx.amount,
        value: tx.value,
        entityName,
        entityType,
        entityDetails,
      };
    });
  } else if (tabIndex === 1) {
    // ERC20
    reportData.sources = report.erc20.incoming.map((tx) => {
      const { entityName, entityType } = getEntityToDisplay(tx, entityTypes);
      const entityDetails = {
        name: tx.entityName,
        type: tx.entityType,
        subType: tx?.entitySubType,
      };
      return {
        ...tx,
        hop: tx.curHop,
        amount: tx.amount,
        value: tx.value,
        entityName,
        entityType,
        entityDetails,
        name: tx.entityName,
      };
    });
  }

  if (tabIndex === 0) {
    // Native (ETH)
    reportData.destinations = report.native.outgoing.map((tx) => {
      const { entityName, entityType } = getEntityToDisplay(tx, entityTypes);
      const entityDetails = {
        name: tx.entityName,
        type: tx.entityType,
        subType: tx?.entitySubType,
      };
      return {
        ...tx,
        hop: tx.curHop ?? tx.hop,
        amount: tx.amount,
        value: tx.value,
        entityName,
        entityType,
        entityDetails,
        name: tx.entityName,
      };
    });
  } else if (tabIndex === 1) {
    // ERC20
    reportData.destinations = report.erc20.outgoing.map((tx) => {
      const { entityName, entityType } = getEntityToDisplay(tx, entityTypes);
      const entityDetails = {
        name: tx.entityName,
        type: tx.entityType,
        subType: tx?.entitySubType,
      };
      return {
        ...tx,
        hop: tx.curHop ?? tx.hop,
        amount: tx.amount,
        value: tx.value,
        entityName,
        entityType,
        entityDetails,
        name: tx.entityName,
      };
    });
  }

  reportData.highRisks = [...report.highRisks.incoming, ...report.highRisks.outgoing].map((tx) => {
    return {
      labels: tx.labels || [],
      ...tx,
      date: tx.date.toString(),
      // TODO caused by inconsistency between cached and live data
      hop: tx.hop ?? tx.curHop ?? 0,
    };
  });

  reportData.addressInfo = {
    ...report.addressInfo,
    balance: convertWeiToEth(report.addressInfo.balance),
    received: convertWeiToEth(report.addressInfo.received),
    sent: convertWeiToEth(report.addressInfo.balance - report.addressInfo.received),
  };

  reportData.dataFrom = "";
  reportData.limitedResult = false;

  const openTokenTransactionList = (tokenAddress: string, tokenSymbol: string) => {
    const tokenData = Object.values(report.addressInfo.tokens).find((token) => token.tokenAddress === tokenAddress);
    if (!tokenData) {
      return;
    }

    setTransactionDetailSummary({
      txCount: {
        in: tokenData.incomingTransactionCount,
        out: tokenData.outgoingTransactionCount,
      },
      amount: {
        in: tokenData.incomingAmount,
        out: tokenData.outgoingAmount,
      },
      value: {
        in: tokenData.incomingValue,
        out: tokenData.outgoingValue,
      },
      firstDate: tokenData.firstTransactionDate,
      lastDate: tokenData.lastTransactionDate,
    });

    setCounterPartyAddresses([]);
    setTokenAddress(tokenAddress);
    setTransactionListEntityName(tokenSymbol || "Unknown Token");
  };

  const openNativeTransactionsList = async (
    entityName: string | null,
    addresses: string[],
    hop: number,
    isBreakdown: boolean,
    firstTxDate: string,
    lastTxDate: string,
    // direction: "incoming" | "outgoing"
  ) => {
    let allReportNativeTransactions: EntityTransaction[] = [];
    if (tabIndex === 0) {
      allReportNativeTransactions = ([] as EntityTransaction[])
        .concat(reportData.sources)
        .concat(reportData.destinations);
    } else if (tabIndex === 1) {
      allReportNativeTransactions = ([] as EntityTransaction[])
        .concat(report.erc20.incoming)
        .concat(report.erc20.outgoing);
    }

    const detailTransactions = allReportNativeTransactions.filter((tx) => {
      if (tx.curHop !== hop) {
        return false;
      }
      if (entityName && entityName !== "Unknown" && !isBreakdown) {
        return tx.entityName === entityName || tx.controllerTag === entityName;
      } else {
        return addresses.includes(tx.address);
      }
    });

    const incomingTransactions = detailTransactions.filter((tx) => {
      return tx.direction === "in";
    });

    const outgoingTransactions = detailTransactions.filter((tx) => {
      return tx.direction === "out";
    });

    const totalIncomingTxCount = incomingTransactions.reduce((acc, tx) => {
      return acc + tx.txCount;
    }, 0);

    const outGoingTxCount = outgoingTransactions.reduce((acc, tx) => {
      return acc + tx.txCount;
    }, 0);

    const totalIncomingTxAmount = incomingTransactions.reduce((acc, tx) => {
      return acc + tx.amount;
    }, 0);

    const outGoingTxAmount = outgoingTransactions.reduce((acc, tx) => {
      return acc + tx.amount;
    }, 0);

    const totalIncomingTxValue = incomingTransactions.reduce((acc, tx) => {
      return acc + tx.value;
    }, 0);

    const outGoingTxValue = outgoingTransactions.reduce((acc, tx) => {
      return acc + tx.value;
    }, 0);

    let counterPartyAddresses: string[];
    if (isBreakdown) {
      counterPartyAddresses = addresses;
    } else {
      counterPartyAddresses = [...new Set(detailTransactions.map((tx) => tx.address))];
    }

    setCounterPartyAddresses(counterPartyAddresses);

    setTransactionDetailSummary({
      txCount: {
        in: totalIncomingTxCount,
        out: outGoingTxCount,
      },
      amount: {
        in: totalIncomingTxAmount,
        out: outGoingTxAmount,
      },
      value: {
        in: totalIncomingTxValue,
        out: outGoingTxValue,
      },
      firstDate: firstTxDate,
      lastDate: lastTxDate,
    });
    setTransactionListEntityName(entityName);
  };

  return (
    <Grid container maxWidth={1000} marginX="auto" marginTop={5} direction="column">
      <PaymentBanner
        hasAccess={hasAccess}
        title="Unlock In-Depth Address Reports"
        description="Upgrade your plan to gain access to detailed address reports with detailed overview of risky transactions."
        style={{
          top: 20,
        }}
      />
      <Grid container columnSpacing={3} rowSpacing={4}>
        <ReportHeader
          reportType={"eth"}
          cryptoBalance={report.addressInfo.balance}
          address={report.addressInfo.address}
          owner={{
            name: report.addressInfo.entityName,
            type: report.addressInfo.entityType,
            subType: report.addressInfo.entitySubType,
            events: report.addressInfo.ownerEvents,
          }}
          controller={{
            name: report.addressInfo.controllerTag,
            type: report.addressInfo.controllerType,
            subType: report.addressInfo.controllerSubType,
            events: report.addressInfo.controllerEvents,
          }}
          isInPortfolio={isAddressInPortfolio}
          addressRelatedTags={addressRelatedTags}
          labels={report.addressInfo.labels}
          highRiskCount={report.highRisks.incoming.length + report.highRisks.outgoing.length}
        />
        <Grid container item rowSpacing={3}>
          <EthTabs tabIndex={tabIndex} handleTokenTabChange={handleTokenTabChange} />

          {tabIndex !== 2 && (
            <Grid item xs={12}>
              <Metadata list={metadata} columns={3} />
            </Grid>
          )}

          {tabIndex == 1 && (
            <ERC20Summary tokens={reportData.addressInfo.tokens} onTxCountClick={openTokenTransactionList} />
          )}

          {tabIndex == 2 && (
            <Grid item xs={12}>
              <Section>
                <NFTGallery />
              </Section>
            </Grid>
          )}

          {tabIndex !== 2 && (
            <Grid container item xs={12} rowSpacing={3}>
              <Grid item container columnSpacing={1}>
                <Grid item>
                  <Button
                    color={directionTab === 0 ? "primary" : "secondary"}
                    variant={directionTab === 0 ? "contained" : "outlined"}
                    onClick={() => setDirectionTab(0)}
                    style={{
                      boxShadow: "none",
                      padding: "4px 20px",
                      height: "40px",
                    }}
                  >
                    Sources
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color={directionTab === 1 ? "primary" : "secondary"}
                    variant={directionTab === 1 ? "contained" : "outlined"}
                    onClick={() => setDirectionTab(1)}
                    style={{
                      boxShadow: "none",
                      padding: "4px 20px",
                      height: "40px",
                    }}
                  >
                    Destinations
                  </Button>
                </Grid>
              </Grid>
              <Grid item>
                {isLargeReport && (
                  <Grid item xs="auto">
                    <LargeReportMessage />
                  </Grid>
                )}
              </Grid>
              <TransactionSummary
                onTxCountClick={openNativeTransactionsList}
                originAddress={report.addressInfo.address}
                direction={directionTab === 0 ? "incoming" : "outgoing"}
                transactions={directionTab === 0 ? reportData.sources : reportData.destinations}
                hideAmounts={tabIndex === 1}
                isLargeAddress={true}
                chain={tabIndex === 0 ? "eth" : "erc20"}
                entityTypes={entityTypes}
              />
              <HighRiskTransactions
                transactions={reportData.highRisks.map((highRisk) => ({
                  ...highRisk,
                  direction: capitalLetter(highRisk.direction),
                }))}
                chain={tabIndex === 0 ? "eth" : "erc20"}
              />
            </Grid>
          )}
        </Grid>
        <Grid item>
          {transactionListEntityName && (
            <TransactionsList
              counterPartyName={transactionListEntityName}
              originEntityName={(report.addressInfo.entityName as string) || "Unknown"}
              defaultTab={directionTab === 0 ? "Sources" : "Destinations"}
              originAddress={report.addressInfo.address}
              counterPartyAddress={counterPartyAddresses}
              coin={tabIndex === 0 ? "eth" : "erc20"}
              summary={transactionDetailSummary}
              onClose={() => {
                setTokenAddress("");
                setCounterPartyAddresses([]);
                setTransactionListEntityName(null);
              }}
              tokenAddress={tokenAddress}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EthereumReport;
