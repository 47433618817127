import { AppContext } from "contexts/AppContext";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Title, Label, Grid, Chip } from "ui";
import EntitiesList from "./EntitiesList";
import { useModal } from "contexts/ModalContext";
import { EntityProfile, PaymentBanner } from "components";
import { Profile } from "types";
import { useAccessControl } from "hooks/useAccessControl";

export const EntityProfiles = () => {
  const appContext = useContext(AppContext);
  const { openModal } = useModal();
  const [activeCategories, setActiveCategories] = useState<string>("All");
  const { category } = useParams();
  const hasAccess = useAccessControl("profiles");

  const entities = appContext.entities
    .sort((a, b) => (b.riskScore || 0) - (a.riskScore || 0))
    .map((profile) => ({
      rank: category ? profile.typeRank : profile.globalRank,
      ...profile,
    }));
  const [activeEntities, setActiveEntities] = useState<Profile[]>(entities);

  let entityTypes = entities.map((entity) => entity.type).filter((type) => type !== "Wallet");
  entityTypes = [...new Set(entityTypes)];
  entityTypes.unshift("All");

  const showEntity = (entityName: string) => {
    const profile = entities.find((entity) => entity.name === entityName);
    openModal(<EntityProfile entity={profile!} />);
  };

  const updateActiveCategories = (category: string) => {
    const newEntities = appContext.entities
      .filter((entity) => {
        if (category === "All") return true;
        return category === entity.type && entity.type !== "Wallet";
      })
      .sort((a, b) => (b.riskScore || 0) - (a.riskScore || 0))
      .map((profile) => ({
        rank: profile.globalRank,
        ...profile,
      }));

    setActiveCategories(category);
    setActiveEntities(newEntities);
  };

  return (
    <Grid container direction="column" rowSpacing={3} position="relative">
      <Grid item>
        <Title>Leaderboards</Title>
        <Label>Entities ranked against Hoptrail’s proprietary Anti-Money Laundering (AML) scoring system.</Label>
      </Grid>
      <Grid container item rowSpacing={2} columnSpacing={1}>
        {entityTypes.map((type) => (
          <Grid item key={type}>
            <Chip
              key={type}
              label={type}
              sx={(theme) => ({
                fontSize: "14px",
                backgroundColor: activeCategories.includes(type) ? theme.colors.primary : theme.colors.white,
                color: activeCategories.includes(type) ? theme.colors.white : theme.colors.darkGray,
                border: activeCategories.includes(type) ? "none" : `1px solid ${theme.colors.gray}`,
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.background.default,
                },
              })}
              clickable
              onClick={() => updateActiveCategories(type)}
            />
          </Grid>
        ))}
      </Grid>
      <EntitiesList
        rows={activeEntities}
        display={activeCategories === "All" ? "All" : "Category"}
        onEntitySelected={(entity) => showEntity(entity.name)}
      />
      <PaymentBanner
        hasAccess={hasAccess}
        title="Unlock AML Ranking Leaderboard"
        description="Upgrade your plan to gain access to detailed leaderboard and anti-money laundering rankings."
      />
    </Grid>
  );
};

export default EntityProfiles;
