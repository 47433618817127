import { useContext, useEffect, useState } from "react";
import { FormControlLabel, Tooltip } from "@mui/material";
import { LabelValue } from "components";
import { Grid, Switch } from "ui";
import AppContext from "contexts/AppContext";
import { formatCryptoCurrencyValue, formatFiatValue } from "utils";

import * as S from "../styled";
import AuthContext from "contexts/Auth0Context";
import { hasFeatureAccess } from "utils/featureUtils";
import { TransactionDetails } from "pages/Report/TransactionSummary/TransactionDetails";
import getColumns from "./columns";
// TODO Sync with Transaction Type in BTC and ETH
export type SummaryTransaction = {
  address: string;
  entityName?: string;
  entityType?: string;
  time?: Date;
  amount: number;
  value: number;
  curHop: number;
  direction: string;
};

type Props = {
  title?: string;
  direction: "incoming" | "outgoing";
  transactions: any[];
  isLargeAddress?: boolean;
  originAddress: string;
  onTxCountClick?: (entityName: string, address: string, hop: number, isBreakdown: boolean) => void;
};

const BitcoinTransactionSummary = ({ transactions, isLargeAddress, onTxCountClick }: Props) => {
  const { entityTypes } = useContext(AppContext);
  const { user } = useContext(AuthContext);
  const hiddenTags = ["Unknown", "Unknown Addresses", "Returning Funds"];
  const systemTags = ["Unknown", "Unknown Address", "Unknown Addresses", "Returning Funds", "Insignificant Amounts"];
  const [rows, setRows] = useState<any[]>([]);
  const [displayAddressBreakdown, setDisplayAddressBreakdown] = useState(false);
  const [showIndirect, setShowIndirect] = useState(false);
  const [showUnknownEntities, setShowUnknownEntities] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const hasEventsAccess = hasFeatureAccess("events", user.isAdmin);

  const [expandedRowIds, setExpandedRowIds] = useState<(string | number)[]>([]);
  // TODO this seems like should be coming from backend

  const showIndirectChange = () => {
    setShowIndirect((prev) => !prev);
  };

  const showBreakdownChange = () => {
    setDisplayAddressBreakdown((prev) => !prev);
  };

  const showAllChange = () => {
    setShowUnknownEntities((prev) => !prev);
  };

  const handleRowExpandToggle = (rowId: string | number) => {
    setExpandedRowIds((prev) => (prev.includes(rowId) ? prev.filter((id) => id !== rowId) : [...prev, rowId]));
  };

  useEffect(() => {
    const destinationData = transactions.reduce((prev: any, next: any) => {
      if ((showIndirect && !next.entityType && !next.endOfHop) || (!showIndirect && next.curHop > 1)) {
        return prev;
      }

      const entityName =
        displayAddressBreakdown || next.entityName === "Unknown"
          ? next.address + next.curHop
          : (next.entityName || "Unknown") + next.curHop;

      const firstTx = next.firstDate;
      const lastTx = next.lastDate;

      if (entityName in prev) {
        prev[entityName].amount += next.amount;
        prev[entityName].value += next.value;
        prev[entityName].txCount = next.txs ? prev[entityName].txCount + next.txs.length : prev[entityName].txCount + 1;
        if (prev[entityName].firstTx > firstTx) {
          prev[entityName].firstTx = firstTx;
        }
        if (prev[entityName].lastTx < lastTx) {
          prev[entityName].lastTx = lastTx;
        }
      } else {
        prev[entityName] = {
          ...next,
          firstTx,
          lastTx,
          txCount: next.txs ? next.txs.length : 1,
          entityName:
            displayAddressBreakdown &&
            (!next.entityName || systemTags.some((unknownEntityTag) => next.entityName.includes(unknownEntityTag)))
              ? "Unknown"
              : next.entityName,
        };
      }

      return prev;
    }, {});

    let curRows = Object.keys(destinationData).map((sourceName: any, index: number) => {
      const isKnownEntity =
        destinationData[sourceName].entityName && destinationData[sourceName].entityName !== "" ? true : false;
      return {
        id: index,
        ...destinationData[sourceName],
        entityName: isKnownEntity
          ? destinationData[sourceName].entityName
          : displayAddressBreakdown
          ? "Unknown"
          : "Hops Exceeded",
      };
    });

    if (!showUnknownEntities) {
      curRows = curRows.filter((row) => !hiddenTags.includes(row.entityName) && !row.entityName.startsWith("Unknown"));
    }

    // Sum total amount and total value from curRows
    const { amountSum, valueSum } = curRows.reduce(
      (previousValue, currentValue) => {
        return {
          amountSum: previousValue.amountSum + currentValue.amount,
          valueSum: previousValue.valueSum + currentValue.value,
        };
      },
      {
        amountSum: 0,
        valueSum: 0,
      },
    );

    setRows(curRows);
    if (amountSum === 0 && totalAmount === 0) {
      setShowUnknownEntities(true);
    }
    setTotalAmount(amountSum);
    setTotalValue(valueSum);
  }, [displayAddressBreakdown, showUnknownEntities, showIndirect, transactions]);

  const columns = getColumns({ displayAddressBreakdown, onTxCountClick, chain: "btc" });

  return (
    <Grid container rowSpacing={3} margin={0}>
      <Grid container item xs={12} md columnSpacing={2}>
        <Grid item>
          <Tooltip disableHoverListener={true} title="This figure does not include gas fees on sent funds">
            <span>
              <LabelValue
                value={(formatCryptoCurrencyValue(totalAmount, "btc") || "0.000") + " BTC"}
                label="Total Amount:"
                direction="row"
                valueBold={true}
              />
            </span>
          </Tooltip>
        </Grid>

        <Grid item>
          <LabelValue
            value={formatFiatValue(totalValue, "btc")}
            label="Total Value ($):"
            direction="row"
            valueBold={true}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} md="auto" columnSpacing={2}>
        {!isLargeAddress && (
          <Grid item>
            <Tooltip placement="top" title="Toggle between direct and indirect counterparties">
              <span>
                <FormControlLabel
                  control={<Switch size="small" checked={showIndirect} onChange={showIndirectChange} />}
                  label="Show Indirect"
                  sx={{
                    marginRight: 0,
                    width: "150px",
                  }}
                />
              </span>
            </Tooltip>
          </Grid>
        )}
        <Grid item>
          <Tooltip placement="top" title="Show address details for each entity">
            <span>
              <FormControlLabel
                control={<Switch size="small" checked={displayAddressBreakdown} onChange={showBreakdownChange} />}
                label="Show Addresses"
                sx={{
                  marginRight: "0px",
                }}
              />
            </span>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip placement="top" title="Show all counterparties">
            <span>
              <FormControlLabel
                control={<Switch size="small" checked={showUnknownEntities} onChange={showAllChange} />}
                label="Show All Entities"
                sx={{
                  marginRight: "0px",
                  width: "170px",
                }}
              />
            </span>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <S.Table
          fontSize="small"
          columns={columns}
          allowExport={user.isAdmin}
          rows={rows}
          isExpandable={true}
          initialState={{
            sorting: {
              sortModel: [{ field: "value", sort: "desc" }],
            },
          }}
          rowHeight={56}
          expandedRowIds={expandedRowIds}
          onRowExpandToggle={handleRowExpandToggle}
          renderRowDetails={({ row }) => {
            const ownerEvents = row?.ownerEvents ?? [];
            const controllerEvents = row?.controllerEvents ?? [];
            const events = [...ownerEvents, ...controllerEvents];
            return (
              <TransactionDetails
                labels={row.labels}
                riskEvents={events}
                displayAddressBreakdown={displayAddressBreakdown}
                controllerTag={row?.controllerTag}
                controllerType={row?.controllerType}
                controllerSubType={row?.controllerSubType}
                entityDetails={row?.entityDetails}
                entityType={row.entityType}
                entitySubType={row.entitySubType}
                entityTypes={entityTypes}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

export default BitcoinTransactionSummary;
