import { Grid, Subtitle, Label, Description, Link, Divider, RisktrailScore, ProgressBar } from "ui";

import type { EntityCompliance, EntitySolvency } from "types";
import OverviewList from "components/EntityProfile/OverviewList";

type Props = {
  solvency: EntitySolvency;
};

export const Solvency = ({ solvency }: Props) => (
  <Grid container direction="column" xs={12} rowSpacing={2} height="100%" flexWrap="nowrap">
    <Grid container item justifyContent="space-between" direction="column" rowSpacing={2}>
      <Grid container item>
        <Grid item container direction="column" rowSpacing={3}>
          <Grid item container justifyContent="space-between">
            <Grid item>
              <Subtitle>Solvency Transparency </Subtitle>
            </Grid>
            <Grid item>
              <RisktrailScore
                score={solvency.solvencyScore}
                value={solvency.solvencyScore + "%"}
                justifyContent="center"
                ignoreScoreColor={true}
              />
            </Grid>
          </Grid>
          <Grid item>
            <ProgressBar color="lightGreen" percentage={solvency.solvencyScore} />
          </Grid>
        </Grid>
      </Grid>

      <Grid container item direction="column" rowSpacing={6} flexWrap="nowrap">
        <Grid container item rowSpacing={2}>
          <OverviewList
            items={[
              {
                label: "Wallet Disclosure",
                value: solvency.disclosedWallets,
                tooltip:
                  "Whether the VASP publicly discloses its cold and/or hot wallets, and the extent of that disclosure",
              },
              {
                label: "Merkle Tree Audit",
                value: solvency.merkelTreeAudit.toString() as EntityCompliance,
                hideTag: true,
                tooltip: "Whether the VASP published details or evidence on its on-chain auditing procedures",
              },
              {
                label: "Liabilities Disclosure",
                tooltip:
                  "Whether the VASP publishes details of its on-chain liabilities, or provides detailed financial accounts",
                value: solvency.liabilitiesDiscloure.toString() as EntityCompliance,
                hideTag: true,
              },
              {
                label: "User Verification",
                value: solvency.userVerification.toString() as EntityCompliance,
                tooltip: "Whether a VASP permits its customers to verify the assets they hold on the platform",
                hideTag: true,
              },
              {
                label: "1:1 Solvency Ratio",
                tooltip: "Evidence that the VASP holds exchange and customer assets on at least a 1:1 ratio",
                value: solvency.solvencyRatio.toString() as EntityCompliance,
                additionalInfo: solvency.assets?.join(","),
                hideTag: true,
              },
              {
                label: "Snapshot",
                tooltip: "How often the VASP discloses proof-of-assets (i.e. the status of its onchain holdings)",
                value: solvency.assetSnapshot,
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid container item xs={12} flexWrap="nowrap" direction="column" justifyContent="flex-end" rowSpacing={1}>
      {solvency.resources.length > 0 && (
        <Grid container item direction="column">
          <Grid item>
            <Label>Resources</Label>
          </Grid>
          <Grid item>
            {solvency.resources.map((resource, index) => (
              <Link
                href={resource}
                key={index}
                target="_blank"
                rel="noreferrer"
                variant="caption"
                sx={(theme) => ({
                  color: theme.colors.darkGray,
                  textDecorationColor: theme.colors.darkGray,
                })}
              >
                {resource.length > 70 ? resource.substring(0, 70) + "..." : resource}
              </Link>
            ))}
          </Grid>
        </Grid>
      )}
      <Grid item>
        <Divider />
      </Grid>
      <Grid item alignItems="flex-end">
        <Description style={{ fontSize: "12px" }}>
          Data is drawn from information publicly disclosed by exchanges, exchange auditors, or which are available on
          Etherscan or in Hoptrail&apos;s own datasets. Asset data covers only blockchains that we support and is not an
          exhaustive, complete or comprehensive list of actual assets or reserves held by the exchange on behalf of its
          users or customers. In some cases, hot and cold wallet designations are approximations based on transaction
          patterns.
        </Description>
      </Grid>
    </Grid>
  </Grid>
);

export default Solvency;
