import styled from "styled-components";

const LabelSize = {
  small: "14px",
  normal: "16px",
};

export const Label = styled.div<{
  color: string;
  size: "small" | "normal";
  whiteSpace?: string;
  fontWeight?: string;
  $labelLineHeight?: string;
}>`
  font-size: ${(props) => LabelSize[props.size]};
  color: ${(props) => props.theme.colors[props.color]};
  font-weight: ${(props) => props.fontWeight || "500"};
  white-space: ${(props) => props.whiteSpace || "nowrap"};
  line-height: ${(props) => props.$labelLineHeight};
`;
