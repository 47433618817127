import { Profile } from "types";
import { RisktrailScore, Grid, Chip, Label } from "ui";

type Props = {
  entity: Profile;
};

export const Stats = ({ entity }: Props) => (
  <Grid container item columnSpacing={2} flexWrap="wrap" rowSpacing={2}>
    {entity.riskScore !== null && (
      <Grid container item direction="column" rowSpacing={1} width="180px">
        <Grid item>
          <Label>AML score</Label>
        </Grid>
        <RisktrailScore
          borderRadius={2}
          score={entity.riskScore}
          value={entity.riskScore.toFixed(2)}
          entityType={entity.type} // changed from entity.tag
        />
      </Grid>
    )}

    {entity.solvencyScore !== null && (
      <Grid container item direction="column" rowSpacing={1} width="180px">
        <Grid item>
          <Label>Solvency Transparency</Label>
        </Grid>
        <RisktrailScore
          score={entity.solvencyScore}
          value={entity.solvencyScore + "%"}
          justifyContent="center"
          ignoreScoreColor={true}
          borderRadius={2}
        />
      </Grid>
    )}

    {entity.typeRank && entity.status !== "Inactive" && (
      <Grid container item direction="column" sm={6} rowSpacing={1}>
        <Grid item>
          <Label>Category Rank</Label>
        </Grid>
        <Grid item>
          <Chip
            label={`#${entity.typeRank}`}
            style={{
              borderRadius: 4,
            }}
            sx={(theme) => ({
              fontWeight: "bold",
              backgroundColor: theme.colors.lightGray,
              color: theme.colors.black,
              width: "80px",
            })}
          />
        </Grid>
      </Grid>
    )}
  </Grid>
);

export default Stats;
