import React from "react";
type FlagIconProps = {
  color: string;
  minWidth?: string;
};
const FlagIconNoBackground: React.FC<FlagIconProps> = ({ color, minWidth }) => (
  <svg
    style={{
      minWidth,
    }}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M5.83331 11.25V16.6667C5.83331 16.8438 5.77338 16.9922 5.65352 17.1119C5.53366 17.2317 5.38519 17.2917 5.2081 17.2917C5.03088 17.2917 4.88248 17.2317 4.7629 17.1119C4.64317 16.9922 4.58331 16.8438 4.58331 16.6667V4.50333C4.58331 4.28986 4.65554 4.11097 4.79998 3.96667C4.94429 3.82222 5.12317 3.75 5.33665 3.75H10.7066C10.8846 3.75 11.0434 3.80583 11.1831 3.9175C11.3227 4.02917 11.4107 4.17417 11.4471 4.3525L11.6633 5.41667H15.4966C15.7101 5.41667 15.889 5.48854 16.0333 5.63229C16.1778 5.77604 16.25 5.95417 16.25 6.16667V12.1669C16.25 12.3794 16.1778 12.5574 16.0333 12.701C15.889 12.8448 15.7101 12.9167 15.4966 12.9167H11.7933C11.6154 12.9167 11.4566 12.8608 11.3169 12.7492C11.1773 12.6375 11.0893 12.4925 11.0529 12.3142L10.8366 11.25H5.83331Z"
      fill={color}
    />
  </svg>
);
export default FlagIconNoBackground;
