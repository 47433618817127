import { Grid } from "ui";
import { ReportTitle } from "./";
import { useTheme } from "@mui/material";
import { Entity } from "types";

type Props = {
  reportType: "btc" | "eth";
  address: string;
  addressRelatedTags: { type: string }[];
  labels: string[];
  highRiskCount: number;
  cryptoBalance: number;
  isInPortfolio: boolean;
  owner: Entity;
  controller: Entity;
};

export const ReportHeader = ({
  reportType,
  address,
  labels,
  highRiskCount,
  cryptoBalance,
  isInPortfolio,
  owner,
  controller,
}: Props) => {
  const theme = useTheme();

  return (
    <Grid xs container item flexWrap="nowrap" rowSpacing={3} borderBottom={`1px solid ${theme.colors.lightGray}`}>
      <Grid container item xs={12} rowSpacing={4}>
        <Grid item xs={12} container alignItems="center" columnSpacing={2}>
          <Grid container item xs paddingBottom={4} flexWrap="nowrap">
            <ReportTitle
              owner={owner}
              controller={controller}
              address={address}
              reportType={reportType}
              balanceCrypto={cryptoBalance}
              balanceFiat={123123}
              highRiskCount={highRiskCount}
              labels={labels}
              isInPortfolio={isInPortfolio}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReportHeader;
