import styled from "styled-components";

export const Badge = styled.div<{ color: string; $backgroundColor: string }>`
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 8px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
  color: ${(p) => p.color};
  background-color: ${(p) => p.$backgroundColor};
  height: 28px;
`;
