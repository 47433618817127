import { EntityType, EntityTypeRisk } from "types";
const riskOrder: EntityTypeRisk[] = ["VERY_HIGH", "HIGH", "MEDIUM", "LOW"];

export const getHigherRiskType = (entityTypes: EntityType[], entityTypesName: string[]) => {
  const types = entityTypes.filter((entityType) => {
    if (entityType == undefined) return false;
    return entityTypesName.includes(entityType.name);
  });
  const sortedTypes = types.sort((a, b) => riskOrder.indexOf(a.risk) - riskOrder.indexOf(b.risk));
  return sortedTypes[0] ? sortedTypes[0].name : "Unknown";
};

export const getEntityToDisplay = (
  tagInformation: {
    entityName?: string | null;
    entityType?: string | null;
    entitySubType?: string | null;
    controllerTag?: string | null;
    controllerType?: string | null;
    controllerSubType?: string | null;
  },
  entityTypes: EntityType[],
): { entityName: string; entityType: string } => {
  const getRisk = (typeName: string | null | undefined): EntityTypeRisk =>
    entityTypes.find((et) => et.name === typeName)?.risk || "LOW";

  const typeRisks = {
    entityType: getRisk(tagInformation.entityType),
    entitySubType: getRisk(tagInformation.entitySubType),
    controllerType: getRisk(tagInformation.controllerType),
    controllerSubType: getRisk(tagInformation.controllerSubType),
  };

  const getHighestRiskType = (types: (keyof typeof typeRisks)[]): keyof typeof typeRisks | null => {
    const validTypes = types.filter((t) => tagInformation[t] != null);
    if (validTypes.length === 0) return null;

    return validTypes.reduce((highest, current) => {
      const currentRiskIndex = riskOrder.indexOf(typeRisks[current]);
      const highestRiskIndex = riskOrder.indexOf(typeRisks[highest]);

      // If current risk is higher (lower index means higher risk)
      if (currentRiskIndex < highestRiskIndex) {
        return current;
      }

      // If highest risk is higher
      if (currentRiskIndex > highestRiskIndex) {
        return highest;
      }

      // If risks are equal, compare priorities
      const typePriorityOrder = ["ownerType", "ownerSubType", "controllerType", "controllerSubType"];
      const currentPriorityIndex = typePriorityOrder.indexOf(current);
      const highestPriorityIndex = typePriorityOrder.indexOf(highest);

      // Lower index in typePriorityOrder means higher priority
      if (currentPriorityIndex < highestPriorityIndex) {
        return current;
      } else {
        return highest;
      }
    });
  };
  const getTypeRisk = (
    type: "controllerType" | "controllerSubType" | "entityType" | "entitySubType" | null,
  ): EntityTypeRisk => (type ? typeRisks[type] : "LOW");

  const ownerHighestRiskType = getHighestRiskType(["entityType", "entitySubType"]);
  const controllerHighestRiskType = getHighestRiskType(["controllerType", "controllerSubType"]);

  let entityName = "Unknown";
  let entityType = "Unknown";
  const isOwnerRiskHigherThanControllerRisk =
    riskOrder.indexOf(getTypeRisk(ownerHighestRiskType)) < riskOrder.indexOf(getTypeRisk(controllerHighestRiskType));
  if (
    !tagInformation.controllerTag ||
    (ownerHighestRiskType && (!controllerHighestRiskType || isOwnerRiskHigherThanControllerRisk))
  ) {
    entityName = tagInformation.entityName || "Unknown";
    entityType = ownerHighestRiskType ? tagInformation[ownerHighestRiskType] || "Unknown" : "Unknown";
  } else if (controllerHighestRiskType) {
    entityName = tagInformation.controllerTag;
    entityType = tagInformation[controllerHighestRiskType] || "Unknown";
  }

  return { entityName, entityType };
};

export const getTypeToDisplay = (
  entityTypes: EntityType[],
  tx: {
    entityType: string | null;
    entitySubType: string | null;
    controllerType: string | null;
    controllerSubType: string | null;
  },
): { name: string | "Unknown"; risk: string } => {
  const type = entityTypes.find((entityType) => entityType.name === tx.entityType);
  const subType = tx.entitySubType ? entityTypes.find((entityType) => entityType.name === tx.entitySubType) : null;

  const controllerType = tx.controllerType
    ? entityTypes.find((entityType) => entityType.name === tx.controllerType)
    : null;

  const controllerSubType = tx.controllerSubType
    ? entityTypes.find((entityType) => entityType.name === tx.controllerSubType)
    : null;

  const risks = [];
  if (type) {
    risks.push({ ...type, kind: "type" });
  }
  if (subType) {
    risks.push({ ...subType, kind: "subType" });
  }
  if (controllerType) {
    risks.push({ ...controllerType, kind: "controllerType" });
  }
  if (controllerSubType) {
    risks.push({ ...controllerSubType, kind: "controllerSubType" });
  }
  const typePriorityOrder = ["type", "subType", "controllerType", "controllerSubType"];
  const sortedRisks = risks.sort((a, b) => {
    const aRisk = a.risk ? riskOrder.indexOf(a.risk) : 0;
    const bRisk = b.risk ? riskOrder.indexOf(b.risk) : 0;
    if (aRisk !== bRisk) {
      return aRisk - bRisk;
    }
    const aPriority = typePriorityOrder.indexOf(a.kind);
    const bPriority = typePriorityOrder.indexOf(b.kind);
    return aPriority - bPriority;
  });
  if (sortedRisks.length === 0) {
    return {
      name: "Unknown",
      risk: "Unknown",
    };
  } else {
    return {
      name: sortedRisks[0].name,
      risk: sortedRisks[0].risk,
    };
  }
};
