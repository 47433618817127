import { Code, Logout } from "@mui/icons-material";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { Divider, Menu } from "@mui/material";
import { SearchBar } from "components";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Profile } from "types";
import { Grid, IconButton, AccountIcon, MenuList } from "ui";
import { MenuItem } from "components/Menu";
import { useAuth0 } from "@auth0/auth0-react";
import AuthContext from "contexts/Auth0Context";
import { AppContext } from "contexts";
import axios from "axios";
import { useErrorHandler } from "hooks";

type Props = {
  isLandingPage?: boolean;
  entities: Profile[];
};

export const TopBar = ({ isLandingPage = false, entities }: Props) => {
  const { logout } = useAuth0();
  const authContext = useContext(AuthContext);
  const { hasNewNotifications, setHasNewNotifications } = useContext(AppContext);
  const { user } = authContext;
  const [eventsMenuAnchor, setEventsMenuAnchor] = useState<null | HTMLElement>(null);
  const [isEventsToggled, setEventsToggled] = useState(false);
  const [isAccountToggled, setIsAccountToggled] = useState(false);
  const [accountMenuAnchor, setAccountMenuAnchor] = useState<null | HTMLElement>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const handleError = useErrorHandler();

  useEffect(() => {
    const fetchNewNotifications = async () => {
      try {
        const res = await axios.get<boolean>("/api/notifications/has-new");
        setHasNewNotifications(res.data);
      } catch (e) {
        handleError(e);
      }
    };

    fetchNewNotifications();
  }, []);

  return (
    <Grid container flexWrap="nowrap">
      <Grid container item flexWrap="nowrap" columnSpacing={2} height="60px" alignItems="center" zIndex={1}>
        <Grid item>
          <MenuItem path="/leaderboard" label="Leaderboard" />
        </Grid>
        <Grid item flexWrap="nowrap">
          <MenuItem
            path=""
            label="Events"
            onClick={(e) => {
              setEventsMenuAnchor(e.currentTarget);
              setEventsToggled(!isEventsToggled);
            }}
          />
          <Menu
            disableScrollLock={true}
            open={isEventsToggled}
            anchorEl={eventsMenuAnchor}
            onClose={() => setEventsToggled(false)}
            slotProps={{
              paper: {
                style: {
                  width: 150,
                },
              },
            }}
          >
            <MenuList dense>
              <MenuItem
                path="/events/hacks"
                label="Hacks"
                onClick={() => {
                  setEventsToggled(false);
                  navigate("/events/hacks");
                }}
              />
              <MenuItem
                path="/events/sanctions"
                label="Sanctions"
                onClick={() => {
                  setEventsToggled(false);
                  navigate("/events/sanctions");
                }}
              />

              <MenuItem
                path="/events/scams"
                label="Scams"
                onClick={() => {
                  setEventsToggled(false);
                  navigate("/events/scams");
                }}
              />
            </MenuList>
          </Menu>
        </Grid>
        {user.isAdmin && (
          <Grid item>
            <MenuItem path="/address" label="Addresses" />
          </Grid>
        )}
      </Grid>
      <Grid container item justifyItems="center" justifyContent="center" flexWrap="nowrap" xs={4}>
        {!isLandingPage && (
          <SearchBar
            placeholder="Search BTC or ETH address, entity..."
            onChange={(searchTerm: string) => {
              setSearchTerm(searchTerm);
            }}
            options={entities.filter((entity) => entity.status !== "Inactive").map((entity) => entity.name)}
            value={searchTerm}
          />
        )}
      </Grid>
      <Grid
        container
        item
        columnSpacing={2}
        alignItems="center"
        height="60px"
        flexWrap="nowrap"
        justifyContent="flex-end"
        zIndex={1}
      >
        <Grid item>
          <MenuItem path="/alerts" label="Alerts" notifications={Number(hasNewNotifications)} />
        </Grid>
        <Grid item>
          <MenuItem path="/portfolio" label="Portfolio" />
        </Grid>
        <Grid item alignSelf="stretch">
          <Divider orientation="vertical" variant="middle" flexItem />
        </Grid>
        <Grid item>
          <IconButton
            onClick={(e) => {
              setAccountMenuAnchor(e.currentTarget);
              setIsAccountToggled(!isAccountToggled);
            }}
          >
            <AccountIcon />
          </IconButton>
          <Menu
            open={isAccountToggled}
            anchorEl={accountMenuAnchor}
            onClose={() => setIsAccountToggled(false)}
            slotProps={{
              paper: {
                style: {
                  width: 150,
                },
              },
            }}
          >
            <MenuList dense sx={{ pl: 1 }}>
              <MenuItem path="/guide" label="User Guide" icon={<MenuBookIcon />} />
              <MenuItem path="/profile" label="Profile" icon={<AccountIcon />} />
              {user.api_access && <MenuItem path="/tokens" label="API" icon={<Code />} />}
              <MenuItem
                path=""
                onClick={() => {
                  setIsAccountToggled(false);
                  logout({
                    logoutParams: { returnTo: window.location.origin },
                  });
                }}
                label="Logout"
                icon={<Logout />}
              />
            </MenuList>
          </Menu>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TopBar;
