import { Grid, Label, Tooltip } from "ui";
import ProfileTag from "components/EntityProfile/ProfileTag";
import Checkmark from "../Checkmark";
import { OverviewItem } from "./Overview";

const OverviewEntry = ({ item }: { item: OverviewItem }) => (
  <Tooltip title={item.tooltip}>
    <Grid container item columnSpacing={1} alignItems="center" flexWrap="nowrap">
      <Grid container item minWidth={24} width="fit-content">
        <Checkmark value={item.value} icon={item.icon} />
      </Grid>
      <Grid container item direction="column" width="fit-content">
        <Label size="small" color={item.value && item.value !== "false" ? "black" : "gray"}>
          {item.label}
        </Label>
        {item.additionalInfo && <Label>{item.additionalInfo}</Label>}
      </Grid>
      {!item.hideTag && item.value && (
        <Grid item>
          <ProfileTag label={item.value.toString()} />
        </Grid>
      )}
    </Grid>
  </Tooltip>
);

type Props = {
  items: OverviewItem[];
};

const OverviewList = ({ items }: Props) => {
  return (
    <Grid container item direction="column" rowSpacing={2} marginTop={3}>
      {items.map((item, index) => (
        <OverviewEntry key={index} item={item} />
      ))}
    </Grid>
  );
};

export default OverviewList;
