import { Metadata, EntityOverview, EntityType } from "components";
import RiskyEventType from "pages/Report/ReportHeader/RiskyEventType";
import { Grid } from "ui";
import { Event } from "types";

type Props = {
  details: { name: string; type?: string; subType?: string; events: Event[] };
  hasNameOrImage: boolean;
  title: string;
};

const EntitySection = ({ details, hasNameOrImage, title }: Props) => {
  // Extract unique event type names
  const uniqueEventTypes = Array.from(new Set(details.events.map((event) => event.type.name)));

  return (
    <Metadata
      columnSpacing={6}
      list={[
        {
          title,
          width: "auto",

          value: (
            <Grid
              container
              columnGap={1}
              style={{ paddingTop: hasNameOrImage ? 12 : 0 }}
              alignItems="center"
              flexWrap="nowrap"
              height="50px"
            >
              <Grid item xs="auto">
                <EntityOverview name={details.name || "Unknown"} avatarVariant="average" />
              </Grid>

              {details.type && (
                <Grid item xs="auto">
                  <EntityType type={details.type} />
                </Grid>
              )}

              {details.subType && (
                <Grid item>
                  <EntityType type={details.subType} />
                </Grid>
              )}

              <Grid container item columnGap={1}>
                {uniqueEventTypes.map((typeName) => (
                  <Grid item key={typeName}>
                    <RiskyEventType type={typeName} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ),
        },
      ]}
    />
  );
};

export default EntitySection;
