import { FlagIcon, Grid, Label } from "ui";
import { getRiskBackgroundColor, getRiskColor } from "utils/risk";
import { useGetTypeRisk, useIsTypeRisky } from "hooks/useRisk";

type Props = { type: string };

const EntityType = ({ type }: Props) => {
  const isTypeRisky = useIsTypeRisky(type);
  const typeRisk = useGetTypeRisk(type);

  return (
    <Grid container item alignItems="center" columnGap={1} wrap="nowrap">
      <Grid item>
        <Label>{type}</Label>
      </Grid>
      {isTypeRisky && (
        <Grid container item justifyContent="flex-start">
          <FlagIcon minWidth="22px" color={getRiskColor(typeRisk)} background={getRiskBackgroundColor(typeRisk)} />
        </Grid>
      )}
    </Grid>
  );
};

export default EntityType;
