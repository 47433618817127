import { useContext, useState, useMemo } from "react";
import { FormControlLabel, Tooltip } from "@mui/material";
import { LabelValue } from "components";
import { Grid, Switch } from "ui";
import { formatCryptoCurrencyValue, formatFiatValue } from "utils";
import getColumns from "./columns";
import AuthContext from "contexts/Auth0Context";
import { isBefore, isAfter } from "date-fns";
import * as S from "../styled";
import { EntityTransaction, EntityType } from "types";
import { TransactionDetails } from "./TransactionDetails";
import { hasFeatureAccess } from "utils/featureUtils";

// TODO Sync with Transaction Type in BTC and ETH
export type SummaryTransaction = {
  address: string;
  entityName: string;
  entityType: string;
  time?: Date;
  amount: number;
  value: number;
  curHop: number;
  direction: string;
  addresses: string[];
  txCount: number;
  firstTx: string;
  lastTx: string;
};

type Props = {
  title?: string;
  direction: "incoming" | "outgoing";
  transactions: EntityTransaction[];
  isLargeAddress?: boolean;
  hideAmounts: boolean;
  chain?: "btc" | "eth" | "erc20";
  originAddress: string;
  onTxCountClick?: (
    entityName: string,
    addresses: string[],
    hop: number,
    isBreakdown: boolean,
    firstTx: string,
    lastTx: string,
  ) => void;
  entityTypes: EntityType[];
};

export type TransactionSummaryRow = SummaryTransaction & { id: string };

const TransactionSummary = ({
  transactions,
  isLargeAddress,
  hideAmounts,
  chain = "btc",
  onTxCountClick,
  entityTypes,
}: Props) => {
  const { user } = useContext(AuthContext);
  const hiddenTags = ["Unknown", "Unknown Addresses", "Returning Funds"];
  const systemTags = ["Unknown", "Unknown Address", "Unknown Addresses", "Returning Funds", "Insignificant Amounts"];
  const [displayAddressBreakdown, setDisplayAddressBreakdown] = useState(false);
  const [showIndirect, setShowIndirect] = useState(false);
  const [showUnknownEntities, setShowUnknownEntities] = useState(false);
  const [expandedRowIds, setExpandedRowIds] = useState<(string | number)[]>([]);

  const handleRowExpandToggle = (rowId: string | number) => {
    setExpandedRowIds((prev) => (prev.includes(rowId) ? prev.filter((id) => id !== rowId) : [...prev, rowId]));
  };
  const showIndirectChange = () => setShowIndirect((prev) => !prev);
  const showBreakdownChange = () => setDisplayAddressBreakdown((prev) => !prev);
  const showAllChange = () => setShowUnknownEntities((prev) => !prev);

  const { rows, totalAmount, totalValue } = useMemo(() => {
    const transactionSummary = transactions.reduce((prev: { [entityName: string]: SummaryTransaction }, next) => {
      if ((showIndirect && !next.entityType && !next.endOfHop) || (!showIndirect && next.curHop > 1)) {
        return prev;
      }
      // const entityName = showBreakdown ? next.address : next.entityName;

      const entityName =
        displayAddressBreakdown || next.entityName === "Unknown"
          ? next.address + next.curHop
          : (next.entityName || "Unknown") + next.curHop;

      // TODO This conditional operation returns the same value whether the condition is "true" or "false".sonarlint(typescript:S3923)
      const firstTx = chain === "eth" || chain === "erc20" ? next.firstDate : next.firstDate;
      const lastTx = chain === "eth" || chain === "erc20" ? next.lastDate : next.lastDate;

      if (entityName in prev) {
        prev[entityName].amount += next.amount;
        prev[entityName].value += next.value;
        prev[entityName].txCount += next.txCount;
        prev[entityName].addresses = prev[entityName].addresses.includes(next.address)
          ? prev[entityName].addresses
          : [...prev[entityName].addresses, next.address];
        // ? prev[entityName].txCount + next.txs.length
        // : prev[entityName].txCount + 1;
        if (isAfter(new Date(prev[entityName].firstTx), new Date(firstTx))) {
          prev[entityName].firstTx = firstTx;
        }
        if (isBefore(new Date(prev[entityName].lastTx), new Date(lastTx))) {
          prev[entityName].lastTx = lastTx;
        }
      } else {
        prev[entityName] = {
          ...next,
          addresses: [next.address],
          firstTx,
          lastTx,
          // txCount: next.txs ? next.txs.length : 1,
          txCount: next.txCount,
          entityName:
            displayAddressBreakdown &&
            (!next.entityName || systemTags.some((unknownEntityTag) => next.entityName.includes(unknownEntityTag)))
              ? "Unknown"
              : next.entityName,
        };
      }

      return prev;
    }, {});

    let curRows: TransactionSummaryRow[] = Object.keys(transactionSummary).map((entityName) => {
      const item = transactionSummary[entityName];
      const isKnownEntity = item.entityName && item.entityName !== "" ? true : false;
      return {
        id: `${item.address}`,
        ...item,
        entityTag: isKnownEntity ? item.entityName : displayAddressBreakdown ? "Unknown" : "Unknown",
      };
    });

    if (!showUnknownEntities) {
      curRows = curRows.filter((row) => !hiddenTags.includes(row.entityName) && !row.entityName.startsWith("Unknown"));
    }

    const { amountSum, valueSum } = curRows.reduce(
      (previousValue, currentValue) => {
        return {
          amountSum: previousValue.amountSum + (currentValue.amount ?? 0),
          valueSum: previousValue.valueSum + (currentValue.value ?? 0),
        };
      },
      {
        amountSum: 0,
        valueSum: 0,
      },
    );

    return {
      rows: curRows,
      totalAmount: amountSum,
      totalValue: valueSum,
    };
  }, [displayAddressBreakdown, showUnknownEntities, showIndirect, transactions, chain]);

  const columns = getColumns({ showBreakdown: displayAddressBreakdown, hideAmounts, onTxCountClick, chain });

  return (
    <Grid container item rowSpacing={3} margin={0}>
      <Grid container alignItems={"center"} rowGap={3}>
        <Grid container item xs={12} md columnSpacing={2}>
          {!hideAmounts && (
            <Grid item>
              <Tooltip
                disableHoverListener={chain !== "eth"}
                title="This figure does not include gas fees on sent funds"
              >
                <span>
                  <LabelValue
                    value={(formatCryptoCurrencyValue(totalAmount, chain) || "0.000") + ` ${chain.toLocaleUpperCase()}`}
                    label="Total Amount:"
                    direction="row"
                    valueBold={true}
                  />
                </span>
              </Tooltip>
            </Grid>
          )}

          <Grid item>
            <LabelValue
              value={`$ ${formatFiatValue(totalValue, chain)}`}
              label="Total Value:"
              direction="row"
              valueBold={true}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} md="auto" columnSpacing={2}>
          {!isLargeAddress && (
            <Grid item>
              <Tooltip placement="top" title="Toggle between direct and indirect counterparties">
                <span>
                  <FormControlLabel
                    control={<Switch size="small" checked={showIndirect} onChange={showIndirectChange} />}
                    labelPlacement="start"
                    label={showIndirect ? <b>Indirect</b> : "Direct"}
                    sx={{
                      marginRight: 0,
                      width: "110px",
                    }}
                  />
                </span>
              </Tooltip>
            </Grid>
          )}

          <Grid item>
            <Tooltip placement="top" title="Show address details for each entity">
              <span>
                <FormControlLabel
                  control={<Switch size="small" checked={displayAddressBreakdown} onChange={showBreakdownChange} />}
                  label="Show Addresses"
                  sx={{
                    fontSize: "14px",
                    marginRight: "0px",
                  }}
                />
              </span>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip placement="top" title="Show all counterparties">
              <span>
                <FormControlLabel
                  control={<Switch size="small" checked={showUnknownEntities} onChange={showAllChange} />}
                  label="Show All Entities"
                  sx={{
                    fontSize: "14px",
                    marginRight: "0px",
                    width: "170px",
                  }}
                />
              </span>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <S.Table
            fontSize="small"
            columns={columns}
            allowExport={user.isAdmin}
            rows={rows}
            initialState={{
              sorting: {
                sortModel: [{ field: "value", sort: "desc" }],
              },
            }}
            isExpandable={hasFeatureAccess("events", user.isAdmin)}
            rowHeight={56}
            expandedRowIds={expandedRowIds}
            onRowExpandToggle={handleRowExpandToggle}
            renderRowDetails={(params) => {
              const row = params.row;
              const ownerEvents = row?.ownerEvents ?? [];
              const controllerEvents = row?.controllerEvents ?? [];
              const events = [...ownerEvents, ...controllerEvents];
              return (
                <TransactionDetails
                  labels={row.labels}
                  riskEvents={events}
                  displayAddressBreakdown={displayAddressBreakdown}
                  controllerTag={row?.controllerTag}
                  controllerType={row?.controllerType}
                  controllerSubType={row?.controllerSubType}
                  entityDetails={row?.entityDetails}
                  entityType={row.entityType}
                  entitySubType={row.entitySubType}
                  entityTypes={entityTypes}
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TransactionSummary;
