import styled from "styled-components";

export const Title = styled.div<{
  color: keyof Theme["colors"];
  $variant?: "regular" | "small";
  $textalign: "left" | "center" | "right";
}>`
  ${(p) => p.theme.fonts.h1};
  font-size: ${(p) => (p.$variant === "regular" ? "24px" : "18px")};
  width: 100%;
  font-weight: 500;
  color: ${(p) => p.theme.colors[p.color]};
  text-align: ${(p) => p.$textalign};
`;
