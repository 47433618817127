import { useContext } from "react";
import { AppContext } from "contexts";
import { isHighRisk } from "utils/risk";

export const useGetTypeRisk = (type: string) => {
  const { entityTypes } = useContext(AppContext);
  const entityType = entityTypes.find((et) => et.name === type);
  return entityType?.risk || "LOW";
};

export const useGetHighestRiskType = (types: string[]) => {
  const risks = types.map(useGetTypeRisk);
  return risks.reduce((prev, current) => (isHighRisk(current) ? current : prev), "LOW");
};

export const useIsTypeRisky = (type: string) => {
  const risk = useGetTypeRisk(type);
  return isHighRisk(risk);
};

export const useIsAnyTypeRisky = (types: string[]) => {
  return types.some(useIsTypeRisky);
};
