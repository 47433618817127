import { useAuth } from "contexts/AuthPassportContext";
import React, { useEffect, useState } from "react";
import { Button, Input, Grid } from "ui";
import { CenterGrid, Container } from "./styled";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";

const Login: React.FC = () => {
  const { login, error } = useAuth();
  const [invalid, setInvalid] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const location = useLocation();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const redirectTo = location.state as { from: string };
    login(email, password, redirectTo.from === location.pathname ? "/" : redirectTo.from);
  };

  useEffect(() => {
    if (error) {
      setInvalid(true);
      toast("Invalid credentials");
    }
  }, [error]);

  useEffect(() => {
    setInvalid(false);
  }, [email, password]);

  return (
    <>
      <CenterGrid>
        <Container>
          <img
            src="https://images.squarespace-cdn.com/content/v1/61581b0994e1b92250196a71/d9441ab8-598f-4bf5-bdcc-e8a210585ef6/Hoptrail+logo-pink+mark-white+background-01.png?format=1500w"
            width={240}
          />
          <h1 style={{ marginTop: 0 }}>Enter your account</h1>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input
                  label={"Email"}
                  type="email"
                  onChange={(evt) => setEmail(evt.target.value)}
                  error={invalid}
                  required={true}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  label={"Password"}
                  type="password"
                  error={invalid}
                  onChange={(evt) => setPassword(evt.target.value)}
                  required={true}
                />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" disabled={false}>
                  Login
                </Button>
              </Grid>
            </Grid>
          </form>
        </Container>
      </CenterGrid>
    </>
  );
};

export default Login;
