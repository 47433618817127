import { SearchHistoryItemType } from "components/SearchBar/Results/SearchHistoryItem";
import { useEffect, useRef } from "react";
import { Profile, HackProfile, SanctionProfile } from "types";
import { Grid } from "ui";

import SearchResultItem from "./ResultItem";

export type SearchItem = {
  id: string;
  name: string;
  type: SearchResultTypes;
};

export type SearchResultTypes = "entity" | "hack" | "sanction" | "address" | "search_history";

type Props = {
  focusedIndex: number;
  entities?: Profile[];
  address?: {
    address: string;
    type: "eth" | "btc";
  };
  hacks?: HackProfile[];
  sanctions?: SanctionProfile[];
  resultsItems: SearchItem[];
  searcHistory: string[];
  onSearchResultOpen: (value: string, type: SearchResultTypes) => void;
  onCloseResult: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onClickOutside: () => void;
};

export const Result = ({
  entities = [],
  address,
  hacks = [],
  sanctions = [],
  onSearchResultOpen,
  focusedIndex,
  resultsItems,
  onMouseEnter,
  onMouseLeave,
  onClickOutside,
  searcHistory,
}: Props) => {
  const itemRefs = useRef<Array<HTMLElement | null>>([]);
  const searchBarRef = useRef<HTMLDivElement>(null);
  const isFocused = (index: string, type: SearchResultTypes) => {
    const globalIndex = resultsItems.findIndex((item) => item.name === index && item.type === type);
    return focusedIndex === globalIndex;
  };

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (target.isSameNode(searchBarRef.current)) {
      onClickOutside();
    }
  };

  useEffect(() => {
    if (focusedIndex >= 0 && focusedIndex < resultsItems.length) {
      const focusedElement = itemRefs.current[focusedIndex];
      if (focusedElement) {
        focusedElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
  }, [focusedIndex, resultsItems.length]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const searchHistory: SearchHistoryItemType[] = searcHistory.slice(0, 5).map((item) => ({
    id: item,
    name: item,
    value: item,
    type: "address",
  }));

  const showSearchHistory = !address && entities.length === 0 && hacks.length === 0 && sanctions.length === 0;

  return (
    <Grid
      container
      justifyContent="center"
      width="640px"
      ref={searchBarRef}
      style={{
        position: "relative",
        backgroundColor: "white",
        // maxHeight: "auto",
        overflowY: "auto",
        width: "100%",
        height: "100%",
        minHeight: "100%",
        zIndex: 1,
      }}
    >
      <Grid
        container
        style={{
          position: "relative",
          width: "640px",
          overflowY: "auto",
          height: "80%",
        }}
        padding={2}
        paddingTop={4}
        flexDirection="column"
        flexWrap="nowrap"
      >
        {showSearchHistory && (
          <SearchResultItem
            items={searchHistory}
            label="Search History"
            itemType="search_history"
            showItem={onSearchResultOpen}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            isFocused={(value) => isFocused(value, "search_history")}
          />
        )}
        {address && (
          <SearchResultItem
            items={[address]}
            label="Addresses"
            itemType="address"
            showItem={onSearchResultOpen}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            isFocused={(value) => isFocused(value, "address")}
          />
        )}

        {entities.length > 0 && (
          <SearchResultItem
            items={entities}
            label="Entities"
            itemType="entity"
            showItem={onSearchResultOpen}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            isFocused={(value) => isFocused(value, "entity")}
          />
        )}

        {hacks.length > 0 && (
          <SearchResultItem
            items={hacks}
            label="Events"
            itemType="hack"
            showItem={onSearchResultOpen}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            isFocused={(value) => isFocused(value, "hack")}
          />
        )}

        {sanctions.length > 0 && (
          <SearchResultItem
            items={sanctions}
            label="Sanctions"
            itemType="sanction"
            showItem={onSearchResultOpen}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            isFocused={(value) => isFocused(value, "sanction")}
          />
        )}
      </Grid>
    </Grid>
  );
};
export default Result;
