import { Grid } from "ui";
import getColumns from "./columns";
import { Table } from "ui";
import type { Entity } from "types";
import * as S from "./styled";

type Data = Entity & {
  rank?: number;
};

type Props = {
  rows: Data[];
  display: "All" | "Category";
  onEntitySelected: (entity: Data) => void;
};

export default function EntitiesList({ rows, onEntitySelected, display }: Props) {
  const columns = getColumns(display);

  return (
    <Grid container item>
      <S.EntitiesTableWrapper>
        <Table
          rowHeight={50}
          columns={columns}
          rows={rows}
          onRowClick={(params) => onEntitySelected(params.row)}
          getRowClassName={(params) => `status-${params.row.status}`}
        />
      </S.EntitiesTableWrapper>
    </Grid>
  );
}
