// src/components/CustomDatePicker.tsx
import { DatePicker as MuiDatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import * as dateFns from "date-fns";

type Props<TDate extends Date = Date> = DatePickerProps<TDate>;

const CustomDatePicker = (props: Props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} dateLibInstance={dateFns}>
      <MuiDatePicker {...props} />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
