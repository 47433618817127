import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, Grid, Input, Title, Container } from "ui";
import { toast } from "react-toastify";
import { Tab, Tabs } from "@mui/material";

import AuthContext from "contexts/Auth0Context";
import { useErrorHandler } from "hooks";
import { formatDate } from "utils";
import { LabelValue } from "components";
import CancelSubscription from "./CancelSubscription";

type FormData = {
  name: string;
  company: string;
  position: string;
  phone: string;
};

enum ButtonStatus {
  NONE,
  ACTIVE,
  WAITING,
  DONE,
}

const Profile: React.FC = () => {
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [passwordResetButtonStatus, setPasswordResetButtonStatus] = useState<ButtonStatus>(ButtonStatus.ACTIVE);
  const [isCancelSubscriptionConfirmOpen, setIsCancelSubscriptionConfirmOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const handleError = useErrorHandler();

  const { register, handleSubmit, control, reset, formState } = useForm<FormData>({
    defaultValues: {
      name: user.name,
      company: user.company,
      position: user.position,
      phone: user.phone,
    },
  });

  useEffect(() => {
    reset({
      name: user.name,
      company: user.company,
      position: user.position,
      phone: user.phone,
    });
  }, [user]);

  useEffect(() => {
    setIsFormDirty(formState.isDirty);
  }, [formState.isDirty]);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleUpdateUserMetadata = async (data: FormData) => {
    try {
      await axios.patch("/api/users/me", data);
      toast("User metadata updated successfully");
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPassword = async () => {
    if (passwordResetButtonStatus === ButtonStatus.ACTIVE) {
      setPasswordResetButtonStatus(ButtonStatus.WAITING);
      const res = await axios.post(`https://${import.meta.env.VITE_AUTH0_DOMAIN}/dbconnections/change_password`, {
        client_id: import.meta.env.VITE_AUTH0_CLIENT_ID,
        email: user?.email,
        connection: "Username-Password-Authentication",
      });
      if (res.status === 200) {
        setPasswordResetButtonStatus(ButtonStatus.DONE);
      } else {
        setPasswordResetButtonStatus(ButtonStatus.ACTIVE);
      }
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const response = await axios.get("/api/users/payments/cancel");
      const { message } = response.data;

      toast.success(message);
    } catch (error) {
      handleError(error);
      toast.error("Failed to cancel subscription. Please try again or contact support.");
    } finally {
      setIsCancelSubscriptionConfirmOpen(false);
    }
  };
  const renderPersonalInfo = () => (
    <form onSubmit={handleSubmit(handleUpdateUserMetadata)}>
      <Grid container direction="column" rowSpacing={2}>
        <Grid item>
          <Controller
            control={control}
            name="name"
            render={({ field }) => <Input label="Full Name" {...field} disabled={true} />}
          />
        </Grid>
        <Grid item>
          <Controller
            control={control}
            name="company"
            render={({ field }) => (
              <Input
                label="Company"
                {...register("company", {
                  required: true,
                })}
                {...field}
                disabled={true}
              />
            )}
          />
        </Grid>
        <Grid item>
          <Controller
            control={control}
            name="position"
            render={({ field }) => (
              <Input
                {...register("position", {
                  required: true,
                })}
                label="Position"
                {...field}
                disabled={true}
              />
            )}
          />
        </Grid>
        <Grid item>
          <Controller
            control={control}
            name="phone"
            render={({ field }) => (
              <Input {...register("phone")} label="Phone number (optional)" {...field} disabled={true} />
            )}
          />
        </Grid>
        <Grid item container columnSpacing={1}>
          <Grid item>
            <Button type="button" onClick={handleResetPassword} size="medium">
              {passwordResetButtonStatus === ButtonStatus.DONE && "Email Sent"}
              {passwordResetButtonStatus === ButtonStatus.WAITING && "Requesting..."}
              {passwordResetButtonStatus === ButtonStatus.ACTIVE && "Reset Password"}
            </Button>
          </Grid>

          {isFormDirty && (
            <Grid item>
              <Button type="submit" size="medium" disabled={isLoading}>
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </form>
  );

  const renderSubscription = () => (
    <Grid container item direction="column" rowSpacing={2}>
      <Grid container item spacing={2}>
        <Grid item xs={6}>
          <LabelValue label="Current Plan" value={user.product.replace("_", " ")} valueBold={true} />
        </Grid>
        <Grid item xs={6} textTransform="capitalize">
          <LabelValue label="Status" value={user.subscriptionStatus} valueBold={true} />
        </Grid>
        {/* <Grid item xs={6}>
          <LabelValue
            label="Current Period Ends"
            value={formatDate(user.subscriptionCurrentPeriodEnd, "long")}
            valueBold={true}
          />
        </Grid>
        <Grid item xs={6}>
          <LabelValue label="Last Payment" value={formatDate(user.lastPaymentDate, "long")} valueBold={true} />
        </Grid> */}
      </Grid>

      {/* <Grid item container columnSpacing={1} sx={{ mt: 2 }}>
        {user.subscriptionStatus === "active" && (
          <Button
            type="button"
            onClick={() => setIsCancelSubscriptionConfirmOpen(true)}
            disabled={isCancelSubscriptionConfirmOpen}
            size="medium"
            color="error"
          >
            {isCancelSubscriptionConfirmOpen ? "Cancelling..." : "Cancel Subscription"}
          </Button>
        )}
      </Grid> */}
    </Grid>
  );

  return (
    <Container maxWidth="md">
      <Grid container paddingTop={2} paddingBottom={8}>
        <Grid item xs="auto">
          <Title>Profile</Title>
        </Grid>
      </Grid>
      {isCancelSubscriptionConfirmOpen && (
        <CancelSubscription
          onCancel={() => setIsCancelSubscriptionConfirmOpen(false)}
          onConfirm={handleCancelSubscription}
        />
      )}
      <Tabs value={tabIndex} onChange={handleTabChange}>
        <Tab label="Personal Info" />
        <Tab label="Subscription" />
      </Tabs>

      <Grid container direction="column" rowSpacing={2} sx={{ mt: 3 }}>
        {tabIndex === 0 && renderPersonalInfo()}
        {tabIndex === 1 && renderSubscription()}
      </Grid>
    </Container>
  );
};

export default Profile;
