import { Warning } from "ui";

import { useTheme } from "@mui/material";
import * as S from "./styled";

type Props = {
  count: number;
};

export const RiskyEventCount = ({ count }: Props) => {
  const theme = useTheme();
  return (
    <S.EventWarning backgroundColor={theme.colors.red} color={theme.colors.lightRed}>
      <Warning /> {count}
    </S.EventWarning>
  );
};

export default RiskyEventCount;
